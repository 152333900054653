import React, { useState, useEffect, useRef, useContext, lazy, Suspense } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { IsAuthContext} from 'app/App';
import { Stage, Layer, Text, Group, Circle, Image } from 'react-konva';
import { PDFViewer} from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Etiquette from "app/views/Pdf/Etiquette"
import {ArticleBarSearch} from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import arrowUpImg from '../../../assets/arrowUp.png';
import arrowDownImg from '../../../assets/arrowDown.png';
import {controlValidEan13} from 'app/controlValidEan13'

import {imprimeEtiquette} from 'app/imprimeEtiquetteImgFile'
import ConfigurationMainEtiquette from './Comp/ConfigurationMainEtiquette'

const bwipjs = require('bwip-js');

const SelectEtiquetteTemplate = lazy(() => import("./Comp/SelectEtiquetteTemplate"));

const EtiquetteTemplate = (props) => {
    const t = useTranslate();
    const [canvasCm,setCanvasCm]=useState({
        width:5.5,
        height:3.5
    })
    const [validSize,setValidSize]=useState(false)
    const [sizeOk,setSizeOk]=useState(false)
    const btnPdfDl = useRef('')
    const [article,setArticle]=useState(false)
    const { authParam } = useContext(IsAuthContext)
    const [hideCircle,setHideCircle] = useState(false)//permettra de ne pas afficher les points de redimensionnement à l'impression de l'etiquette
    const [exportFilePdf, setExportFilePdf] = useState(false);
    const [exportFilePrint, setExportFilePrint] = useState(false);
    const [disbaledBtnPdf,setDisabledBtnPdf]=useState(false)
    const [disbaledBtnPrint,setDisabledBtnPrint]=useState(false)
    const [print, setPrint] = useState(false);
    const [goSave,setGoSave] = useState(false)
    const [etiquetteName,setEtiquetteName] = useState('')
    const [btnValidate,setBtnValidate] = useState(false)
    const [errorSaveTemplate,setErrorSaveTemplate] = useState(false)
    const [saveSuccess,setSaveSuccess] = useState(false)
    const [templateEdit,setTemplateEdit] = useState(false)
    const [isResizing, setIsResizing] = useState(false);
    const cmToPx = (cm) => cm * 37.795; // Conversion cm en pixels
    const arrowUpImage = new window.Image();
    arrowUpImage.src = arrowUpImg; // Chemin de l'image importée
    const arrowDownImage = new window.Image();
    arrowDownImage.src = arrowDownImg; // Chemin de l'image importée

    let devise = authParam.company.devise;
    let digit = (authParam.company.devise === "XPF") ? 0 : 2;

    const [elements, setElements] = useState([]);

    useEffect(function () {//mise à jour des dimensions de l'etiquette
        if(canvasCm.width>=2 && canvasCm.height>=2 && article) setSizeOk(true)
        else setSizeOk(false)
    }, [canvasCm,article])

    const [imageData, setImageData] = useState(null); // État pour stocker l'image

    const stageRef = useRef(); // Référence pour le canevas

    const handleResize = (index, e) => {//redimensionne un elementsur l'etiquette
        const newElements = [...elements];

        const newWidth = e.target.x() + 10;
        const newHeight = e.target.y() + 10;

        if (newWidth > 50) newElements[index].width = newWidth;
        if (newHeight > 20) newElements[index].height = newHeight;

        const newFontSize = Math.min(newHeight / 2, 180);
        newElements[index].fontSize = newFontSize;

        setElements(newElements);
    };

    useEffect(()=>{//Creation de l'image de l'etiquette qui sera mise sur le PDF
        if (hideCircle) {
            //création d'une image pour la mettre ensuite sur le pdf
            const scaleFactor = 4; // Augmentez ce facteur pour une meilleure résolution (4x résolution)
    
            // Obtenez la taille originale du canvas
            const originalWidth = stageRef.current.width();
            const originalHeight = stageRef.current.height();
        
            // Ajustez temporairement la taille du canvas pour augmenter la résolution
            stageRef.current.width(originalWidth * scaleFactor);
            stageRef.current.height(originalHeight * scaleFactor);
        
            // Appliquez l'échelle au stage (pour que tout dans le canvas soit mis à l'échelle)
            stageRef.current.scale({ x: scaleFactor, y: scaleFactor });
        
            // Générer l'image en haute résolution
            const dataURL = stageRef.current.toDataURL({mimeType: 'image/png', quality: 0.95, pixelRatio: scaleFactor });
            setImageData(dataURL); // Stocker l'image en haute résolution
        
            // Rétablir la taille originale du canvas
            stageRef.current.width(originalWidth);
            stageRef.current.height(originalHeight);
            stageRef.current.scale({ x: 1, y: 1 });
        
            // Nom du fichier
            let now = new Date();
            let file_name=now.getTime()

            setHideCircle(false);
            setPrint(file_name)
            if(exportFilePrint){
                setExportFilePrint(false)
            }
            if(exportFilePdf){
                setTimeout(() => {
                    setExportFilePdf(false)
                }, 2000);
            }
            setTimeout(() => {
                setDisabledBtnPdf(false)
                setDisabledBtnPrint(false)
            }, 4000);
        }
    },[hideCircle])

    const exportEtiquette = (isFor) => {//lance l'export ou l'impression de l'etiquette
        setHideCircle(true); // Cacher les cercles avant l'exportation
        if(isFor=='pdf'){
            setExportFilePdf(true)
        }
        else if(isFor=='print'){
            setExportFilePrint(true)
        }
        setDisabledBtnPdf(true)
        setDisabledBtnPrint(true)
    };

    const handleEditSize = (e,isfor) => {//redimensionne l'etiquette
        setCanvasCm({...canvasCm,[isfor]:e.target.value.replace(/[^0-9.]/g, '')});
    }

    useEffect(function () {//pour mise à jour du code barre et flêches si on change l'article
        let updatedElements = [...elements]; // Créer une copie des éléments
        if(article && validSize && elements.find(value=>value.from=='reference')){
            let formatCodeBarre = (article.reference && controlValidEan13(article.reference)) ? "ean13" : "code128"

            const canvas = document.createElement('canvas');

            if(formatCodeBarre=='ean13'){
                bwipjs.toCanvas(canvas, {
                    bcid:        formatCodeBarre,       // Barcode type
                    text:        article.reference,    // Text to encode
                    scale:       3,               // 3x scaling factor
                    height:      10,              // Bar height, in millimeters
                    includetext: true,            // Show human-readable text
                });
            }
            else{
                bwipjs.toCanvas(canvas, {
                    bcid:        formatCodeBarre,       // Barcode type
                    text:        article.reference,    // Text to encode
                    scale:       3,               // 3x scaling factor
                    height:      10,              // Bar height, in millimeters
                    includetext: true,            // Show human-readable text
                    textxalign:  'center',       // Always good to set this
                    textyoffset: 3
                });
            }

            const barcodeImage = canvas.toDataURL('image/png'); // Format PNG sans fond
            const img = new window.Image();
            img.src = barcodeImage;

            updatedElements = updatedElements.map(el => 
                el.from === 'reference' ? { ...el, img: img } : el
            );
        }

        if(article && validSize && elements.find(value=>value.from=='arrowUp')){
            updatedElements = updatedElements.map(el => 
                el.from === 'arrowUp' ? { ...el, img: arrowUpImage } : el
            );
        }

        if(article && validSize && elements.find(value=>value.from=='arrowDown')){
            updatedElements = updatedElements.map(el => 
                el.from === 'arrowDown' ? { ...el, img: arrowDownImage } : el
            );
        }

        setElements(updatedElements);

    }, [article,validSize]);

    const handleClick = (name) => {//lorsqu'on clique sur une donnnée à ajouter sur l'etiquette
        if(elements.find(value=>value.from==name)){
            setElements(elements.filter(value => value.from !== name))
        }
        else{
            switch(name){
                case 'reference':
                    let formatCodeBarre = (article.reference && controlValidEan13(article.reference)) ? "ean13" : "code128"

                    const canvas = document.createElement('canvas');

                    if(formatCodeBarre=='ean13'){
                        bwipjs.toCanvas(canvas, {
                            bcid:        formatCodeBarre,       // Barcode type
                            text:        article.reference,    // Text to encode
                            scale:       3,               // 3x scaling factor
                            height:      10,              // Bar height, in millimeters
                            includetext: true,            // Show human-readable text
                        });
                    }
                    else{
                        bwipjs.toCanvas(canvas, {
                            bcid:        formatCodeBarre,       // Barcode type
                            text:        article.reference,    // Text to encode
                            scale:       3,               // 3x scaling factor
                            height:      10,              // Bar height, in millimeters
                            includetext: true,            // Show human-readable text
                            textxalign:  'center',       // Always good to set this
                            textyoffset: 3
                        });
                    }

                    const barcodeImage = canvas.toDataURL('image/png'); // Format PNG sans fond
                    const img = new window.Image();
                    img.src = barcodeImage;
                
                    // Ajoutez l'image à vos éléments
                    setElements([...elements, {
                        from: 'reference',
                        type: 'image',
                        img: img, // Utilise l'image ici
                        x: 50,
                        y: 30,
                        width: 300, // Largeur initiale
                        height: 100, // Hauteur initiale
                    }]);
                    break
                case 'price':
                    setElements([...elements,{ from:name, type: 'price', x: 50, y: 50, width: 100, height: 40, fontSize: 20 }])
                    break
                case 'sku':
                    setElements([...elements,{ from:name, type: 'hardfield', x: 50, y: 50, width: 100, height: 40, fontSize: 20 }])
                    break
                case 'name':
                    setElements([...elements,{ from:name, type: 'hardfield', x: 50, y: 50, width: 100, height: 40, fontSize: 20 }])
                    break
                case 'ecotaxe':
                    setElements([...elements,{ from:name, type: 'price', x: 50, y: 50, width: 100, height: 40, fontSize: 20 }])
                    break
                case 'arrowUp':
                    setElements([...elements, {
                        from: name,
                        type: 'image',
                        img: arrowUpImage, // Utilise l'image ici
                        x: 50,
                        y: 30,
                        width: 50, // Largeur initiale
                        height: 50, // Hauteur initiale
                    }]);
                    break;
                case 'arrowDown':
                    setElements([...elements, {
                        from: name,
                        type: 'image',
                        img: arrowDownImage, // Utilise l'image ici
                        x: 50,
                        y: 70,
                        width: 50, // Largeur initiale
                        height: 50, // Hauteur initiale
                    }]);
                    break;
                default :
                    setElements([...elements,{ from:name, type: 'customfield', x: 50, y: 50, width: 100, height: 40, fontSize: 20 }])
            }
        }
    }

    useEffect(function () {//impression sur imprimante via methode telechargement automatique du pdf, impression, suppression
        if (print) {
            setTimeout(() => {
                if (btnPdfDl.current) {
                    btnPdfDl.current.click();  // Exécution du clic
                    (async function () {
                        //On exécute imprimeEtiquette seulement si exportFilePdf est false, car si il est true on fait uniquement un téléchargement de l'étiquette et non une impréssion
                        if(!exportFilePdf) imprimeEtiquette(props.peripheriqueParam,print,setPrint,props.qteTag,t)
                    })();
                }
            }, 2000);
        }
    }, [print]);

    const handleChangeName = (e) => {
        setEtiquetteName(e.target.value)
    }

    const saveTemplate = async () => {//sauvegarde ou met à jour un modèle d'etiquette
        setErrorSaveTemplate(false) 
        setSaveSuccess(false)
        let valid=true;
        let errors = [];
        if(etiquetteName.length<2){
            valid=false
            errors.push(t('nameMin2'))
        } 
        if(etiquetteName.length>191){
            valid=false
            errors.push(t('nameMax191'))
        }
        if(!valid) setErrorSaveTemplate(errors) 
        else{
            setBtnValidate(true)
            let method = (props.isfor=='create') ? "post" : "put";
            let url = (props.isfor=='create') ? process.env.REACT_APP_API_URL+"api/etiquette_template/create" : process.env.REACT_APP_API_URL+"api/etiquette_template/edit/"+templateEdit.id;
            const response = await axios({
                method: method,
                url:url,
                data:{
                    name:etiquetteName,
                    article: article.id,
                    width:canvasCm.width,
                    height:canvasCm.height,
                    elements:elements
                }
            }).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }
                else{   
                    setSaveSuccess(t('successOperation'))
                    props.setRelanceEtiquetteTemplates(Date.now())
                    setGoSave(false)
                    setEtiquetteName('')
                }
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
            setBtnValidate(false)
            setTimeout(() => {
                setSaveSuccess(false)
            }, 1000);
        }
    }

    const deleteTemplate = async () => {
        let method = "delete";
        let url = process.env.REACT_APP_API_URL+"api/etiquette_template/delete/"+templateEdit.id;
        const response = await axios({
            method: method,
            url:url
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                setTemplateEdit(false)
                props.setRelanceEtiquetteTemplates(Date.now())
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
    }

    return (
        <>
            {(props.isfor=='edit') &&
                <SelectEtiquetteTemplate 
                    templateEdit={templateEdit}
                    setTemplateEdit={setTemplateEdit}
                    setArticle={setArticle}
                    setCanvasCm={setCanvasCm}
                    setElements={setElements}
                    setEtiquetteName={setEtiquetteName}
                    setValidSize={setValidSize}
                    relanceEtiquetteTemplates={props.relanceEtiquetteTemplates}
                    articleSelected={props.articleSelected}
                />
            }
            {(props.isfor=='create' || templateEdit) &&
                <>
                    <ConfigurationMainEtiquette 
                        validSize={validSize}
                        setValidSize={setValidSize}
                        sizeOk={sizeOk}
                        article={article}
                        setArticle={setArticle}
                        canvasCm={canvasCm}
                        handleEditSize={handleEditSize}
                    />
                    <hr />
                    {(validSize) &&
                        <div className="row">
                            <div className="col-12">
                                <button className={(elements.find(value=>value.from=='reference')) ? "btn btn-primary btn-sm mb-2" : "btn btn-outline-primary btn-sm mb-2"} onClick={() => handleClick('reference')}>{t('reference')}</button>&nbsp;
                                <button className={(elements.find(value=>value.from=='sku')) ? "btn btn-primary btn-sm mb-2" : "btn btn-outline-primary btn-sm mb-2"} onClick={() => handleClick('sku')}>{t('sku')}</button>&nbsp;
                                <button className={(elements.find(value=>value.from=='name')) ? "btn btn-primary btn-sm mb-2" : "btn btn-outline-primary btn-sm mb-2"} onClick={() => handleClick('name')}>{t('name')}</button>&nbsp;
                                <button className={(elements.find(value=>value.from=='price')) ? "btn btn-primary btn-sm mb-2" : "btn btn-outline-primary btn-sm mb-2"} onClick={() => handleClick('price')}>{t('price')}</button>&nbsp;
                                <button className={(elements.find(value=>value.from=='ecotaxe')) ? "btn btn-primary btn-sm mb-2" : "btn btn-outline-primary btn-sm mb-2"} onClick={() => handleClick('ecotaxe')}>{t('ecoTaxe')}</button>&nbsp;
                                <button className={(elements.find(value=>value.from=='arrowUp')) ? "btn btn-primary btn-sm mb-2" : "btn btn-outline-primary btn-sm mb-2"} onClick={() => handleClick('arrowUp')}>{t('arrowUp')}</button>&nbsp;
                                <button className={(elements.find(value=>value.from=='arrowDown')) ? "btn btn-primary btn-sm mb-2" : "btn btn-outline-primary btn-sm mb-2"} onClick={() => handleClick('arrowDown')}>{t('arrowDown')}</button>&nbsp;
                                {article.custom_fields &&
                                    Object.entries(article.custom_fields).map(([key,value])=>
                                        <span key={key}><button className={(elements.find(value=>value.from==key)) ? "btn btn-primary btn-sm mb-2" : "btn btn-outline-primary btn-sm mb-2"} onClick={() => handleClick(key)}>{key}</button>&nbsp;</span>
                                    )
                                }
                            </div>
                            <div className="col-lg-6">
                                <div className='border' style={{ backgroundColor: '#FFFFFF', width: cmToPx(canvasCm.width), height: cmToPx(canvasCm.height) }}>
                                    <Stage width={cmToPx(canvasCm.width)} height={cmToPx(canvasCm.height)} ref={stageRef}>
                                        <Layer>
                                            {elements.map((element, index) => {
                                                if (element.type === 'image') {
                                                    if(!Array.isArray(element.img)){//c'est bizzare, mais si c'est un tableau alors c'est que y a pas d'image (vient d'une template sauvegarder dans ce cas et limage doit être refaite)
                                                        return (
                                                            <Group key={index} x={element.x} y={element.y} draggable onDragMove={(e) => {
                                                                // Empêche le déplacement pendant le redimensionnement
                                                                if (!isResizing) {
                                                                    setElements(elements.map((el, i) => (
                                                                        i === index ? { ...el, x: e.target.x(), y: e.target.y() } : el
                                                                    )));
                                                                }
                                                            }}>
                                                                <Image
                                                                    key={index}
                                                                    image={element.img}
                                                                    width={element.width}
                                                                    height={element.height}
                                                                />
                                                                {!hideCircle &&
                                                                    <Circle
                                                                        x={element.width - 1}
                                                                        y={element.height - 1}
                                                                        radius={3}
                                                                        fill="#000000"
                                                                        draggable
                                                                        onDragStart={() => setIsResizing(true)} // Activer isResizing au début du redimensionnement
                                                                        onDragMove={(e) => {
                                                                            handleResize(index, e);
                                                                        }}
                                                                        onDragEnd={() => setIsResizing(false)} // Désactiver isResizing à la fin du redimensionnement
                                                                        onMouseEnter={(e) => {
                                                                        const stage = e.target.getStage();
                                                                        stage.container().style.cursor = 'nwse-resize';
                                                                        }}
                                                                        onMouseLeave={(e) => {
                                                                        const stage = e.target.getStage();
                                                                        stage.container().style.cursor = 'default';
                                                                        }}
                                                                    />
                                                                }
                                                            </Group>
                                                        );
                                                    }
                                                }

                                                if (element.type === 'hardfield') {

                                                    const textWidth = article[element.from].length * (element.fontSize / 2);
                                                    const textHeight = element.fontSize;

                                                    const textX = (element.width - textWidth) / 2;
                                                    const textY = (element.height - textHeight) / 2;

                                                    return (
                                                        <Group key={index} x={element.x} y={element.y} draggable onDragMove={(e) => {
                                                            // Empêche le déplacement pendant le redimensionnement
                                                            if (!isResizing) {
                                                                setElements(elements.map((el, i) => (
                                                                    i === index ? { ...el, x: e.target.x(), y: e.target.y() } : el
                                                                )));
                                                            }
                                                        }}>
                                                            <Text
                                                                text={article[element.from]}
                                                                x={textX}
                                                                y={textY}
                                                                fontSize={element.fontSize}
                                                                fill="#000000"
                                                            />
                                                            {!hideCircle &&
                                                                <Circle
                                                                    x={element.width - 10}
                                                                    y={element.height - 10}
                                                                    radius={3}
                                                                    fill="#000000"
                                                                    draggable
                                                                    onDragStart={() => setIsResizing(true)} // Activer isResizing au début du redimensionnement
                                                                    onDragMove={(e) => {
                                                                        handleResize(index, e);
                                                                    }}
                                                                    onDragEnd={() => setIsResizing(false)} // Désactiver isResizing à la fin du redimensionnement
                                                                    onMouseEnter={(e) => {
                                                                    const stage = e.target.getStage();
                                                                    stage.container().style.cursor = 'nwse-resize';
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                    const stage = e.target.getStage();
                                                                    stage.container().style.cursor = 'default';
                                                                    }}
                                                                />
                                                            }
                                                        </Group>
                                                    );
                                                }

                                                if (element.type === 'customfield') {

                                                    let text = (article.custom_fields[element.from]) ? article.custom_fields[element.from] : '';
                                                    const textWidth = text.length * (element.fontSize / 2);
                                                    const textHeight = element.fontSize;

                                                    const textX = (element.width - textWidth) / 2;
                                                    const textY = (element.height - textHeight) / 2;

                                                    return (
                                                        <Group key={index} x={element.x} y={element.y} draggable onDragMove={(e) => {
                                                            // Empêche le déplacement pendant le redimensionnement
                                                            if (!isResizing) {
                                                                setElements(elements.map((el, i) => (
                                                                    i === index ? { ...el, x: e.target.x(), y: e.target.y() } : el
                                                                )));
                                                            }
                                                        }}>
                                                            <Text
                                                                text={text}
                                                                x={textX}
                                                                y={textY}
                                                                fontSize={element.fontSize}
                                                                fill="#000000"
                                                            />
                                                            {!hideCircle &&
                                                                <Circle
                                                                    x={element.width - 10}
                                                                    y={element.height - 10}
                                                                    radius={3}
                                                                    fill="#000000"
                                                                    draggable
                                                                    onDragStart={() => setIsResizing(true)} // Activer isResizing au début du redimensionnement
                                                                    onDragMove={(e) => {
                                                                        handleResize(index, e);
                                                                    }}
                                                                    onDragEnd={() => setIsResizing(false)} // Désactiver isResizing à la fin du redimensionnement
                                                                    onMouseEnter={(e) => {
                                                                    const stage = e.target.getStage();
                                                                    stage.container().style.cursor = 'nwse-resize';
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                    const stage = e.target.getStage();
                                                                    stage.container().style.cursor = 'default';
                                                                    }}
                                                                />
                                                            }
                                                        </Group>
                                                    );
                                                }

                                                if (element.type === 'price') {

                                                    let price = (article[element.from]) ? article[element.from] : 0;
                                                    let prependPrice = (element.from == 'ecotaxe') ? t('dont')+' '+t('ecotaxe').toLowerCase()+': ' : ''

                                                    let formattedPrice = new Intl.NumberFormat('fr-FR', {
                                                        style: 'currency',
                                                        currency: devise,
                                                        minimumFractionDigits: digit
                                                    }).format(price);

                                                    const textWidth = formattedPrice.length * (element.fontSize / 2);
                                                    const textHeight = element.fontSize;

                                                    const textX = (element.width - textWidth) / 2;
                                                    const textY = (element.height - textHeight) / 2;

                                                    return (
                                                        <Group key={index} x={element.x} y={element.y} draggable onDragMove={(e) => {
                                                            // Empêche le déplacement pendant le redimensionnement
                                                            if (!isResizing) {
                                                                setElements(elements.map((el, i) => (
                                                                    i === index ? { ...el, x: e.target.x(), y: e.target.y() } : el
                                                                )));
                                                            }
                                                        }}>
                                                            <Text
                                                                text={prependPrice+formattedPrice}
                                                                x={textX}
                                                                y={textY}
                                                                fontSize={element.fontSize}
                                                                fill="#000000"
                                                            />
                                                            {!hideCircle &&
                                                                <Circle
                                                                    x={element.width - 10}
                                                                    y={element.height - 10}
                                                                    radius={3}
                                                                    fill="#000000"
                                                                    draggable
                                                                    onDragStart={() => setIsResizing(true)} // Activer isResizing au début du redimensionnement
                                                                    onDragMove={(e) => {
                                                                        handleResize(index, e);
                                                                    }}
                                                                    onDragEnd={() => setIsResizing(false)} // Désactiver isResizing à la fin du redimensionnement
                                                                    onMouseEnter={(e) => {
                                                                    const stage = e.target.getStage();
                                                                    stage.container().style.cursor = 'nwse-resize';
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                    const stage = e.target.getStage();
                                                                    stage.container().style.cursor = 'default';
                                                                    }}
                                                                />
                                                            }
                                                        </Group>
                                                    );
                                                }
                                            })}
                                        </Layer>
                                    </Stage>
                                </div>
                                <div className="col-12 mt-3">
                                    <button disabled={disbaledBtnPrint || !props.peripheriqueParam} className='btn btn-success btn-sm mb-2' onClick={() => exportEtiquette('print')}>{t('btnPrint')}</button>&nbsp;&nbsp;
                                    <button disabled={disbaledBtnPdf} className='btn btn-success btn-sm mb-2' onClick={() => exportEtiquette('pdf')}>{t('download')+' PDF'}</button><br/>
                                </div>
                                <div className="col-12 mt-2">
                                    <button onClick={() => setGoSave(!goSave)} className={`${goSave ? "btn btn-danger btn-sm" : "btn btn-primary btn-sm"} mb-2`}>{goSave ? t('btnCancel') : t('btnSave')}</button>
                                    {(props.isfor=='edit') &&
                                        <>
                                            &nbsp;&nbsp;&nbsp;
                                            <button disabled={btnValidate} className="btn btn-danger btn-sm mb-2" onClick={() => {
                                                swal
                                                .fire({
                                                title: t('confirmDeleteTemplate'),
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: t('btnYes'),
                                                cancelButtonText: t('btnNo'),
                                                })
                                                .then((result) => {
                                                if (result.isConfirmed) {  // Utilise isConfirmed au lieu de result.value
                                                    deleteTemplate();
                                                }
                                                });
                                                }}>
                                                    {btnValidate ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnDelete')}
                                            </button>
                                        </>
                                    }
                                </div>
                                {(goSave) &&
                                    <div className="col-12 mt-2">
                                        <input type="text" className="form-control" placeholder={t('name')+'...'} value={etiquetteName} onChange={(e) => handleChangeName(e)}/>
                                        {(errorSaveTemplate) &&
                                            <div className="alert alert-danger mt-2">
                                                {errorSaveTemplate.map((value,ind)=>
                                                    <span key={ind}>{value}<br/></span>
                                                )}
                                            </div>
                                        }
                                        {(saveSuccess) &&
                                            <div className="alert alert-success mt-2">
                                                {saveSuccess}
                                            </div>
                                        }
                                        <button onClick={() => saveTemplate()} disabled={btnValidate} className="btn btn-primary btn-sm mt-3">
                                            {btnValidate ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                                        </button>
                                    </div>
                                }
                            </div>
                            <PDFDownloadLink 
                                document={<Etiquette imageData={imageData} widthCm={canvasCm.width} heightCm={canvasCm.height}/>}
                                fileName={print}
                            >
                                {({ url }) => (
                                    <button ref={btnPdfDl} className="d-none">print</button>
                                )}
                            </PDFDownloadLink>
                        </div>
                    }
                </>
            }
        </>
    );
};

export default EtiquetteTemplate;
