import React, {useState,useContext,useEffect,useRef} from "react";
import {EtiquetteTemplate,SelectPrinterErp} from "@gull"
import { useTranslate } from 'react-redux-multilingual';
import {imprimeEtiquette} from 'app/imprimeEtiquette'
import { IsAuthContext } from '../../../app/App';
import { espace_mille } from 'app/espace_mille';
import swal from "sweetalert2";
import axios from 'axios'

const useLoad = (from) => {
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext);
    const [loading,setLoading]=useState(true)
    const [company,setCompany]=useState({})

    useEffect(function () {
        (async function() {
            if(from!='pos'){
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/company/"+authParam.company.id
                ).then((response) => {
                    setLoading(false)
                    setCompany(response.data)
                }).catch((error) => {
                    if(error=='end_session') return;
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                    setLoading(false)
                })
            }
        })()
    }, [])

    return [loading,company]
}

const ModalTagPrintForm = (props) =>{
    const t = useTranslate();
    const [loading,company] = useLoad(props.from)
    const [qteTag,setQteTag]=useState(1)
    const [sizeTag,setSizeTag]=useState('') 
    const { authParam } = useContext(IsAuthContext)
    
    const [urlCertificat,setUrlCertificat] = useState('')
    const redirectCertificat = useRef('')
    const [errorCookie,setErrorCookie] = useState(false)

    const [relanceEtiquetteTemplates, setRelanceEtiquetteTemplates] = useState(false);
   

    let cookie
    if(props.cookies){
        if(props.cookies.peripherique){
            cookie = props.cookies.peripherique.split('/!')
        }else{
            cookie = []
        }
    }
    // else if(props.from=='erp'){
    //     if(props.peripheriqueParam.peripheriqueTagErp){
    //         cookie = props.peripheriqueParam.peripheriqueTagErp.split('/!')
    //     }else{
    //         cookie = []
    //     }
    // }

    let param = (props.cookies) ? cookie : props.peripheriqueParam
    //Dans le module WMS, on utilise le cookie 
    
    let devise = authParam.company.devise;
    let formatedPrice = espace_mille(props.articlePrice)

    useEffect(function () {
        if(props.hasOwnProperty('qteToPrint') && props.qteToPrint && +props.qteToPrint!=0 ) setQteTag(props.qteToPrint)
    }, [])

    const handleChange = (e,name) =>{

        if(name=="qte"){
            setQteTag(e.target.value.replace(/\D/g,''))
        }else{
            setSizeTag(e.target.value)
        }
        
    }

    const handleCheckRadio = () => {
        //Dans le module wms on garde le fonctionnement avec le cookie pour l'impréssion ZPL
        //Sinon on donne la possibilité de de personnaliser l'étiquette mais l'utilisatauer ne pourra pas imprimer son étiquette mais générer un pdf pour l'imprimer 
        if(props.imprimanteType){
            props.setParamPrinter(false)
        }else{
            props.setParamPrinter({
                imprimante_etiquette:{model:'image_file'}
            })
        }
    
    };

    const handleSubmit = () =>{
        setErrorCookie(false)
        if(sizeTag==''){
            swal.fire(t('sizeTagRequired'), "", "error");
        }else if(qteTag>100){
            swal.fire(t('limitTagPrint100'), "", "error");
        }else if(qteTag<1){
            swal.fire(t('mintTagPrint1'), "", "error");
        }else{
            //window.open(process.env.REACT_APP_URL_ETIQUETTE)
            if(Array.isArray(param) && param.length==0){
                setErrorCookie(true)
            }else{
                imprimeEtiquette(param,devise,formatedPrice,t,props.articleName,props.articleUpc,qteTag,props.from,sizeTag,setUrlCertificat,redirectCertificat)
            }
        }
    }

    return(
        <div>
            {props.from!='wms' ?
                <span className='row'>
                    <SelectPrinterErp
                        company={company}
                        setParamPrinter={props.setParamPrinter}
                        style='form-group col'
                        from={props.from}
                    />
                </span>
            :
                <div className="form-check mb-2">
                    <input type="radio" checked={props.imprimanteType} className="form-check-input" onChange={()=>handleCheckRadio()}/>
                    <label className="form-check-label">{t('customizeTag')}</label>
                </div>
            }
            <a ref={redirectCertificat} href={urlCertificat} className="d-none" target="_blank"></a>
            <div className="row position-relative">
                <div className={`${props.imprimanteType!="image_file" ? "col-12 form-group" : "col-12 col-lg-6 form-group positionInputPrintTag"}`}>
                    <label>{t('qteTag')}</label>
                    <input type="text" className="form-control mb-3" value={qteTag} onChange={(e)=>handleChange(e,'qte')}/>
                </div>
                {props.imprimanteType!="image_file" &&
                    <div className="col-12 form-group">
                        <label>{t('tagSize')}</label>
                        <select className="form-control mb-3" value={sizeTag} onChange={(e)=>handleChange(e,'size')}>
                            <option value="">--Select--</option>
                            <option value="300">35x30mm</option>
                            <option value="220">35x22mm</option>
                        </select>
                    </div>
                }
            </div>

            {props.imprimanteType=="image_file" &&
                <EtiquetteTemplate 
                    isfor="edit"
                    relanceEtiquetteTemplates={relanceEtiquetteTemplates}
                    setRelanceEtiquetteTemplates={setRelanceEtiquetteTemplates}

                    peripheriqueParam={props.peripheriqueParam}
                    articleSelected={props.articleSelected}
                    qteTag={qteTag}
                    from={props.from}
                />
            }

            {(errorCookie) ? <div className="alert alert-danger mb-2 mt-2">{t('noConfiguredPeripheral')}</div> : false}
            {props.imprimanteType!="image_file" &&
                <button className="btn btn-primary" onClick={() => handleSubmit()}>{t('btnPrint')}</button>
            }
        </div>
    )
}

export default ModalTagPrintForm;