
let translation = {
    en: {
      locale: 'en-US',
      messages: {
        /*********form response***********/
        //required
        stockMinRequired:'The trigger threshold is required',
        stockGoodRequired:'The restock quantity is required',
        nameRequired: 'The "name" field is required',
        firstnameRequired: 'The "first name" field is required',
        emailRequired: 'The "email" field is required',
        mailRelanceRequired: 'The "Reminder email" field is required',
        passwordRequired: 'The "password" field is required',
        password_confirmationRequired: 'The "password confirmation" field is required',
        companyRequired: 'The "company" field is required',
        moduleRequired: 'The "module" field is required',
        sectionRequired: 'The "shop" field is required',
        sexeRequired: 'The "sexe" field is required',
        sizeTagRequired:'You must indicate a size',
        typefideliteRequired: 'The "Type of Loyalty Card" field is required',
        maxusemoisRequired: 'The "Period of validity" field is required',
        maxusemoisproformaRequired: 'The "Validity period of a proforma" field is required',
        modepaiementRequired: 'The "Payment methods" field is required',
        themeRequired: 'The "Theme" field is required',
        donneeclientRequired: 'The "Activate the obligation to collect customer data " field is required',
        objectifvendeurRequired: 'The "Seller objective" field is required',
        objectifcommerceRequired: 'The "Shop objective" field is required',
        dixmilleRequired: 'You must indicate the number of 10 000 XPF banknotes',
        cinqmilleRequired: 'You must indicate the number of 5 000 XPF banknotes',
        milleRequired: 'You must indicate the number of 1 000 XPF banknotes',
        cinqcentRequired: 'You must indicate the number of 500 XPF banknotes',
        deuxcentRequired: 'You must indicate the number of  200 XPF coins',
        centRequired: 'You must indicate the number of  100 XPF coins',
        cinquanteRequired: 'You must indicate the number of  50 XPF coins',
        vingtRequired: 'You must indicate the number of  20 XPF coins',
        dixRequired: 'You must indicate the number of  10 XPF coins',
        cinqRequired: 'You must indicate the number of  5 XPF coins',
        deuxRequired: 'You must indicate the number of  2 XPF coins',
        unRequired: 'You must indicate the number of  1 XPF coins',
        gradeRequired: 'You must indicate a grade',
        caRequired: 'You must indicate a CA HT',
        cattcRequired: 'You must indicate a CA TTC',
        pmRequired: 'You must indicate a PM HT',
        pmttcRequired: 'You must indicate a PM TTC',
        tfRequired: 'You must indicate a TF',
        ivRequired: 'You must indicate a IV',
        entreRequired: 'You must indicate a number of customers passages (entry and exit)',
        beginRequired: 'You must indicate de start date',
        endRequired: 'You must indicate de end date',
        dayRequired: 'You must indicate the day',
        telephoneRequired: 'Phone is required',
        adresseRequired: 'Pysical Adress is required',
        adressepostaleRequired: 'Postal Adress is required',
        newsletterRequired: 'You must check the newsletter',
        civiliteRequired: 'Civility is required',
        csvEncodeRequired: 'CSV Encode is required',
        csvSeparatorRequired: 'CSV Separator is required',
        csvDateFormatRequired: 'CSV Date Format is required',
        codeRequired:'Code is required',
        rcsRequired:'RCS is required',
        adressRequired:'Adress is required',
        codePostalRequired:'Zip code is required',
        villeRequired:'Town is required',
        paysRequired:'Country is required',
        codeBanqueRequired: 'Bank Code is required',
        codeGuichetRequired: 'Sort Code is required',
        numeroCompteRequired: 'Account Number is required',
        cleRibRequired: 'RIB Key is required',
        quartierRequired: 'An area is required',
        typeRequired: 'A type is required',
        unspecified:'Unspecifed',
        dateDeNaissanceRequired: 'A date of birth is required',
        providerNameRequired: 'You must indicate a provider name',
        contactNameRequired: 'You must indicate a contact name',
        statusRequired: 'You must indicate a status',
        itemFamilyNameRequired: 'You must indicate an item family name',
        brandNameRequired: 'You must indicate un brand name',
        categoryRequired: 'You must indicate a category',
        referenceRequired: 'You must indicate a UPC Code',
        referenceToRequired: 'You must indicate a reference',
        skuRequired: 'You must indicate a SKU',
        internalidRequired: 'You must indicate the internal ID of the item',
        priceRequired: 'You must indicate a Price duty includes',
        tgcRequired: 'You must indicate the TGC rate',
        prmpRequired: 'You must indicate the Average Cost',
        havestockRequired: 'You must indicate if this item must be dependent on a stock',
        colorHexaRequired: 'You must indicate a hexadecimal color code',
        banqueRequired: 'You must indicate the bank name',
        customFieldRequired: 'You must fill the "{name}"',
        numeroShipmentRequired:'You must indicate the shipment number',
        shdeCartonNameRequired:'You must indicate the box reference',
        shdeQuotaPrevuRequired:'You must indicate the expected quatity',
        firstStartBinsRequired:'You must indicate the beginning of the first level',
        firstEndBinsRequired:'You must indicate the end of the first level',
        zoneRequired:'You must indicate the zone',
        secondEndBinsRequiredWith:'You must indicate the end of the second level if the beginning of the second level is filled',
        thirdEndBinsRequiredWith:'You must indicate the end of the third level if the beginning of the third level is filled',
        fourthEndBinsRequiredWith:'You must indicate the end of the fourth level if the beginning of the fourth level is filled',
        fifthEndBinsRequiredWith:'You must indicate the end of the fifth level if the beginning of the fifth level is filled',
        firstBinsRequired:'You must indicate the first level',
        quotaRequired: 'The quantity is required',
        actionRequired:'You must indicate the action',
        binsRequired:'You must indicate the bins',
        quantiteRequired:'you must indicate the quantity',
        fieldRequired:'This field is required',
        expectedRequired: 'The desired date of execution is required',
        rhRequired: 'A staff is required',
        rhBarreRequired: 'A staff barcode is required',
        osectionRequired: 'The origin shop is required',
        fsectionRequired: 'The destination shop is required',
        rpRequired: 'The reorder point is required',
        pslRequired: 'The preferred stock level is required',
        toMaxRequired: 'The maximum number of instructions in a transfert order is required',
        articleRequired: 'You must choose an item',
        promotionRequired: 'You muste choose a promotion',
        allFieldsRequired: 'All fields should be filled',
        promoImport:'Promotions import',
        codeCaisseRequired: 'A POS identifier is required',
        caisseIdentRequired:'The POS identifier is required',
        hostRequired:'You must provide an IP address',
        comPortRequired:'You must provide a COM port',
        modelRequired:'You must choose a model',
        poRequired:'Purchase Order is required',
        waitingEpt:'Waiting for EPT',
        errorEpt:'Error response EPT',
        newCheckoutShortcutProfil:'New shortcut profil',
        checkoutShorcutProfil:'Shortcuts profil',
        titreRequired:'Title is required',
        messageRequired:'Message is required',
        dateRequired:'Date is required',
        capitalRequired:'Capital is required',
        exerciceStartRequired:'Exercise start is required',
        montantRequired:'You must provide an amount',
        itemsRequiredInTo:'You can\'t submit a transfer order without items',
        pageNamerequired:'You must indicate the page name',
        filtreNamerequired:'You must indicate the favorite name',
        heureRequired:'Hours is required',
        minuteRequired:'Minutes is required',
        valueRequired:'A value is required',
        clientRequired:'Client is required',
        noteRequired:'Note is required',
        delaiReglementRequired:'Paiement time is required',
        //Min
        capitalMin1:'The capital must be at least 1 XPF',
        poidsMin1:'The minimum weight is 1',
        exerciceStartMin1:'The exercise start must at least 1 (january)',
        pageNameMin2: 'The page name must be at least 2 characters long',
        filtreNameMin2: 'The favorite name must be at least 2 characters long',
        nameMin2: 'The "name" field must be at least 2 characters long',
        nameMin5: 'The "name" field must be at least 5 characters long',
        skuMin2: 'The "Sku" field must be at least 2 characters long',
        firstnameMin2: 'The "first name" field must be at least 2 characters long',
        passwordMin8: 'The "password" field must be at least 8 characters long',
        phonedMin6: 'The "phone" field must be at least 6 characters long',
        phoneMin6: 'The "phone" field must be at least 6 characters long',
        maxusemoisdMin1: 'The "Period of validity" field must be at least 1 month',
        maxusemoisproformaMin1: 'The "Validity period of a proforma" field must be at least 1 month',
        dixmilleMin0: 'The number of 10 000 XPF banknotes must be at least 0',
        cinqmilleMin0: 'The number of 5 000 XPF banknotes must be at least 0',
        milleMin0: 'The number of 1 000 XPF banknotes must be at least 0',
        cinqcentMin0: 'The number of 500 XPF banknotes must be at least 0',
        deuxcentMin0: 'The number of 200 XPF coins must be at least 0',
        centMin0: 'The number of 100 XPF coins must be at least 0',
        cinquanteMin0: 'The number of 50 XPF coins must be at least 0',
        vingtMin0: 'The number of 20 XPF coins must be at least 0',
        dixMin0: 'The number of 10 XPF coins must be at least 0',
        cinqMin0: 'The number of 5 XPF coins must be at least 0',
        deuxMin0: 'The number of 2 XPF coins must be at least 0',
        unMin0: 'The number of 1 XPF coins must be at least 0',
        caMin0: 'The CA HT must be at least 0',
        cattcMin0: 'The CA TTC must be at least 0',
        pmMin0: 'The PM HT must be at least 0',
        pmttcMin0: 'The PM TTC must be at least 0',
        tfMin0: 'The TF must be at least 0',
        ivMin0: 'The IV must be at least 0',
        entreMin0: 'The number of customers passages (entry and exit) must be at least 0',
        codeMin3: 'The "code" field must be at least 3 characters long',
        rcsMin5: 'The "RCS" field must be at least 5 characters long',
        codeBanqueMin5: 'The "Bank Code" field must be at least 5 characters long',
        codeGuichetMin5: 'The "Sort Code" field must be at least 5 characters long',
        numeroCompteMin11: 'The "Account Number" field must be at least 11 characters long',
        cleRibMin2: 'The "RIB Key" field must be at least 2 characters long',
        nomContactMin2: 'Contact name must be at least 2 characters long',
        priceMin: 'The price can\'t be under 0',
        promoPriceMin: 'The promotionnal price can\'t be under 0',
        tgcMin: 'The TGC rate can\'t be under 0',
        colorHexaMin: 'The hexadecimal color code must have 8 characters',
        buyPriceMin: 'The buying price can\'t be under 0',
        prmpMin: 'The Average Price can\'t be under 0',
        prMin: 'The Cost Price can\'t be under 0',
        indicePrmpMin: 'The Average Cost indice can\'t be under 0',
        indicePrMin: 'The Cost Price indice can\'t be under 0',
        deviseAchatMin: 'The buying currency must have 3 characters',
        quotaMin: 'The quantity can\'t be under 0',
        quotaCommandeMin: 'The quantity can\'t be under 0',
        numeroShipmentMin2:'The field "shipment number" must be at least 2 characters long',
        shdeCartonNameMin2: 'The box reference must be at least 2 characters long',
        shdeQuotaPrevuMin: 'The expected quantity can\'t be under 0',
        firstStartBinsMin0:'The beginning of the first level can\'t be under 1',
        firstEndBinsMin0:'The end of the first level can\'t be under 1',
        secondStartBinsMin0:'The beginning of the second level can\'t be under 1',
        secondEndBinsMin0:'The end of the second level can\'t be under 1',
        thirdStartBinsMin0:'The beginning of the third level can\'t be under 1',
        thirdEndBinsMin0:'The end of the third level can\'t be under 1',
        fourthStartBinsMin0:'The beginning of the fourth level can\'t be under 1',
        fourthEndBinsMin0:'The end of the fourth level can\'t be under 1',
        fifthStartBinsMin0:'The beginning of the fifth level can\'t be under 1',
        fifthEndBinsMin0:'The end of the fifth level can\'t be under 1',
        firstBinsMin0:'The first level can\'t be under 1',
        secondBinsMin0:'The second level can\'t be under 1',
        thirdBinsMin0:'The third level can\'t be under 1',
        fourthBinsMin0:'The fourth level can\'t be under 1',
        fifthBinsMin0:'The fifth level can\'t be under 1',
        referencePoMin2:'The purchase order reference must be at least 2 characters long',
        rpIsMin0:'{name} reorder point RP can\'t be under 0',
        pslIsMin0:'{name} preferred stock level PSL can\'t be under 0', 
        quantiteMin1:'The quantity can\'t be under 1',
        quantiteMin0:'The quantity can\'t be under 0',
        referenceToMin2:'Reference must be at least 2 characters long',
        rpMin0:'The reorder point can\'t be less than 0',
        pslMin0:'Le preferred stock level can\'t be less than 0',
        toMaxMin10:'The maximum number of instructions in a transfert order can\'t be less than 10',
        giftCardMin1:'The validity period of a deposit on a gift card cannot be less than 1 month',
        giftCardMontantMin: 'The Gift Card amount can\'t be less than 1',
        paiementMin: 'The paiements indications must be over 0',
        modifManualValueMin1: 'The value must be over 1',
        codeCaisseMin4: 'The POS ident must be at least 4 characters long',
        detailModePaiementMin0: 'The values can\'t be less than 0',
        titreMin2: 'The title can\'t be less than 2 characters long',
        messageMin2:'The message can`t be less than 2 characters long',
        typeMin2:'The type can`t be less than 2 characters long',
        ticketNameMin1:'The ticket titled can`t be less than 1 characters long',
        heureMin0:'Hours can\'t be less than 0',
        minuteMin0:'Minutes can\'t be less than 0',
        //Max
        exerciceStartMax12:'The exercise start cannot be higher than 12 (december)',
        poidsMax100:'The maximum weight is 100',
        lineLimitationZoneContent:'The display is limited to 1000 lines',
        quantiteMax8000000:'The quantity must be less than 8 000 000',
        nameMax191: 'The "name" field must be less than 191 characters',
        nameMax250: 'The "name" field must be less than 250 characters',
        nameMax50: 'The "name" field must be less than 50 characters',
        skuMax250: 'The "name" field must be less than 250 characters',
        phoneMax20: 'The "phone" field must be less than 20 characters',
        firstnameMax50: 'The "first name" field must be less than 50 characters',
        firstnameMax191: 'The "first name" field must be less than 191 characters',
        nameMax20: 'The "name" field must be less than 20 characters',
        logoMax50: 'The "logo" field must be less than 50 characters',
        gradeMax50: 'The "grade" field must be less than 50 characters',
        maxusemoisdMax120: 'The "Period of validity" field cannot exceed 120 months',
        maxusemoisproformaMax120: 'The "Validity perdiod of a proforma" field cannot exceed 120 months',
        caMax2000000000: 'The CA HT cannot exceed 2 000 000 000 XPF',
        cattcMax2000000000: 'The CA TTC cannot exceed 2 000 000 000 XPF',
        pmMax2000000000: 'The PM HT cannot exceed 2 000 000 000 XPF',
        pmttcMax2000000000: 'The PM TTC cannot exceed 2 000 000 000 XPF',
        tfMax100: 'The TF cannot exceed 100 %',
        ivMax999: 'The IV cannot exceed 999 items',
        entreMax10000000: 'The number of customers passages (entry and exit) cannot exceed 10 000 000',
        codeMax5: 'The "code" field must be less than 5 characters',
        rcsMax20: 'The "RCS" field must be less than 20 characters',
        codeBanqueMax5: 'The "Bank Code" field must be less than 5 characters',
        codeGuichetMax5: 'The "Sort Code" field must be less than 5 characters',
        numeroCompteMax11: 'The "Account Number" field must be less than 11 characters',
        cleRibMax2: 'The "RIB Key" field must be less than 2 characters',
        nomContactMax191: 'Contact name must be less than 191 characters',
        priceMax: 'The price can\'t be over 999999999.99',
        referenceMax191: 'The UPC code must be less than 191 characters',
        reference2Max191: 'The UPC code 2 must be less than 191 characters',
        internalIdMax191: 'The internal ID must be less than 191 characters',
        referenceToMax191: 'The reference must be less than 191 characters',
        skuMax191: 'The SKU must be less than 191 characters',
        parentNameMax250: 'The Parent Name must be less than 250 characters',
        promoPriceMax: 'The promotionnal price can\'t be over 999999999.99',
        prmpMax: 'The Average Cost can\'t be over 999999999.99',
        prMax: 'The Cost Price can\'t be over 999999999.99',
        tgcMax: 'The TGC rate can\'t be over 100',
        imageMax250: 'The image link must be less than 250 characters',
        colorHexaMax: 'The hexadecimal color code must have 8 characters',
        buyPriceMax: 'The buying price can\'t be over 999999999.99',
        indicePrmpMax: 'The indice Average Cost can\'t be over 999999999.99',
        indicePrMax: 'The indice Coast Price can\'t be over 999999999.99',
        deviseAchatMax: 'The buying currency must have 3 characters',
        maxPhoneLength6: 'Phone number must contains 6 numbers maximum',
        quotaCommandeMax: 'The quantity can\'t be over 8000000',
        quotaMax: 'The quantity can\'t be over 8000000',
        quotaMax10000: 'The quantity can\'t be over 10000',
        numeroShipmentMax190:'The shipment number must be less than 190 characters',
        shdeCartonNameMax250: 'The box reference must be less than 250 characters',
        shdeQuotaPrevuMax: 'The expected quantity can\'t be over 8000000',
        firstStartBinsMax32000:'The beginning of the first level can\'t be over than 32000',
        firstEndBinsMax32000:'The end of the first level can\'t be over than 32000',
        secondStartBinsMax32000:'The beginning of the second level can\'t be over than 32000',
        secondEndBinsMax32000:'The end of the second level can\'t be over than 32000',
        thirdStartBinsMax32000:'The beginning of the third level can\'t be over than 32000',
        thirdEndBinsMax32000:'The end of the third level can\'t be over than 32000',
        fourthStartBinsMax32000:'The beginning of the fourth level can\'t be over than 32000',
        fourthEndBinsMax32000:'The end of the fourth level can\'t be over than 32000',
        fifthStartBinsMax32000:'The beginning of the fifth level can\'t be over than 32000',
        fifthEndBinsMax32000:'The end of the fifth level can\'t be over than 32000',
        firstBinsMax32000:'The first level can\'t be over than 32000',
        secondBinsMax32000:'The second level can\'t be over than 32000',
        thirdBinsMax32000:'The third level can\'t be over than 32000',
        fourthBinsMax32000:'The fourth level can\'t be over than 32000',
        fifthBinsMax32000:'The fifth level can\'t be over than 32000',
        referencePoMax190:'The purchase order reference must be less than 190 characters',
        rpIsMax8000000:'{name} reorder point RP can\'t be over than 8000000',
        pslIsMax8000000:'{name} preferred stock level PSL can\t be over than 8000000',
        generateBarcodeMax1000:'You can\'t generate more than 1000 barcode',
        rpMax8000000:'The reorder point can\'t be over 8000000',
        pslMax8000000:'Le preferred stock level can\'t be over 8000000',
        toMaxMax1000:'The maximum number of instructions in a transfert order can\'t be over 1 000',
        giftCardMax120:'The validity period of a deposit on a gift card cannot be more than 120 month',
        giftCardMontantMax: 'The Gift Card amount can\'t be over 100 000 000',
        paiementMax: 'The paiements indications must be less than 1 000 000 000',
        codeCaisseMax30: 'The POS ident must be less than 30 characters long',
        cashOutReasonMax100: 'The cash out reason must be less than 100 characters',
        titreMax250: 'The title must be less than 250 characters',
        typeMax50: 'The type must be less than 50 characters',
        ticketNameMax15:'The title on receipt must be less than 15 characters long',
        heureMax23:'Hours must be less than 23',
        minuteMax59:'Minutes must be less than 59',
        //email
        emailEmail: 'The "email" field must be a valid email',
        //unique
        emailUnique: 'There is already an account registered with this email address',
        nameUnique: 'This name already exists',
        skuUnique: 'An other item already have the same Sku',
        referenceUnique: 'The UPC Code is already use by an other item',
        referenceEnumereUnique: 'The bar code of the packagin is already use by an other an other packaging',
        reference2Unique: 'The UPC Code 2 is already use by an other item',
        skuUnique: 'The SKU is already use by an other item',
        alreadyExist: 'has already been assigned',
        sectionUnique: 'This section already exist',
        zoneInventaireAlreadyExists: 'You already have an inventory area with the same name',
        //confirmed
        passwordConfirmed: 'The password was incorrectly confirmed',
        confirmDifferentQuantityCollected:'The collected quantity does not match with the quantity asked, confirm ?',
        confirmDifferentQuantityReceipt:'The received quantity does not match with the collected quantity, confirm ?',
        confirmCancelSaleOrder:'Are you sure you want to cancel this sale order',
        confirmValidateDocument:'Are you sure you want to validate this document',
        confirmCancelDocument:'Are you sure you want to cancel this invoice ? That will create a Credit note.',
        confirmValidateDeliveryNote:'Are your sure you want to validate this delivery note ? This will remove the items from this delivery note from stock',
        confirmConvertDeliveryNoteToInvoice:'Are your sure you want to convert this document in invoice?',
        //In
        roleIn: 'The assigned role is not accepted',
        sexeIn: 'The assigned sexe is not accepted',
        themeIn: 'The assigned theme is not accepted',
        modepaiementIn: 'One of the payment methods indicated is not accepted',
        priorityIn: 'The "Priority" value must be equal to Yes or No',
        customFieldIn: 'The field "{name}" has an invalid value',
        //exists
        companyExists: 'This company does not exist',
        moduleExists: 'This module does not exist',
        sectionExists: 'This shop does not exist',
        rhExists: 'This staff does not exist',
        gradeExists: 'This grade does not exist',
        typefideliteExists: 'This type of Loyalty Card does not exist',
        programfideliteExists: 'This program of Loyalty Card does not exist',
        programgiftcardExists: 'This program of Gift Card does not exist',
        clientExists: 'There is already a registered customer with this firstname and last name',
        giftCardExists: 'There is already a registered Gift Card with this reference',
        internalIdExists: 'Internal ID is incorrect',
        expectedInternalIdAlreadyExists: 'Expected Internal ID alrready exist',
        internalNotIdExists: 'Internal Id already exists',
        firstlevelcatExists: 'No match found with the "Classification" field',
        secondlevelcatExists: 'No match found with the "Femille" field',
        thirdlevelcatExists: 'No match found with the "Sous-famille" field',
        fourthlevelcatExists: 'No match found with the "Catégorie" field',
        fifthlevelcatExists: 'No match found with the "Sous-catégorie" field',
        fournisseurExists: 'No match found with the "Fournisseur" field',
        marqueExists: 'No match found with the "Marque" field',
        nameExists: 'This name is already in use',
        referenceExists: 'This reference is already in use',
        articleNameExists: 'No item matches this item name',
        articleNameAlreadyExists: 'This item name is already use by an other item',
        articleSkuExists: 'No item matches this Sku',
        poNameExists: 'This purchase orders doesn\'t exist',
        devisNotExists: 'This quotation doesn\'t exist',
        sectionCodeExists: 'This shop code doesn\'t exist',
        numeroShipmentExists: 'This shipment number is already used',
        shipmentNameExists: 'This shipment number doesn\'t exists',
        idsExists: 'ID is wrong',
        binsExists: 'A bins with the same adress already exist for this stock zone',
        shdeCartonNameExists: 'This box doesn\'t exist in this shipment',
        binsAlreadyExistsInSection: 'There is already a bins with the same adress in this shop',
        binsNotInThisSection: 'This bins doesn\'t exists in this shop',
        binsNotInThisZone: 'This bins doesn\'t exists in this location',
        zoneNotInThisSection: 'This location doesn\'t exists in this shop',
        binsNotExist: 'This bins doesn\'t exists',
        binsNotExistInToList: 'This bins doesn\'t appear in this tansfer order',
        binsNotExistInInventory:'This bins not appear in your inventory zone',
        itemNotExistInInventory:'This item not appear in your inventory zone',
        codeCaisseExists: 'This POS ident is already use',
        caisseIdentExists:'This POS identifier doesn\'t exist',
        namePeripheriqueExists:'This device name is already in use',
        articleAlreadyInTo:'Item is already in this transfer order',
        //array
        moduleArray: 'An unexpected error has occurred',
        sectionArray: 'An unexpected error has occurred',
        rhArray: 'An unexpected error has occurred',
        modepaiementArray: 'An unexpected error has occurred',
        raisonmodifprixArray: 'An unexpected error has occurred',
        //General
        sectionGeneral: 'An unexpected error has occurred',
        rhGeneral: 'An unexpected error has occurred',
        programfideliteGeneral: 'An unexpected error has occurred',
        programgiftcardGeneral: 'An unexpected error has occurred',
        forbiddenGeneral: 'You are not authorized to perform this action',
        //Numeric
        maxusemoisNumeric: 'The "Period of validity" field must be a numeric value',
        maxusemoisproformaNumeric: 'The "Validity period of a proforma" field must be a numeric value',
        dixmilleNumeric: 'The 10 000 XPF banknotes must be a numeric value',
        cinqmilleNumeric: 'The 5 000 XPF banknotes must be a numeric value',
        milleNumeric: 'The 1 000 XPF banknotes must be a numeric value',
        cinqcentNumeric: 'The 500 XPF banknotes must be a numeric value',
        deuxcentNumeric: 'The 200 XPF coins must be a numeric value',
        centNumeric: 'The 100 XPF coins must be a numeric value',
        cinquanteNumeric: 'The 50 XPF coins must be a numeric value',
        vingtNumeric: 'The 20 XPF coins must be a numeric value',
        dixNumeric: 'The 10 XPF coins must be a numeric value',
        cinqNumeric: 'The 5 XPF coins must be a numeric value',
        deuxNumeric: 'The 2 XPF coins must be a numeric value',
        unNumeric: 'The 1 XPF coins must be a numeric value',
        caNumeric: 'The CA HT must be a numeric value',
        cattcNumeric: 'The CA TTC must be a numeric value',
        pmNumeric: 'The PM HT must be a numeric value',
        pmttcNumeric: 'The PM TTC must be a numeric value',
        tfNumeric: 'The TF must be a numeric value',
        ivNumeric: 'The IV must be a numeric value',
        capitalNumeric:'The capital must be a numeric value',
        exerciceStartNumeric:'The exercise start must be a numeric value',
        entreNumeric: 'The number of customers passages (entry and exit) must be a numeric value',
        codeBanqueNumeric: 'The "Bank Code" must be a numeric value',
        codeGuichetNumeric: 'The "Sort Code" must be a numeric value',
        numeroCompteNumeric: 'The "Account Number" must be a numeric value',
        cleRibNumeric: 'The "RIB Key" must be a numeric value',
        priceNumeric: 'The Price must be a numeric value',
        promoPriceNumeric: 'The Promotionnal Price must be a numeric value',
        prmpNumeric: 'The Average Coste must be a numeric value',
        prNumeric: 'The Cost Price must be a numeric value',
        tgcNumeric: 'The TGC rate must be a numeric value',
        buyPriceNumeric: 'The buying price must be a numeric value',
        indicePrmpNumeric: 'The Average Cost indice must be a numeric value',
        indicePrNumeric: 'The Cost Price indice must be a numeric value',
        internalIdNumeric: 'Internal Id must be a numeric value',
        expectedInternalIdNumeric: 'Expected Internal Id must be a numeric value',
        referenceNumeric: 'The UPC code must be a numeric value',
        quotaNumeric: 'The quantity must be a numeric value',
        quotaCommandeNumeric: 'The quantity must be a numeric value',
        shdeQuotaPrevuNumeric: 'The expected quantity must be a numeric value',
        firstStartBinsNumeric: 'The beginning of the first level must be a numeric value',
        firstEndBinsNumeric: 'The end of the first level must be a numeric value',
        secondStartBinsNumeric: 'The beginning of the second level must be a numeric value',
        secondEndBinsNumeric: 'The end of the second level must be a numeric value',
        thirdStartBinsNumeric: 'The beginning of the third level must be a numeric value',
        thirdEndBinsNumeric: 'The end of the third level must be a numeric value',
        fourthStartBinsNumeric: 'The beginning of the fourth level must be a numeric value',
        fourthEndBinsNumeric: 'The end of the fourth level must be a numeric value',
        fifthStartBinsNumeric: 'The beginning of the fifth level must be a numeric value',
        fifthEndBinsNumeric: 'The end of the fifth level must be a numeric value',
        firstBinsNumeric: 'The first level must be a numeric value',
        secondBinsNumeric: 'The second level must be a numeric value',
        thirdBinsNumeric: 'The third level must be a numeric value',
        fourthBinsNumeric: 'The fourth level must be a numeric value',
        fifthBinsNumeric: 'The fifth level must be a numeric value',
        rpIsNumeric:'{name} reorder point RP must be a numeric value',
        pslIsNumeric:'{name} preferred stock level PSL must be a numeric value',
        quantiteNumeric:'The quantity must be a numeric value',
        rpNumeric:'The reorder pointmust be a numeric value',
        pslNumeric:'Le preferred stock level must be a numeric value',
        toMaxNumeric:'The maximum number of instructions in a transfert order must be a numeric value',
        giftCardNumeric:'The validity period of a deposit on a gift card must be a numerical value',
        giftCardMontantNumeric: 'The Gift Card amount must be a numeric value',
        paiementNumeric: 'The paiements indications must be some numerics values',
        modifManualValueNumeric: 'The value must be numeric',
        detailModePaiementNumeric: 'All values must be numerics',
        maxArticleInventaire:'Your inventory cannot contain more than 500 references',
        maxZoneInventaire:'Your inventory cannot contain more than 500 inventory areas',
        //Boolean
        donneeclientBoolean: 'An unexpected error has occurred',
        objectifvendeurBoolean: 'An unexpected error has occurred',
        objectifcommerceBoolean: 'An unexpected error has occurred',
        //General
        unknowError: 'An unexpected error has occurred',
        identFailed: 'Login credentials are incorrect',
        //divers
        delivery:'Delivery',
        createDeliveryVoucher:'Create a voucher',
        validationDelivery:'Validation of vouchers',
        returnNotes:'Return notes',
        shipmentVouchers:'Shipment vouchers',
        poB2bWms:'Customer purchase order B2B',
        poB2cWms:'Customer purchase order B2C',
        createListItem:'Create a list of items',
        customizeTag:'Customize tag',
        generatedByPurchaseOrder:'Generated by a purchase order',
        billingInvoireGeneratedMsg:'A purchase invoice has been generated with this purchase order',
        selectAPrinter:'Select a printer',
        printerNameRegistered:'Printer name resgistered on Windows',
        image_file:'Image file (Windows)',
        defaultCustomer:'Default customer',
        noFolderLinked:'No related folder',
        convertPoBillinBuyErr:'This purchase order can\'t be converted because it contains more than one currency',
        convertIntoBillingBuy:'Convert into purchase invoice',
        dont:'Including',
        last_pr:'Last cost price',
        last_date_buy:'Last date of purchase',
        ecotaxe:'Eco-participation',
        quotaIsOverArticleQuantity:'The quantity is over the item quantity',
        qteToReceive:'Quantity to receive',
        docCompletelyReceived:'Document completely received',
        noMoreItemReceive:'There are no more items to receive',
        noReturnNote:'No return note',
        receiptReturnNote:'Receipt of return note',
        returnNote:'Return note',
        addToBoxMsg:'Do you want to integrate the selection into a box ?',
        poB2b:'Customer purchase order',
        collectPoClient:'Collection of customer purchase order',
        cancelFiltre:'Cancel the filter',
        showOnlyLineToBeConverted:'Show only the lines to be converted',
        allItemsConverted:'All the items have been converted',
        cancelledDocu:'Cancelled document',
        documentClosedAt:'Document closed at',
        confirmCancelpo:'Are you sur you want to cancel this purchase order',
        cancelDocument:'Cancel {document}',
        quantityAlreadyPo:'Converted quantity',
        quantityToConvert:'Quantity to convert',
        convertToPo:'Convert to purchase order',
        convertToCreditNote:'Convert to credit note',
        convertToDeliveryNote:'Convert to delivery note',
        deliveryNote:'Delivery Note',
        validateDeliveryNote:'Validate the delivery note',
        convertToReturnOrder:'Convert to return order',
        mustHave1Bouton:"The button prameters is required ([bouton]) and must be unique",
        linkToDouaneCat:'Link to a customs category',
        noSupplierProvided:'No supplier provided',
        noCurrencyProvided:'No currency provided',
        exchangeRate:'Exchange rate',
        indiceCalcul:'Prmp indice calculation',
        categorie_douane:'Customs category',
        confirmCancelFactureAchat:'Are you sure you want to cancel this invoice',
        billingBuy:'Purchase invoice',
        mustDeletePaymentBeforeCancel:'You must either cancel all the payments or complete the missing payments before canceling the invoice',
        linkToFolder:'Link to the folder',
        linkTo:'Link to',
        billingDeposit:'Deposit invoice',
        depositLabel:'Deposit',
        error404Msg:'Sorry! The page you were looking for doesn\'t exist',
        retourAccuil:'Go back to home',
        noEmailAdressConfigured:'No e-mail address configured',
        errDestinataireEmail:'Please include "@" in the email address',
        addMoreRecipientsMsg:'Add more recipients by separating e-mail adresses with a ","',
        documentAlreadyUseForPaiement:'This document is already used for the paiement of an other invoice',
        montantIsOver:'The amount indicated is higher than the remaining balance',
        changeFolder:'Change folder',
        allInfosCorrect:'Is all the information correct ?',
        allDocuments:'All documents',
        noPriorityPricing:'No priority price',
        priorityPricingCat:'Priority pricing category',
        noB2cPrice:'No B2C price provided for this item',
        searchForExistingFolder:'Search for an existing folder',
        folderNotExist: 'The folder n° {numeroDossier} doesn\'t exist',
        folder:'Folder',
        linkToOtherCompany:'Link to an other company',
        docName:'Folder name',
        linkToDoc:'Link to a folder',
        createFolder:'Create a folder',
        createDocument:'Create a document',
        createTemplate:'Create a template',
        editTemplate:'Edit a template',
        deleteEmailAccount:"Delete the e-mail account",
        send:'Send',
        sendTestEmail:'Send a test e-mail',
        addEmailAccount:'Add an e-mail account',
        emailAccount:'Emails accounts',
        emailLayout:'Emails layouts',
        miseEnPage:'Layout',
        balisesPossibles:'Possibles anchors',
        newEmailAccount:'New email account',
        accountName:'Account name',
        emailAdress:'E-mail adress',
        hostOutgoing:'Outgoing mail server',
        username:'User name',
        allFieldRequiredForEnumere:'All the packaging list fields must be filled (name, bar code, quantity)',
        price:'Price',
        prixAchat:'Buy price',
        addAdditionalPrice:'Add an additional price',
        portrait:'Portrait',
        landscape:'Landscape',
        generateExport:'Generate an export',
        referedToQuotation:'Refered to quotation n°',
        referedToDocument:'Refered to document n°',
        parentToDocument:'Parent of document(s)',
        childToDocument:'Child of document(s)',
        priceTtcB2c:'B2C price with duty included',
        priceTtcB2b:'B2C price with duty included',
        priceHtB2b:'Price B2B without duty',
        priceHtB2c:'Price B2C without duty',
        margeB2b:'B2B gross margin',
        margePB2b:'B2B margin %',
        margeB2c:'B2C gross margin',
        margePB2c:'B2C margin %',
        b2bPrice:'B2B price',
        b2cPrice:'B2C price',
        mustIndicateAPrice:'You must indicate at least one price',
        additionalPriceName:'Additional price name',
        additionalPrice:'Additional price',
        includedTaxe:'Taxes included',
        useB2bPrice:'Use B2B price',
        useB2cPrice:'Use B2C price',
        itemPrice:'Item price',
        productPriceMaxNumber:'Maximum number of additional price for a product',
        noUserRestricted:'No users are restricted',
        unauthorized:'Unauthorized',
        fieldReading:'Field reading',
        fieldEditing:'Field editing',
        fieldReadingNotAvl:'Reading not available for',
        fieldEditingNotAvl:'Editing not available for',
        designation:'Designation',
        accessMenu:'Access',
        stockInZoneNotSufficient:'The stock in your area or bins is not sufficient to perform your action',
        padFormat:'PAD format',
        smallPad:'Small PAD',
        bigPad:'Big PAD',
        seuilMiniCashBack:'Minimum cashback threshold',
        reliquatCashBack:'Requirement to use all cashback',
        hostname:'Hostname',
        noHostnameAssigned:'No hostname assigned',
        clientAlreadyAssigned:'This customer has already been associated or is in the process of association',
        relatedCompanies:'Related companies',
        poids:'Weight',
        companyOrOtherCompany:'Company or other company',
        noAssignablePromo:'No assignable promotions',
        noAssignedPromo:'No assigned promotions',
        assignablePromo:'Assignable promotions',
        assignedPromo:'Assigned promotions',
        assignPromoClient:'Assign promotion',
        addCustomer:'Add customers',
        allModificationWillBeLost:'All modifications will be lost, continue ?',
        allCustomer:'All customers',
        listOfCustomer:'List of customer',
        selectionOfCustomer:'Selection of customer',
        selectionTemplate:'Select Template',
        voucherManagement:'Voucher management',
        itemListWillBeDelete:'Your selection of items will be deleted, continue ?',
        convertIntoToCollected:'Transfer order already collected',
        convertThisList:'Convert this list',
        quantityManualModificationListeArticle:'Manual quantity modification on items lists',
        noList:'No list',
        listName:'List name',
        listOfItems:'List of items',
        packaging:'Packaging',
        packagedQuantity:'Quantity packaged',
        packagingName:'Packaging name',
        enumere:'Packaging list',
        packagingList:'Packaging list',
        pricingHt:'Pricing excluding taxes',
        nbSales:'Number of sales',
        qteSold:'Quantity sold',
        salesStatistics:'Sale statistics',
        copy:'Copy',
        copied:'Copied',
        infoZoneAffectedByRestock:'By default, freebird use all storage areas if no configuration is existing',
        zoneAffectedByARestock:'Zone concerned by an internal restock',
        triggerThresholdInternalRestock:'Trigger threshold of an internal restock',
        quantityToRestock:'Optimal stock',
        internalRestockException:'Internal restock exception',
        noZoneOfStock:'No zone of stock',
        rppslMenuName:'RP/PSL',
        emptyValue:'Empty value',
        reopenQuotation:'Reopen quotation',
        devisValidite:'Quotation validity period',
        quotationGenerated:'Your quotation has been generated',
        continueWithoutIdentification:'Continue without identification',
        expired:'Expired',
        notAvailable:'Not available',
        infosOnReceipt:'Infos to display on the receipt',
        infosOnTopReceipt:'Infos to display on the top of the receipt',
        infosOnBottomReceipt:'Text to display on the bottom of the receipt',
        listedUserWillBeNotified:'The listed users will be notified',
        cancellationOfInvoice:'Cancellation of invoice n°',
        cancelledByCreditNote:'Cancelled by the credit note n°',
        assignCountZeroEmptyBins:'Assign a count of zero to all remaining bins',
        confirmAssignCountZeroEmptyBins:'Do you really want to assign a count of zero to all remaining bins',
        confirmDeleteTemplate:'Do you really want to delete this template?',
        assignPointsManually:'Assign points manually',
        unauthorizedMovement:'Stock transfer to this zone is not allowed',
        nbPointsToAssign:'Number of points to assign',
        reminder:'Reminder',
        reminders:'Reminders',
        a:'To',
        list:'List',
        title:'Title',
        addSettle:'Settle the document',
        addLimitDate:'Add a limit date of payment',
        addLimitDateValidity:'Add a limit date of validity',
        addFund:'Add funds on the gift card',
        addUsers:'Add users',
        addItems:'Add items',
        addingItemsToPromo:"Adding items to the promotion",
        addTopText:'Add text on top part',
        addBottomText:'Add text on top part',
        specifyASeller:'Specify a seller',
        message:'Message',
        retraitZone:'Withdrawal zone',
        transferAllProducts:'Transfer all the products',
        toMomentarilyFiniched:'The collection of this transfer order is temporarily completed',
        toFiniched:'The collection of this transfer order is completed',
        collectOrderFinish:'The collection of items for this order is completed',
        toReceiptMomentarilyFiniched:'The receipt of this transfer order is temporarily completed',
        toReceiptFiniched:'The receipt of this transfer order is completed',
        toItemCollectMessage:'This item is either out of stock or has been fully collected',
        assignedTo:'Assigned to',
        finalize:'Finalize',
        finalized:'Finalized',
        getToItemsSelection:'Get to items selection',
        selectCustomer:'Select a customer',
        orderedInThisShop:'Ordered in this shop',
        toBeCollectedInThisShop:'To be collected in this shop',
        informPaymentLate:'Inform customer on the consequences of late payment',
        toCollect:'To collect',
        toCollectWms:'To collect from your WMS',
        enCoursTo:'Subject to a transfer order',
        necessaryActionOrder:'A manual action is necessary to complete the collection of this item',
        createATransferOrder:'Create a transfer order',
        generateAnTransferOrder:'Generate an transfer order',
        dontIndicate:'Don\'t indicate',
        itemManagement:'Items management',
        autoCreationItemInfos:'Automatic creation of classifications, families, subfamilies, categories, subcategories, brands and suppliers',
        skuObligation:'Obligation to ceate a SKU',
        barCodeObligation:'Obligation to create a barcode',
        temporary:'Temporary',
        ecomTemporaryMsg:'This item cannot be uploaded because it is currently provisional',
        showOnlyTemporaryItem:'Display only the temporary items',
        temporaryItem:'Temporary item',
        addThisItemToItemBase:'Add permanently this item to the item base',
        other:'Other',
        hour:'Hour',
        ordered:'Ordered',
        errorDifferenceQteValidate:'There is an existing difference of value on the following items',
        paiementFideliteButNoFideliteArticle:"Loyalty payment is not accepted in this cart",
        closeWithDifference:'Your checkout account does not appear to be correct. Do you still want to close as is ?',
        onReceipt:'On receipt',
        articleNoReferenceFind: 'No item corresponds to this reference',
        mustIndicateReasonRetrait: 'You must indicate the reason for withdrawing cash',
        gradeHasUser: 'You can\'t delete this grade because some staff have this grade. To delete this grade you must before remove this grade for all staff who have it .',
        modepaiementRemove: 'You cannot delete this payment method because it is assigned to some of your shops. If you really want to delete it, you must first remove it from shops for which this payment method is assigned.',
        adminOverQuota: 'You cannot add the "Configuration" module to this user because your users quota for this module has already been reached. Please contact Ciweb if you wish to modify this quota.',
        erpOverQuota: 'You cannot add the "ERP" module to this user because your users quota for this module has already been reached. Please contact Ciweb if you wish to modify this quota.',
        usersOverQuota: 'You cannot add the "Users" module to this user because your users quota for this module has already been reached. Please contact Ciweb if you wish to modify this quota.',
        wmsOverQuota: 'You cannot add the "WMS" module to this user because your users quota for this module has already been reached. Please contact Ciweb if you wish to modify this quota.',
        posOverQuota: 'You cannot add the "POS" module to this user because your users quota for this module has already been reached. Please contact Ciweb if you wish to modify this quota.',
        endUnderrBegin: 'The period end date cannot be lower than the period start date',
        perdioExceedAYear: 'The period cannot be longer than one year',
        dayDateFormat: 'The date of this CSV row is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        commandeDateFormat: 'The date of order is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        expectedDateFormat: 'The date of transfer is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        expeditionDateFormat: 'The expected delivery date is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        realExpeditionDateFormat: 'The real delivery date is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        receptionDateFormat: 'The expected receipt date is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        beginDateFormat: 'The start date is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        endDateFormat: 'The end date is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        dateDepartDateFormat: 'The departure date is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        dateArriveDateFormat: 'The arrival date is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        dateFormat: 'The date is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        beginRequiredWithEnd: 'You must indicate a start date since you indicated an begin date',
        endRequiredWithBegin: 'You must indicate an end date since you indicated a start date',
        sizeLineCsv: 'This row of the CSV file doesn\'t contain the correct numbers of column',
        row: 'Row',
        lines: 'Lines',
        noRhOrNoAuth: 'Non-existent or unauthorized staff to access this section',
        staffNotAttributeToInventory:'Staff not attributed to this inventory',
        noRhOrNoAttributed: 'Non-existent staff or not attributed to this inventory',
        noRhTos: 'You don\'t have pending transfers orders',
        noInternetTos: 'You don\'t have pending internet orders',
        imageUrl: 'The image link must be a valid URL',
        havestockIn: 'You must indicate if this item must be dependent on a stock',
        zoneAlreadyHaveCommercial: 'This shop already has a stock zone with the type selling area',
        zoneAlreadyHaveCommande: 'This shop already has a stock zone with the type order area',
        archiveZoneAddArticle: 'This stock zone has some items and therefore can\'t be archived',
        deleteZoneAddArticle: 'This stock zone has some items and therefore can\'t be deleted',
        customFieldsAlreadyExists: 'A custom field with the same name already exist',
        youMustIndicateField: 'You must fill the field',
        ecomMustBeConfigured:'This e-commerce is not configured',
        limitCsv: 'The CSV file must be less than {limit} lines',
        filename: 'File name',
        name:'Name',
        description:'Description',
        categorization:'Categorization',
        ptype:'Product type',
        pcat:'Product category',
        image:'Image',
        couleur:'Color',
        taille:'Size',
        notANameForCustomFields: 'This name cannot be given to a custom field',
        quotaCommandeInteger: 'The quantity must be an integer',
        quotaInteger: 'The quantity must be an integer',
        selectBinsOrZone: 'You must scan a bins or select a zone',
        shipmentLineAlreadyReceptione:'This block has already been validated',
        changHaveBinsMustEqualZero:'You cannot change the bins option for an area that already contains items. This area currently contains: {quota} items',
        toIsClosed:'This Transfer Order is closed',
        rhNotInSection:'This staff doesn\'t exist or doesn\'t work in this origin shop',
        communToLineNotTheSame:'Some data that must be common to all lines of the same transfer order is not',
        noDoubleArticleTo:'This item is already present in another line of this transfer order',
        noAutorizedUpdateTo:'You cannot update a transfer order that has passed the collection stage',
        cantDeleteLine: 'You cannot delete this line',
        pslGte: 'The preferred stock level can\'t be less than the redorder point',
        noDockForReassort: 'You have no warehouse defined',
        noArticleForReassort: 'There are no items in your selection',
        noToMaxForReassort: 'You must first set the maximum number of instructions a transfer order can contain in your company settings',
        valuePromotionNotOk: 'The promotions values are incorrect',
        amountOverDue:'Payed amount can\'t be over than due amount',
        resteIsOverTicket: 'The amount paid is higher than the remaining amount of the ticket',
        majorPaiementError: 'Major paiement error, the customer has paid more than the asked price',
        paiementIsNegative: 'The amount payable cannot be negative',
        paiementIsPositive: 'The outstanding amount must be 0',
        noCommercialZone:'No commercial zone is define for this shop',
        noGiftCardFound: 'No gift card matches this reference',
        noFactureFound: 'No document found',
        noTicketFound: 'No ticket matches this reference',
        noCommandeFound: 'No sales order matches this reference',
        noPossibilityToReturnArticle:'An error prevents the return of the item',
        articleAlreadyReturn: 'This item has been already return',
        hostIp:'This is not a valid IP adress',
        optional:'Optional',
        orderTitle:'Order',
        sectionDontHaveCommandeZone: 'The sections affected by this command do not have a command zone',
        commandeAlreayCancel: 'This sales order has already been cancelled',
        mustSpecifyRemboursement: 'You must specify the method of refund',
        mustSpecifyGiftCardReference: 'You must specify the gift card bar code',
        mustChooseOneRaccourci: 'You must choose a shortcut',
        someItemsNotPresentInPo: 'Some items are not present in the associate purchase order',
        poLineAlreadyReceptione:'This bloc has already been received',
        ZoneWithBins:'No bins has been provided for this zone',
        someItemsNotPresentInPo:'Some of the received items are not part of the purchase order',
        unauthorizedDivision:'The line you want to duplicate cannot be split by the remainder',
        thisItemsNotPresentInPo: 'This item is not present in the associate purchase order ({name})',
        lineCannotBeDelated: 'This line cannot be delated',
        thisArticleNotLinkToStock: 'This item didn\'t have stock',
        articleIsNotInPo:'This item doesn\'t appear in this purchase order',
        articleIsNotInTo:'This item doesn\'t appear in this transfert order',
        articleIsNotInRestock:'This item doesn\'t appear in this internal restock',
        toQuantityCantBeEdit: 'The status of this transfer order does not allow you to edit the quantities to be collected',
        tooManyOrTooLongTags:'There is too many tags (250 max) or one of them is too long (255 chars max)',
        ecommerceSendError:'The E-shop send the error {erreur}',
        variantAreTheSame:'Child items are not itemized correctly and are therefore interpreted as the same items by Shopify. Check the options you specified.',
        noUserToSendAlert:'No users are configured to receive this type of alert',
        noUser:'No user has been assigned',
        indicateBinsInZoneThatHaveNoBins: 'You specified a bins for a location that does not contain one',
        binsOrZone:'You must specified a bins or a zone',
        reassortInterneAlreadyOpen:'You already have an open reassort. Please close it before generate a new one',
        negativeShopStockAndNoAutoPossibilities:'The following items have negative stock in your trade area. You must first readjust their stock before you can generate a restock',
        reassortCantDeleteAlreadyMove:'You can\'t delete a line that have already being collected',
        clientNotMatchWithDossier:'This customer didn\'t match with this case',
        /*************Modules name**************/
        moduleadmin:'Configuration',
        moduleerp:'ERP',
        modulewms:'WMS',
        modulepos:'POS',
        moduleusers:'Users',
        moduleinvoice:'Invoice',
        modulerhtime:'Working time management',
        /*********divers***********/
        addDiscountSelection:'Add discount on selection', 
        addDiscountAll:'Add idscount on all items',
        documentCantBeDeleteBecauseLinesAlreadyProcessed:'Le document ne peut pas être annulé car certaines lignes de ce document ont déjà été traitées',
        convert:'Convert',
        chat:'Message',
        thisLine:'This line',
        manualCounting:'Manual counting',
        cetteLigne:'this line',
        emisLe:'Issued on',
        acquittee:'Payed',
        cancelInvoice:'Cancel this invoice',
        acquitterFacture:'Pay this invoice',
        generateNewFrom:'Generate a new document from this one',
        sendDocEmail:'Send this document by e-mail',
        defaultMail:'Hello,<br /><br />Click the button just under to donwload the [type]<br /><br />[bouton]<br /><br />Regards,<br />',
        saveDocTemplate:'Save this document as a template',
        createReminderFromDoc:'Create a reminder for this document',
        editReminderDoc:'Edit the reminder for this document',
        convertToInvoice:'Convert to invoice',
        notConvertToInvoice:'Not convert to invoice',
        allLocalisations:'All localisations',
        filterTheSelection:'Filter the selection',
        filterBysection:'Filter by shop',
        supplierOrder:'Supplier order',
        yes: 'Yes',
        no: 'No',
        signIn: 'Sign In',
        password: 'Password',
        newPassword: 'New password',
        signOut: 'Sign Out',
        administration: 'Configuration',
        users: 'Licences',
        gestionStockCommandes: 'ERP',
        shops: 'Shops',
        shop: 'Shop',
        delete: 'Delete',
        remove: 'Remove',
        update: 'Update',
        upload:'Upload',
        online:'Online',
        optional:'Optional',
        offline:'Offline',
        calculationMethod:'Calculation method',
        activeOnPromotion:'Active on promotions',
        forAmountOf:'For an amount of',
        perSlice:'Per slice',
        inProportion:'In proportion',
        activateOnPromotion:'Activate on promotions',
        totalCumulLoyaltyPoint:'Total cumulative loyalty points',
        fusionClientInfo:'The selected customer will disappear from your customer database, and all of his informations will be merged with {clientName}',
        earnedLoyaltyPoint:'Earned loyalty points',
        loyaltyPoints:'Loyalty points',
        confirmDeleteAllPage:'Do you really want to delete all the lines on the page',
        confirmDeleteUser: 'Are you sure you want to delete the user',
        confirmDeleteGrade: 'Are you sure you want to delete the grade',
        typeDeleteX: 'Are you sure you want to delete ',
        typeDeleteReminderX: 'Are you sure you want to delete the reminder for ',
        confirmDeleteCheckDeposit: 'Are you sure you want to delete this check deposit ?',
        typeRemoveX: 'Are you sure you want to remove ',
        confirmArchiveX: 'Are you sure you want to archive ',
        confirmActiveX: 'Are you sure you want to active ',
        confirmDeleteGoals: 'Are you sure you want to delete the goals of this perdiod: ',
        confirmRemoveRh: 'Are you sure you want to remove',
        confirmOpenFonCaisse: 'Are you sure you want to open this checkout with ',
        confirmAddClientForm: 'Are you sure you want to add a client form',
        confirmRemoveClientForm: 'Are you sure you want to remove the client form',
        selectLoyaltyRewardMode:'Select the loyalty reward mode',
        noRewardModeSelected:'No loyalty reward mode selected',
        loyaltyRewardConfig:'Loyalty rewards configuration',
        cashback:'Cashback',
        availableCashBack:'Available cashback',
        toApplyTo:'To apply to',
        recompense:'Free item',
        ofFondCaisse: 'of cash fund',
        confirmArchiveX: 'Are you sure you want to archive ',
        confirmUnarchiveX: 'Are you sure you want to active ',
        confirmClockingX: 'Are you sure you want to clock in ',
        confirmValidateLine:'Are you sure you want to validate this block',
        confirmValidateAll:'Are you sure you want to validate all',
        confirmCancelSaleOrder:'Are you sure you want to cancel this sale order',
        warningToCancelSaleOrder:'Transfer orders are associated with this sale order, it is strongly recommended to cancel them',
        confirmClosefondCaisse:'Are you sure you want to close checkout with ',
        confirmDifferenceFondCaisse:'Are you sure you want to open this checkout with a difference of ',
        confirmValidateInventory:'Are you sure you want to validate this inventory, this action will be irreversible',
        clotureDetailPdf:'Checkout closing detail',
        returnToItemReceipt:'Return to items receipt',
        associateToAnItem:'Replace by an existing item',
        addPermanentlyMsg:'Attention, this action is irreversible',
        de: 'From',
        vers: 'To',
        exerciseStart:'Exercise start',
        ecart:'Difference',
        irreversibleAction: 'Warning: this action will be irreversible',
        company: 'Company',
        canceled: 'Canceled',
        noLogoActualy: 'No logo currently',
        man: 'Man',
        woman: 'Women',
        rh: 'Staff',
        modePaiement: 'Payment method',
        loyaltyMode:'Loyalty mode',
        general: 'General',
        genere:'Generate',
        proposal:'Propo',
        newTotalValueStock:'Total value of the new stock',
        oldTotalValueStock:'Total value of the old stock',
        totalValueSaleOrder:'Total value of sales order',
        totalPaiementSaleOrder:'Total value of deposits paid',
        tarifSellerModification: 'Manual modification of a price',
        width:'Width',
        height:'Height',
        openFondCaisse: 'Checkout opening - Cash fund',
        fond_caisse:'Cash fund',
        closeFondCaisse: 'Closing Checkout',
        closeCaisse:'Checkout closing',
        repartGoals: 'Goals distribution',
        openCaisse: 'Open checkout',
        openTiroir:'Open cash drawer',
        loyalty:'Loyalty',
        customerLoyalty:'Customer loyalty',
        loyaltyPlan:'Loyalty plan',
        accumulatedPoints:'Accumulated points',
        spentPoints:'Spent points',
        open:'Open',
        opened:'Open',
        closeWith:'Close with',
        a: 'at',
        allShops:'All shops',
        allUsers:'All users',
        searchArticle: 'Search item...',
        searchInWait: 'Searching',
        cashier: 'Cashier',
        inventory:'Inventory',
        clickOnZoneWithoutBins:'Click on a zone without bins',
        mustCompleteOrCancelReceipt:'You must complete or cancel your current receipt',
        inventaireZone:'Inventory type zone',
        inventaireBins:'Inventory type bins',
        inventaireCategorie:'Inventory type category',
        finalizedInventory:'Finalized inventory',
        inventoryType:'Inventory type',
        inventaireImport:'Inventory importation',
        loyaltyPlanImport:'Loyalty importation',
        ajustImport:'Stock ajustement imported',
        returnInventory:'Return to inventory',
        returnInventoryHome:'Return to inventory homepage',
        startCounting:'Start counting',
        seeInventoryReport:'See the inventory report',
        validateInventory:'Validate inventory',
        addSelection:'Add the selection',
        validateCount:'Validate count',
        addToInventoryList:'Add to the inventory list',
        cancelCount:'Cancel count',
        cancelAllCount:'Cancel all counts',
        cancelComparisonwithStok:'Cancel the comparison with the stock',
        allInventories:'All inventories',
        readInventoryDetail:'Read inventory detail',
        compareInventoryToStock:'Compare inventory to stock',
        inventoryComparison:'Inventory comparison',
        allCounts:'All counts',
        selectCancellationOption:'Select the cancellation option',
        countNotFinished:'Count not finished',
        wrongCount:'Wrong count',
        inventoryBinsMsg:'The system will automatically create different inventory if the number of bins in this zone is too important',
        confirmSecondCount:'You have already done this count, do you really want to do a new one',
        zoneName:'Zone name',
        customerPlacehlder: 'Customer...',
        noRhForSection: 'No staff have been allocated for this point of sale',
        consultPriceItem: 'See price item...',
        seeSaleOrder:'See the sale order',
        selectColumnFileToMatch:'Select the column from the file to match',
        selectColumnBaseToMatch:'Select the column from the item base to match',
        stockArea: 'Stock Area',
        createStockArea: 'Create storage Area',
        creerGiftCard:'Create a gift card',
        stockHaveBins: 'This stock area must Have Bins',
        stockZoneEmpty:'This stock area is empty',
        errorEcomOrderTraitement:'Error on a sale order',
        newEcomOrder:'New sale order',
        itemReference: 'Item Reference...',
        cashOut: 'Cash withdrawal',
        consulter: 'Consult',
        attribuer: 'Attribute',
        attribuerA: 'Attribute to',
        synchronized:'Synchronised',
        notSynchronized:'Not synchronised',
        itemMustHaveParentToBeOnline:'This item must have a parent name to be online',
        attribuerSelection: 'Attribute the selection',
        generateReassort: 'Generate a proposal restock',
        reassortGenerate:'Generate a restock',
        addToFavorite:'Add to favorites',
        editFavorite:'Edit favorite',
        listOfFavorite:'List of favorites',
        favoriteName:'Favorite name',
        generateReadjustment:'Generate a readjustment',
        forDepotBanque: 'This withdrawal is for a bank deposit',
        retraitForOtherReason: 'This withdrawal is for an other reason',
        retraitOtherLabel: 'Indicate the reason you are withdrawing cash',
        confirmRetraitOtherReason: 'Are you sure you want to withdraw cash in the amount of',
        confirmRetraitBanqueReason:'Are you sure you want to deposit an amount of',
        confirmDeleteCustomerAssociate: 'Are you sure you want to delete this customer association',
        confirmDeleteDivers: 'Are you sur you want to delete',
        confirmDelete: 'Confirm deletion',
        confirmGenerateTo:"Are you sure you want to generate a transfer order from this shipping order",
        successOperation: 'Operation performed successfully',
        successInHistorique: 'Import launched successfully. You can follow the import status in your import history.',
        ruleUpDateAlreadyAssigned:'This field has already been assigned to a update rule',
        addClient: 'Add Customer',
        editColumnPdf: 'Edit columns',
        editRecapPdf: 'Edit lines',
        addLogo: 'Add a logo',
        cattc:'Turnover taxes included',
        caht:'Turnover taxes excluded',
        goalsttc:'Goals taxes included',
        taxesExcluded:'Taxes excluded',
        taxesIncluded:'Taxes included',
        totalTTC:'Total Taxes included',
        goalsht:'Goals taxes excluded',
        grossMargin:'Gross margin',
        grossMarginPercent:'Gross margin in %',
        beginIsGreaterThanEnd:'The beginning  of the period cannot  be greater than the end of the period',
        periodIsGreaterThanAYear:'The selected period must be less than one year',
        importGoals: 'Import goals',
        createGrade: 'Create Grade',
        createModePaiement: 'Add a payment method',
        createCashOutReason: 'Add proof of cash withdrawal at the POS',
        cashOutMore: 'Your proof of cash withdrawal at the cash POS',
        modePaiementForced: 'Basic payment methods',
        modePaiementMore: 'Your added payment methods',
        items: 'Items',
        today: 'Today',
        hier: 'Yesterday',
        demain: 'Tomorrow',
        noObjectif: 'There is no POS goals for this period.',
        objectifs: 'Goals',
        realise: 'Achieved',
        nbrEntreClient: 'Number of customers passages (entry and exit)',
        edit: 'Edit',
        period: 'Period',
        oneDay: 'One day',
        manyDays: 'Many days',
        searchBy:'Rechercher par',
        day: 'Day',
        de: 'From',
        toA: 'To',
        seeAll:'See all',
        seeMore:'See more',
        createNotification:'Create a notification',
        disponible: 'Avaible',
        addCashOut: 'CashOut',
        noEnougthForReassort: '{name} does not have enough stock to execute this change',
        confirmAddItemPromo: 'Confirm the selection of {quantity} items',
        needEntre: 'This chart will be avaible when the number of customer passages will be filled in',
        createGoals: 'Create Shop Goals',
        attributeStaffGoals: 'Attribute Goals to Staff',
        deleteGoals: 'Delete Goals',
        createOneDayGoals: 'Create goal of one day',
        report:'Reporting',
        datas:'Datas',
        dropCsv: 'Drop CSV file here or click to upload.',
        noDataInCsv: 'No data found in this csv file',
        fileCsvToLarge: 'The file exceeds the maximum allowed size of 7 megabytes',
        objectifCsvFileToLong: 'The csv file must have 367 line maximum',
        doublonCsv: 'Some columns of the csv file are used on multiple data',
        indicateRecurrence:'Indicate a recurrence',
        indicateRecurrenceMsg:'Indicate the day of the month for which you want this recurrence to apply for the template {modeleName}',
        deleteRecurrence:'Delete the recurrence',
        exonerateFromTaxes:'Exonerate from taxes',
        every:'Every',
        ofMonth:'th of the month',
        template: 'Template',
        recurrence:'Recurrence',
        reprogramRecurrenceOnNextMonth:'Reprogram the recurrence for the next month',
        templateConfiguration: 'Template configuration',
        templateEmailConfiguration: 'Email template configuration',
        emailBodyQuotation:'Default body of a quotation email',
        emailBodyInvoice:'Default body of a invoice email',
        emailBodyCreditNote:'Default body of a credit note email',
        msgInfoContenuMail:'The elements noted within brackets [] will be retrieved from the information in the document. Here is the complete list of elements that you can write within brackets:',
        msgInfoContenuMailClient:'will be replaced by the customer name',
        msgInfoContenuMailNumero:'will be replaced by document number',
        msgInfoContenuMailMontant:'will be replaced by the total amount with taxes included of the document',
        msgInfoContenuClient:'[customer]',
        msgInfoContenuNumero:'[number]',
        msgInfoContenuMontant:'[amount]',
        msgEmailConfigure:'When you create an invoice, quotation or a credit note, you will be able to send this document by e-mail. Here you can configure the body of this e-mail.',
        seeMyTemplate:'see the template',
        importedRows: 'Imported rows: ',
        infoModePaiement: 'This is to list the possible payment methods for your shop. Please note: each of your shops can use different payment methods. Therefore, you must go to the options of each of your shops to indicate the payment methods that are accepted there.',
        restrictionStaff: 'Staff Restrictions',
        reassortWait:'Restock proposal is being calculated. This task may take a few minutes...',
        inventoryCompareWait:'The comparison of the inventory with the stock is being calculated. This task may take a few minutes...',
        inventoryValidateWait:'The inventory is being validated. This task may take a few minutes...',
        waitReassortTo:'Transfer order(s) being created. You will be redirected soon. This task may take a few minutes...',
        authorized: 'Authorized',
        noRestriction: 'No restriction',
        scanBadge: 'Scan your badge here...',
        scanSellerBadge: 'Scan your seller badge here...',
        scanBadgeOrSearch:'Scan your badge here or search a staff',
        formclient: 'Customer form',
        explicationFormClient: 'Indicate the fields you want to appear in the customer creation form',
        noFilter:'No filter',
        field:'Field',
        obligatoire:'Required',
        requiredField: 'Required Field',
        notRequiredField: 'Not Required Field',
        uniqueField: 'Signle value',
        notUniqueField: 'Not Single Value',
        freeField: 'Free value',
        fieldListData: 'Submitted to a data list',
        civilite: 'Civility',
        activer: 'Activate',
        adressePhysique: 'Physical Adress',
        adressePostale: 'Postal Adress',
        communeVille:'City/Town',
        inscriptionNewsletter: 'Newsletter Subscription',
        noFormClient: 'The customer form for your company is not configured',
        customerReward:'Customer rewards',
        homme: 'Man',
        femme: 'Woman',
        noClientToEdit: 'You must first indicate a customer',
        noCashierIndicated : 'You must indicate a cashier',
        periodHigherOneMonth:'The selected period must be less than 1 month',
        newItem: 'New item',
        stamp:'Stamp',
        parameterName: 'Avanced parameters',
        otherCompany: 'Other companies',
        clientType: 'Client type',
        quartier: 'Area',
        dateDeNaissance: 'Date of birth',
        selectAType: 'Please select a type',
        customField: 'Custom field',
        customFields: 'Custom fields',
        category: 'Categories',
        providerName: 'Provider name',
        contactName: 'Contact Name',
        categoryName: 'Category name',
        companyName: 'Company name',
        legalForm:'Legal form',
        documentType:'Document type',
        draftListMsgInfo:'Only drafts less than 48 hours are saved',
        fieldName: 'Field name',
        defaultFields: 'Default fields',
        items: 'Items',
        providers: 'Providers',
        nouveauFournisseur: 'New provider',
        editFournisseur: 'Edit provider',
        CDD: 'CDD',
        CDIE: 'CDI - Trial period',
        CDI: 'CDI',
        archive: 'Archive',
        thisStaffIsArchive: 'Please note: this staff is archived',
        includeArchiveStaff: 'Include archived staff',
        includeArchiveProvider: 'Include archived providers',
        includeArchiveData: 'Include archived datas',
        includeArchivetem: 'Include archived items',
        clientAssocie: 'Associated customer',
        clientArchiveAccocie: 'This Staff is archived and therefore cannot be associated with a customer',
        clientArchiveBadge: 'This Staff is archived and therefore this is not possible to generate a badge',
        deleteCheckDeposit:'Delete check deposit',
        archive: 'Archive',
        downloadCSV: 'Export csv file',
        downloadZone:'Export zone content',
        downloadBins:'Export bins content',
        downloadQuickCSVSelection:'Export a quick version of the selected lines in csv',
        downloadCSVSelection: 'Export selected column in csv',
        downloadCSVPeriod: 'Export selected period in csv',
        downloadCSVThisSelection: 'Export this selection in csv',
        downloadCSVThisSalesSelection: 'Export the sales of this selection in csv',
        downloadCSVThisTicketsSelection: 'Export the tickets of this selection in csv',
        downloadCSVThisDocumentsSelection: 'Export the documents of this selection in csv',
        downloadCSVThisPaiementSelection: 'Export the paiements method of this selection in csv',
        downloadCSVAll: 'Export all coloumn in csv',
        downloadAllPoCsv:'Export all purchase order in csv',
        downloadAllToCsv:'Export all transfer order in csv',
        downloadAllToWithoutDetailsCsv:'Export only selected rows from the current table',
        downloadAllShipmentCsv:'Export all shipment in csv',
        downloadAllGiftCardCsv:'Export all gift cards in csv',
        downloadSelectGiftCardCsv:'Export just the selection in csv',
        downloadStockGap:'Export stocks gap',
        downloadExcel: 'Export excel file',
        downloadArticlesCSV: 'Export items in csv',
        listDonnéesCsv: 'Export the possibles datas list',
        firstlevelcat:'Classification',
        secondlevelcat: 'Family',
        thirdlevelcat: 'Subfamily',
        fourthlevelcat:'Category',
        fifthlevelcat: 'Subcategory',
        imageLink: 'Item image link',
        categorysingular: 'Category',
        addone: 'Add a',
        addOption:'Add an option',
        tagsMsg:'You can combine multiple tags by separating them with a comma',
        emailsCumul:'You can combine multiple emails by separating them with a comma',
        tagsMax:'You can combine up to 250 tags',
        staffIsAssociate: 'This Staff is associate to customer',
        staffIsNotAssociate: 'This Staff is not associate to any customer',
        newbrand: 'New brand',
        includeArchiveBrands: 'Include archived brands',
        formBrand: 'Brand form',
        archivedBrand: 'This brand is archived',
        archivedProvider:'This provider is archived',
        associationStaffCustomer: 'Association of a staff and a customer',
        transferOrderPriority:'Priority of transfer order collection',
        transferOrderAssociation:'Transfer orders association',
        saleOrderAssociation:'Sale order association',
        noAssociateCustomerResult: 'There is no association possibilities found',
        impossibleDeletingFamily: 'Deleting is impossible !',
        familyContainItems: 'This category contains items',
        impossibleArchivingFamily: 'Archiving is impossible !',
        formItem: 'Item form',
        internalId: 'Internal ID',
        expectedInternalId: 'Expected internal ID',
        fournisseur: 'Provider',
        notConnectedToFreebird:'Not connected to Freebird',
        otherItemWithSameRefName:'Other items with the same reference name',
        marque: 'Brand',
        reference: 'UPC code',
        reference2: 'UPC code 2',
        arrowUp: 'Arrow Up',
        arrowDown: 'Arow Down',
        ecoTaxe: 'Eco-participation',
        barcode: 'Barcode',
        barcodePackaginglist: 'Packaging barcode',
        shopCode:'Shop code',
        itemName: 'Item name',
        priceTtc: 'Price with duty included',
        priceHt: 'Price without duty',
        priceHtWithoutDiscount: 'Price without duty and without discount',
        marge: 'Margin',
        margeNet: 'Net Margin',
        totalMargeNet: 'Total Net Margin',
        differenceMargeNet: 'Difference Net Margin',
        totalBuyPrice:'Total buy price in currency',
        margePercent: 'Margin %',
        discountPrice: 'Discount price',
        discountProfil: 'Discount profil',
        unitDiscount:'Unit discount',
        discountType:'Discount type',
        buy_price: 'Buying price in currency',
        devise_achat: 'Buying currency',
        stores: 'Stores',
        tgc: 'TGC Rate',
        downloadBadge: 'Download badge',
        generateBadge: 'Generate badge',
        thisItemMustHaveStock: 'This item will be dependent on a stock',
        thisItemIsArchived:'This item is archived',
        itemHaveStock: 'Stock dependent',
        customizationBadge: 'Badge customization',
        addIdPicture: 'Add a picture on the badge',
        addGrade: 'Add the grade',
        addShopName: 'Add the entity name',
        addLastname: 'Add the lastname',
        addShopLogo:'Add shop logo',
        chooseYouCompany: 'Select the entity',
        chooseYourBgColor: 'Choose the background color',
        chooseYourTextEntityColor: 'Choose the entity text color',
        chooseRefundMethod:'Choose the refund method',
        enterYourCustomColor: 'Add a background  color in hexadecimal',
        addCustomColor: 'Add a custom background  color',
        fieldToUpdate:'Field to update',
        fieldAutoUpdateItemRule:'All items with this field filled in the same way will be automatically updated',
        noUpdateRule:'No update rule',
        noEntity: 'No entity',
        red: 'Red',
        blue: 'Blue',
        yellow: 'Yellow',
        pink: 'Pink',
        green: 'Green',
        black: 'Black',
        white: 'White',
        pr:'Cost price',
        prmp:'Average price',
        beneficiary: 'Beneficiary',
        selectABeneficiary: 'Select a beneficiary',
        muststock: 'Item Has Stock',
        mobil: 'Transit area',
        collect:'Collect',
        receive:'Receive',
        chosseMobile: 'Choose the delivery vehicle',
        choseReceptionZone:'Choose the reception zone',
        receiptPoDefaultZone:'Default purchase order receipt zone',
        receiptToDefaultZone:'Default transfer order receipt zone',
        collectToDefaultZone:'Default transfer order collect zone',
        receiptAndCollectDefaultZone: 'Default receipt and collect zone',
        choseAReceptionZoneToReceive:'Choose a zone to receive the transfer order',
        choseAReceptionZoneToCollect:'Choose a zone to collect the transfer order',
        choseAReceptionZoneToReceivePo:'Choose a zone to receive the purchase order',
        unauthorizedActionOnZoneBins:'The selected zone contains bins, please use your WMS for the reception of this transfer order',
        duplicatePoReliquat:'Duplicate the line based on the remainder',
        duplicatePoReliquatConfirm:'Are you sure you want to duplicate the line based on the remainder',
        reception: 'Receiving area',
        commerciale: 'Selling area',
        prive: 'No accessible selling area and stock mouvments',
        unpaidInvoice:'Unpaid invoices',
        stockage: 'Traditional storage area',
        commande: 'B2C Order area',
        commandeB2b: 'B2B Order area',
        zoneArchived: 'Please note : this area is archived',
        importHistory: 'Imports',
        importReport: 'Import report',
        articleImport: 'Item import',
        poImport: 'PO import',
        toImport: 'TO import',
        shipmentImport: 'Shipment Voucher Import',
        rppslImport: 'RP/PSL Import',
        enCours: 'In progress',
        open:'Opened',
        close:'Closed',
        on:'On',
        dataListpossible: 'List of possible data',
        editPersonalField: 'Edit custom field',
        customFieldCreation:'Custom field creation',
        updateDatasRule:'Data update rule',
        editBrand: 'Edit a brand',
        clocking: 'Clocking in',
        errorClocking: 'Badge not assigned this shop or incorrect',
        errorPriorityCollect:'You cannot set the same shop twice',
        errorNoBinsSelected:'No bins has been selected',
        validateProposalReassort: 'Are you sure you want to convert this restocking proposal into a transfer order ?',
        errorClotureSaveTicket:'Please delete all your saved receipts before closing the checkout',
        nonAttribue: 'Not assigned',
        detailHistory: 'History details',
        detailArticle: 'Item details',
        detailPrice: 'Price details',
        detailItem:'Item details',
        stockItem:'Stock item',
        stockItemPos:'Stock item',
        negativeStock:'Negative stock',
        stockParent:'Stock parent',
        colonnes: 'Columns',
        rechercheSurValeurUnique: 'Single value search',
        deleteLineItem:'Delete an item line',
        removeQuantityOnLineItem:'Remove quantity from an item line',
        removeDepositPayment:'Delete payment line',
        filtres: 'Filters',
        prmpIndice: 'Average Cost index',
        prIndice: 'Cost price index',
        parent_name: 'Parent name',
        itemInfos: 'Item informations',
        paymentInfos:'Payment informations',
        limitDatePay:'Limit date of payment',
        limitDateValidity:'Limit date of validity',
        detail:'Detail',
        customer: 'Customer',
        customerProfil:'Customer profile',
        customerInfos: 'Customer informations',
        requiredInformations:'Required informations',
        principalInfo: 'Principal informations',
        secondInfo: 'Secondary informations',
        warningImportInventaire:'Please note, this feature will irreversibly overwrite your previous stock with the new stock indicated in your file, for each of the items found there and in each of the indicated locations. You should NEVER import the inventory of an item for a location, if you have not carried out its complete inventory for the whole of this location.',
        infoImportInventaireAddStock:'Please note, this feature will add to your actual stock the new stock indicated in your file, for each of the items found there and in each of the indicated locations.',
        itemLinkAndNotLinkToTock: 'Stock and no stock items',
        itemLinkToTock: 'Stock items',
        itemNotLinkToTock: 'No stock items',
        itemWithAndWitoutStock:'Items with and without stock',
        itemWithStock:'Items stock',
        itemWWitoutStock:'Items without stock',
        diverseInfo: 'Diverses informations',
        devise: 'Currency',
        inDevise: 'In currency',
        migrate: 'Migrate',
        migrateFrom: 'Migrate from',
        vers: 'to',
        errorMsgMin2:'Your message must be at least 2 characters long',
        errorSelectFamily: 'You must indicate a valid destination of migration',
        aucune: 'No',
        noShops:'No shops',
        noSalesOrder:'No sale order',
        justItemsWhoHaveStock: 'Only items that have stock',
        impossibleExport: 'Unable to export',
        maxLineAuth: 'You can export a maximum of {limit} rows at once. You are currently trying to export some {actual}',
        csvWillBeReady: 'Export request recorded. Your CSV file will be available shortly in your CSV file download manager',
        inventoryUpdateWillBeReady: 'Inventory update in progress, it will take effect within 5 minutes on {ecomName}',
        lastExportCsv: 'Last export of CSV files',
        exportDate: 'Export date',
        exportSelectedColumnToCsv:'Export selected colmumn to csv',
        state:'State',
        itemHasNoStock:'This item has no stock',
        errorExport: 'An error occurred during the export',
        errorImport: 'An error occurred during the import',
        errorNoStaffSelected:'No Staff has been selected',
        attributeSelectionToStaff:'Do you want to attribute the selection now',
        download: 'Download',
        editArticle: 'Item edit',
        editClient:'Customer edit',
        havestockInImport: 'You must indicate if this item must be dependent on a stock',
        itemCard: 'Item card',
        defaultConfig: 'Default configuration',
        personalizedConfig: 'Personalized configuration',
        addNewSection:'Add a new section',
        add: 'Add',
        newApiKey:'New API key',
        apiKey:'API key',
        msgApiKeyExpire:'Your API key will expire in less than 30 days',
        msgNoApiKey:'No API key',
        noUserConf: 'No personalized configuration',
        createApiKey:'Create an API key',
        activeApiKey:'Active API key',
        createNewApiKey:'Create a new API key',
        msgApiKey:'Here your API key, make sure to keep this key because it will not appear again',
        msgRecreateApiKey:'Are you sure you want to regenerate an API key',
        deleteUserConf: 'Delete the personalized configuration',
        persoConfigVide: 'Your personalized configuration is empty',
        defautConfigVide: 'Your default configuration is empty',
        noDefaultConf:'No default configuration',
        noConfAvailable:'No available configuration',
        itemAndZoneDetail:'Items and Zones detail',
        customizeInterface: 'Customize the interface',
        customizationInterface: 'Interface customization',
        importArticleAlreadyInProgress:'An items import is already in progress.',
        purchaseOrder:'Provider\'s purchase order ',
        purchaseOrderB2b:'Purchase order',
        purchaseOrderForm:'Purchase order form',
        orderDate: 'Date of order',
        expectedDeliveryDate:'Expected delivery date',
        requestedDeliveryDate:'Requested delivery date',
        requestedDeliveryDateNoAccent:'Requested delivery date',
        realDeliveryDate:'Real delivery date',
        estimateDeliveryDate:'Expected delivery date',
        expectedReceiptDate:'Expected date of receipt at the warehouse',
        expectedReceiptDateInShop:'Expected date of receipt at the shop',
        estimatedDelivery:'Expected delivery',
        lastSalesOrder:'Last sale order',
        lastQuotation:'Last quotation',
        searchSalesOrder:'Search a sale order',
        searchBins:'Search a bins',
        quantity:'Quantity',
        leave:'Leave',
        leaveConversation:'Leave the conversation',
        leaveNotifConfirm:'Are you sure you want to withdraw from this conversation',
        quantityTotal:'Total quantity',
        quantityDisp:'Quantity on hand',
        quantityBloque:'Blocked quantity',
        quantiteOnPurchase: 'Ordered quantity',
        quantiteWait: 'Pending quantity',
        purchaseOrders:'Purchase orders',
        createPurchaseOrder:'Create a purchase order',
        purchaseOrderName:'Purchase order name',
        createdAt:'Date of creation',
        lastUpdate:'Last update',
        divers:'Other',
        createBy: 'Create by',
        notDelivered: 'Not received',
        notDeliveredLines: 'lines not received',
        poCompletelyReceived:'Purchase order completely received',
        noMoreItemToReceive:'There are no more items to receive in this purchase order',
        completed: 'Completed',
        notCompleted: 'Not Completed',
        includeAllPurchaseOrders: 'Include completed purchase orders',
        editPurchaseOrder: 'Edit purchase order',
        importPo: 'Import Purchase Orders Contents',
        importShipment: 'Import Shipment Voucher Contents',
        importReassort: 'Import Automatic Restock Contents',
        importTo: 'Import Transfers Orders Contents',
        refer:'Reference name',
        billingRef:'Invoice reference',
        quota_recu: 'Received quantity',
        confirmLogout:'Do you really want to change the staff',
        status:'Status',
        statusItems:'Items Status',
        statusLines:'Lines Status',
        regenerate:'Regenerate',
        depotBanqueTitle:'Cash desposit attestation',
        remiseCheckTitle:'Check deposit attestation',
        toAlreadyCompleted: 'This transfer order has already been completed',
        allItems: 'All items',
        itemsTotallyReceived: 'Items totally received',
        itemsPartiallyReceived: 'Items partially received',
        itemsNotReceived: 'Items not received',
        sku:'SKU',
        january:'January',
        february:'February',
        march:'March',
        april:'April',
        may:'May',
        june:'June',
        july:'July',
        august:'August',
        september:'September',
        october:'October',
        november:'November',
        december:'December',
        member:'Members',
        writeNewMessageHere:'Write your message here...',
        relatedRecord: 'Related records',
        relatedItems:'Related items',
        addItemsInfoPromo:'Use the filter to target the items to add to the promotion',
        redirect:'Redirect',
        updatePoRecords: 'Update in',
        insertPoRecords: 'Insert in',
        updateShipmentRecords: 'Update in',
        updateInventory:'Stock update',
        updateTheInventory:'Update the stock',
        insertShipmentRecords: 'Insert in',
        entreStockRecords: 'Stock entry',
        voirLe: 'See',
        saleOrderCanceled:'This sale order has been canceled',
        saleOrderFinish:'This sale order has been completed',
        saleOrderFinishTicket:'This sale order has been completed. Invoice numbre: ',
        itemsDetail:'Items detail',
        registrationDate:'Registration date',
        shipmentVoucher: 'Packing list',
        shipment: 'Packing list',
        transferOrder: 'Transfer Order',
        transferOrders: 'Transfer Orders',
        shipmentVoucherAccent: 'Packing list',
        entre: 'Entry',
        expected:'Expected',
        numero_shipment: 'Packing list number',
        numero_container_shipment: 'Container number',
        numero_container_shipment_accent: 'Container number',
        date_depart_shipment:'Departure date',
        date_depart_shipment_accent:'Departure date',
        date_arrive_shipment:'Arrival date',
        date_alert:'Reminder date',
        date_arrive_shipment_accent:'Arrival date',
        status_shipment:'Status',
        createShipmentVoucher: 'Create a shipment voucher',
        manualEntry:'Manual entry',
        inventoryZone:'Inventory zone',
        inventoryOption:'Inventory option',
        inventoryZoneList:'Inventory zone list',
        countFromInventory:'Inventory count',
        cours_chargement: 'Loading in progress',
        alert_texte:'Reminder text',
        cancelReminder:'Cancel reminder',
        warehouse:'Warehouse',
        mer: 'On the sea',
        douane: 'At the custom office',
        dock: 'Receipt in progress',
        receptione:'Received',
        receipt:'Receipt',
        receptioneLines:'received lines',
        includeAllShipmentVoucher: 'Include all shipment voucher',
        exceptReceivedVoucher: 'Except received voucher',
        depositDate:'Deposit date',
        only:'Only',
        customCreateForm: 'Customize the creation form',
        customVoucherDetails: 'Customize the voucher details',
        PurchaseOrderCard: 'Purchase order card',
        shipmentVoucherCard:'Packing list card',
        shipmentVoucherForm:'Packing list form',
        toCustomForm:'Transfer Order Form',
        shde_carton_name:'Box reference',
        shde_quota_prevu:'Expected quantity in box',
        shde_quota_prevu_accent:'Expected quantity in box',
        shde_quota_recu:'Received quantity',
        shde_carton_recu:'Card received',
        shde_carton_recu_accent:'Card received',
        receiptShipmentVoucher:'Receipt of shipment voucher',
        receiptPo:'Receipt of supplier purchase order',
        scanBoxHere:'Scan a box here',
        scanBoxToCheckHere:'Scan a box here',
        scanItems:'Scan an item here',
        boxeChoice:'Box choice',
        itemChoice:'Item choice',
        drawerName:'Drawer\'s name',
        priorityBox:'Priority box',
        french:'French',
        english:'English',
        po: 'Provider\'s purchase order',
        to: 'Transfer order',
        totalPrmp: 'Average Cost total',
        totalPr: 'Cost pirce total',
        allBoxes: 'All Boxes',
        allSalesOrder:'All sales order',
        allLines: 'All lines',
        allInvoices:'All invoices',
        allCompanies:'All Companies',
        onlyUnpaidInvoices:'Only unpaid invoices',
        boxesStatus: 'Boxes status',
        assignAWms: 'Assign to a WMS',
        assignAShop: 'Assign to a shop',
        noWmsAttribution: 'No attribution to a WMS',
        noShipmentVoucher: 'No shipment voucher',
        noPurchaseOrder: 'No purchase order',
        noReassortInterne: 'No internals restocking',
        editShipmentVoucher: 'Edit shipment voucher',
        editTransferOrder: 'Edit transfer order',
        receivedBy:'Received by',
        of:'of',
        cannotOpenToBecauseAllClose: 'You cannot reopen this transfer order because all the actions are achieved',
        cannotEditToBecauseIsClose: 'You cannot edit this tranfer order because it is closed',
        errorScan:'The box {barcode} does not exist',
        successScan:'The box {barcode} has been successfully scanned',
        reinstateScan:'The box {barcode} has already been scanned, would you like to reinstate to unscanned boxes ?',
        successReinstate:'The box {barcode} has been successfully reinstate',
        checkingBoxes:'Boxes receipt',
        checkingItems:'Items receipt',
        itemsReceipt:'Items receipt',
        itemsCollect:'Items collection',
        notScannedYet:'This box has not been scanned yet, would you like to be redirected to the boxes receipt ?',
        quantityCommande:'Ordered quantity',
        createStorageSpace:'Create bins in bulk',
        binsList:'Bins List',
        createOneBins:'Create a bins',
        beginningFirstLevel:'Beginning of the first level',
        endFirstLevel:'End of the first level',
        beginningSecondLevel:'Beginning of the second level',
        endSecondLevel:'End of the second level',
        beginningThirdLevel:'Beginning of the third level',
        endThirdLevel:'End of the third level',
        beginningFourthLevel:'Beginning of the fourth level',
        endFourthLevel:'End of the fourth level',
        beginningFifthLevel:'Beginning of the fifth level',
        endFifthLevel:'End of the fifth level',
        firstEndBinsGte:'The end of the firt level value can\'t be higher than the beginning of the firt level',
        secondEndBinsGte:'The end of the second level value can\'t be higher than the beginning of the second level',
        thirdEndBinsGte:'The end of the third level value can\'t be higher than the beginning of the third level',
        fourthEndBinsGte:'The end of the fourth level value can\'t be higher than the beginning of the fourth level',
        fifthEndBinsGte:'The end of the fifth level value can\'t be higher than the beginning of the fifth level',
        firstBins:'First level',
        secondBins:'Second level',
        thirdBins:'Third level',
        fourthBins:'Fourth level',
        fifthBins:'Fifth level',
        zone:'Area',
        successBinsSkip:'Operation performed successfully, however those bins have not been created because they already exist',
        scanedBox:'Scanned boxes',
        scanBinsHere:'Scan the bins here',
        attributeAStorageArea:'Attribute to a storage area',
        selectAStorageArea:'Select a storage area',
        billing:'Invoice',
        quotation:'Quotation',
        quotationCreate:'Quotation creation',
        purchaseOrderB2bCreate:'Purchase order creation',
        deliveryNoteCreate:'Delivery note creation',
        creditNoteCreate:'Credit note creation',
        returnNoteCreate:'Return note creation',
        billingBuyCreate:'Billing buy creation',
        poB2bCollectedUp:'Purchase order: collect',
        poB2bCollectedDown:'Purchase order: put in order area',
        poB2bMouvement:'Purchase order mouvment',
        deliveryNoteOutStock:'Out of stock delivery note',
        outStock:'Out of stock',
        returnNoteReturnInStock:'Return in stock return note',
        rnReceipt:'Return in stock',
        creditNote:'Credit note',
        searchModel:'Search a model',
        searchRoughtDraft:'Search a rought draft',
        selectOnProductList:'Select on the products list',
        itemsSelection:'Items selection',
        addStock:'Import stock',
        or:'Or',
        totalStock:'Total in stock',
        boxesInfos:'Boxes informations',
        binsNumber:'Bins number',
        binsNumb:'Bins number',
        storageArea:'Storage area',
        articlesList:'Items list',
        boxNotScaned:'Box not scanned',
        itemNotExist:'The item {sku} does not exist',
        itemNotExistInThisTo:'The scanned item does not appear in this transfer order',
        itemNotExistInThisPoB2b:'The scanned item does not appear in this purchase order',
        finishScanBefore:'You need to finish scanning this item before scanning another',
        selectRowScan:'Select the block to scan',
        location:'Location',
        binsDetails: 'Bins content',
        zoneDetails: 'Zones content',
        onOrder:'On order from supplier ',
        receivedLines: 'Received line',
        totalHt: 'Total without duty',
        differencePrmp:'Average Cost difference',
        differencePr:'Cost price difference',
        differenceHt:'Without duty difference',
        ecart:'Difference',
        caddie:'Cart',
        providedCashFund:'Expected cash fund',
        prevu:'Expected cash fund',
        prevu_session:'Expected',
        recipient:'Recipient',
        limitDayPayment:'Limit date of payment',
        caseLatePayment:'In case of late payment',
        optionalNoteMsg:'Optional note to include on the document',
        settleInvoice:'Do you want to settle this invoice ?',
        cancelLimitDatePaymentMsg:'That will cancel the limit date of payment if you have filled it in',
        cancelSettleMsg:'That will cancel the settlement if you have filled it in',
        indicateLimitDateOfPayment:'Do you want to indicate a limite date of payment ?',
        indicateLimitValidDate:'Do you want to indicate a limite date of validity ?',
        addTextOnTopPart:'Do you want to add a text on the top part of the document ?',
        addTextOnBottomPart:'Do you want to add a text on the bottom part of the document ?',
        topText:'Top text',
        bottomText:'Bottom text',
        paidOn:'Paid on',
        generateFreebird:'Powered by Freebird',
        dashboard:'Dashboard',
        total:'Total',
        totalWithoutDiscount:'Total without discount',
        tax:'Tax',
        openWith:'Open with',
        binsMovement:'Stock movement',
        stockDeplacement: 'Moving stock',
        simpleMove: 'Simple moving',
        massMouvement:'Mass stock movement',
        avlQuantity:'Available quantity',
        qteToTransfer:'Quantity to transfer',
        qteReceived:'Received quantity',
        receivedOn:'Received on',
        qteToMove:'Quantity to move',
        qteThinkMove:'Quantity picked',
        qteIsMove:'Quantity deposed',
        collectedAt:'Picked at',
        deposedAt:'Deposed at',
        collected:'Picked',
        qteToCollect:'Quantity to collect',
        inLivraison:'On delivery',
        outLivraison:'Out of delivery',
        shipmentDelivery:'Shipment and delivery', 
        carriers:'Carriers',
        orderPreparationZone:'Order preparation zone',
        matchInternalId:'Match internal id',
        matchingInternalIdImport:'Matching of internal id',
        deposed:'Deposed',
        qteReached:'Maximum quantity reached',
        qteExceed:'Maximum quantity exceed',
        qteAvlInsufficient:'Available quantity is insufficient',
        selectStorageDestination:'Select the storage aera',
        cantScanDifferentItem:'You could not scan different items in the same time',
        itemNotInThisArea:'This item not appear in this storage area',
        origin:'Origin',
        destination:'Destination',
        scanTheItemHere:'Scan the item here',
        binsContent:'Bin\'s content',
        emptyBins:'This bins is empty',
        emptyTrolley:'Your trolley is empty',
        totalDevise:'Total in currency',
        referencePo:'Référence',
        internetRef:'Internet order reference',
        seeReceiptVoucher:'See the receipt voucher',
        voucherNumber:'Voucher number',
        receiptDate:'Réceipt date',
        boxNumber:'Box number',
        itemsDetails:'Items details',
        receiptVoucher:'Receipt voucher',
        cancelBoxReceipt:'Receipt box cancellation',
        cancelItemReceipt:'Receipt item cancellation',
        cancellationOnReceipt:'Cancellation on receipt',
        someItemsAlreadyScan: 'Action impossible, items from this box are already in stock',
        cancelItemsEntry:'Cancel items entry in stock',
        itemsReinstate:'Items reintegration',
        cancellationStockEntry:'Cancellation stock entry',
        resolveNegative:'Automatic In-Store Stock Resolution',
        errorReinstateNotPossible:'You can\'t reinstate an item that has not been received',
        selectBlockToReinstate:'Select the block to reinstate',
        selectBlocksToReinstate:'Select the blocks to reinstate',
        qteInSalesPoint:'Quantity in sales point',
        qteInReserve:'Quantity in reserve',
        errorStockReassortMsg:'There are currently some items with negative stock quantities in your sales point, Would you like to proceed with the automatic adjustment shown below ?',
        btnReadjustmentAndRestock:'Apply this readjustment and generate a restock',
        btnReadjustmentAndNoRestock:'Generate a restock without applying the readjustment',
        autoRestock:'Automatic restock',
        reassortInterne:'Internals restocking',
        resolveNegative:'Automatic stock correction',
        resolveShopNegative:'Correction of negative stock in shop',
        internalRestock:'Internal restock',
        restockList:'Restocks list',
        listSelection:'List selection',
        actionOnExistingListItem:'Action on the existing list of items',
        orderList:'Sales order list',
        underwayRestockMsg:'A restocking is underway',
        toCorrectionDeposed:'Stock correction for difference between quantity collected and quantity delivered',
        reorderPoint: 'Reorder point',
        referenceTo:'Reference',
        trolley:'Trolley',
        referenceToNoAccent:'Reference',
        transfert_leNoAccent: 'To be transferred on',
        transfert_le: 'To be transferred on',
        origin_section: 'Origin Shop',
        destination_section: 'Destination Shop',
        rhBarre: 'Barcode staff',
        preferredStockLevel:'Preferred Stock Level',
        actualStock:'Actual stock on hand',
        reassortEnCours:'Restock to come',
        recommendedDestination:'Recommended destinations',
        sameItem:'Same Item',
        sameParent:'Same Parent',
        box:'Box',
        tagSize:'Tag size',
        rpInteger:'{name} reorder point RP must be a integer',
        pslInteger:'{name} preferred stock level PSL must be a integer',
        rppslAllOrNone:'You cannot enter a RP without a PSL (and vice versa) to {name}',
        detailProvider:'Provider details',
        providerCard:'Provider card',
        allPo:'All purchase orders',
        generateABarCode:'Generate a barcode',
        generateMasseBarCode:'Generate barcodes in bulk',
        generateMasseBarCodePdf:'Generate pdf barcodes in bulk',
        generateABarCodePdf:'Generate a pdf barcodes',
        quantityManualModification:'Manual quantity modification',
        stockReorganization:'Stock reorganization',
        removeFromStock:'Remove from stock',
        removeOption:'Remove an option',
        addToStock:'Add to stock',
        chooseAnAction:'Choose an action',
        choisir:'Choose',
        selectSection:'Select a shop',
        stockIntegration:'Stock integration',
        stockRemove:'Stock remove',
        stockAdjustment:'Stock adjustment',
        attemptedStockAdjustment:"Attempted stock adjustment",
        error_open_checkout:'Error on checkout opening',
        error_close_checkout:'Error on checkout closing',
        validateAll:'Validate all',
        reinstateAllItems:'Reinstate all items',
        reinstateOneItem:'Reinstate one item',
        reinstateSelection:'Reinstate the selection',
        confirmReinstateItems:'Do you really want to reinstate all the items ?',
        noBarcodeFounded:'No barcode founded',
        rppsl:'Rppsl',
        createATransferOrder:'Create a transfer order',
        other:'Autre',
        allTransferOrders:'All transfer orders',
        exceptFinished:'except finished',
        transferOrderNotStarted:'Transfer orders not started',
        notStarded:'Not started',
        processOfCollecting:'In process of collecting',
        toEnCours:'Trasnfer Orders in process only',
        processOfDelivery:'In process of delivery',
        processOfEmptying:'In process of emptying',
        transferOrderEdit:'Edit a transfer order',
        errorDeleteTO:'You can\'t delete this transfer order',
        wrongBins:'You have scanned the wrong bins',
        wrongItem:'You have scanned the wrong item',
        stockNegativeAlert:"Negative stock alert",
        stockNegativeAlertLabel:"Alert when selling if the item sold is or will be in negative stock",
        stockNegativeAlertMsg:"The item you just added will have a negative stock if you finalize the sale",
        allowTransferOrderWithDifferentValue:'Transfer orders and orders with different quantity',
        zoneWithoutBins:'Zone without bins',
        uncompletedLines:'Uncompleted lines',
        transferTo:'Transfer to',
        transferOrderCompleted:'Transfer order completed',
        errorToStockMissing:'The transfer order could not be correctly finalized due to a missing stock',
        confirmValidateAsIs:'Are you sure you want to validate as is ?',
        confirmCheckDeposit:'Are you sure you want to validate your check deposit ?',
        confirmGenerateRestockNoReadjust:'Are you sure you want to continue ? The items with negative stock will not be considered for restocking',
        transferOrdersToCollect:'Transfer orders to collect',
        transferOrdersToReceive:'Tansfer orders to receive',
        stockTransfer:'Stock transfer',
        stockTransferConfirm:'Are you sure you want to transfer the stock',
        ecomToCollect:'Sales order to collect',
        transferOrderRelatedPo:'This transfert order is related to a purchase order',
        quantityToReceive:'Received quantity',
        receiveAllItems:'Receive all items',
        receiveItemsByItems:'Receive items by items',
        validAllArticleToReceive:'Validation of all items from a transfer order at the reception',
        noTransferOrdersToReceive:'No transfer orders to receive',
        confirmReceptionWithDifferentQte:'The receive quantity is different from the collected quantity, that will generate a stock readjustment, continue ?',
        linesNumber:'Lines number',
        notCollected:'Not collected',
        alertItemsNotCollected:'Not all items have been collected. If you proceed, you may encounter stock errors in the future. Do you really want to continue ?',
        collection:'Collection',
        depose:'Depose',
        closed:'Closed',
        website:'Website',
        previousMonthTurnover:'Turnover for the previous month',
        presentMonthTurnover:'Turnover for the present month',
        itemsBase:'Items base',
        stockInShop:'Stock in this shop',
        stockInOtherShop:'Stock in other shop',
        priority:'Priority',
        priority:'Priorité',
        toBeCollectedpriority: 'To be collected as a priority',
        restockPriority:'Restock priority',
        collectedOn:'Collected on',
        rppslNotConfigured:'The item\'s RPPSL has not been configured',
        rppslEdit:'Rppsl edit',
        nbMaxToInstruction:'Maximum number of instructions in a transfer order',
        nbMaxEnumere:'Maximum number of pachinglist',
        allItemsAlreadyScaned:'The whole items has been scanned',
        ecommerce:'E-shop',
        shopLiaison:'Shop liaison',
        seller:'Seller',
        discount:'Discount',
        unitaireDiscount:'Unit discount',
        price:'Price',
        unitairePrice:'Unit price',
        unitaireDiscountPrice:'Unit discount price',
        priceWithoutDiscount:'Price without discount',
        priceEdit:'Unit price edit',
        priceModification:'Price modification',
        newPrice:'New price',
        titleOnReceipt:'Title on receipt',
        discountPercent:'Discount %',
        discountNet:'Net discount',
        noDiscount:'No discount',
        addNote:'Add a note',
        modifColumnPosition:'Change the column position',
        clickToHideOrShow:'Click to hide or show a column',
        dragAndDropToChangePosition:'Drag and drop to change the position of a column',
        espece:'Cash',
        check:'Check',
        card:'Card',
        other:'Other',
        amount:'Amount',
        amountTtc:'Amount With Taxes Included',
        insertToRecords:'Addition to transfer order',
        discountedPrice:'Discounted price',
        programmedRecurrence:'Programmed recurrences',
        availableTemplate:'Available templates',
        checkNumber:'Check number',
        modePay:'Payment method',
        bank:'Bank',
        checkNum:'Check number',
        reason:'Reason',
        scanGiftCard:'Scan the Gift Card here',
        payment:'Payment',
        due:'Due',
        promotion:'Promotions',
        zoneNameErrorCarac:'The zone name must not contain special characters',
        percent:'Discount %',
        franche:'Net discount',
        prixfixe:'Fixed price',
        xpoury:'Offer "X for Y',
        percentsecond:'Discount % on 2nd item',
        xpourfixe:'Fixed price for N items',
        cadeaupourx:'Gift for purchase higher than',
        suppercent:'Additional discount in % on item already discounted in %',
        suppercentInstruction:'Add the discounts in %, separating them with a comma',
        noPromoPeriodDefined:'No ending date',
        specifyPaymentMode:'Specify the payment mode',
        createPromo:'Create a promotion',
        qteItems:'Item quantity',
        addImage:'Add an image',
        seeTheList:'See the list',
        reviewAllGols:'Review all sellers goals',
        reviewIndividualGoals:'Review individual goals',
        percentDesc:'Percentage discount, exemple : -20%',
        francheDesc:'Net discount of the price, example : -10$',
        prixfixeDesc:'Assignment of a fixed price to an item',
        xpouryDesc:'Sale a quantity of X items for the price of Y items, exemple : 2 for the price of 1',
        percentsecondDesc:'Percentage discount on the second item, exemple : -50% on the second item',
        xpourfixeDesc:'Sale a quantity of X items for a fixed price, exemple : 2 for 5 000 XPF',
        cadeaupourxDesc:'A gift for a minimum amount of pruchase, exemple : buy for 100$, get a free tee-shirt',
        suppercentDesc:'Additional percentage discount on an already discounted item : an additional -10% on an item already discounted at -30%',
        for:'For',
        automaticRestockPlanning:'Planning of automatic restock',
        itemCreation:'Item creation',
        beginPromo:'Beginning of the promotion',
        numberPointCumule:'Accumulated number of points',
        numberPointsToAccumulate:'Number of points to accumulate',
        totalFideliteCumul:'Total accumulated points',
        totalFideliteSpent:'Total spent points',
        totalFideliteAdjustment:'Total of manual adjustment',
        mergeWithCustomer:'Merge with an other customer',
        perSliceOf:'Per slice of',
        endPromo:'End of the promotion',
        assignedPoint:'Assigned points',
        editLoyaltyPlan:'Edit loyalty plan',
        createLoyaltyPlan:'Create loyalty plan',
        editPromo:'Edit a promotion',
        undefined:'Undefined',
        editGeneralInfos:'Edit the general infos',
        generalInfos:'General infos',
        addPromotion:'Add a promotion',
        currentAndUpcomingLoyaltyPlan: 'Current and upcoming loyalty plans',
        allLoyaltyPlans:'All loyalty plans',
        currentAndUpcomingPromo: 'Current and upcoming promotions',
        currentPromo:'Current promotion',
        upcomingPromo:'Upcoming promotion',
        currentLoyaltyPlan:'Current loyalty plan',
        upcomingLoyaltyPlan:'Upcoming loyalty plan',
        noCurrentPromo:'No current promotions',
        noUpcomingPromo:'No upcoming promotions',
        addToTheSelection:'Add to the list',
        replaceTheList:'Replace the list',
        addToPromotion:'Add to the promotion',
        additionPromo:'Addition of the promotion',
        dependingItemPrice:'Depending on items prices',
        giftOffered:'Gift offered',
        receiptSaved:'Saved receipts',
        receiptNumber:'Receipt number',
        saleSaved:'Sale saved',
        favoriteSaved:'Favorite saved',
        noCurrentSale:'No current sale',
        noSalesSaved:'No sales saved',
        createGiftCard:'Gift card creation',
        scanGiftCardHere:'Scan the gift card here',
        codeBarreContain:'Whose barcode contains',
        deposit:'Deposit amount',
        totalDeposit:'Total deposit',
        totalDiscountPrice:'Total discounted',
        discountTotal:'Discount total',
        cashDepositExecutedBy:'Cash deposit executed by',
        checkDeposit:'Check deposit',
        checkDepositHistorical:'Check deposit historical',
        noCheckDeposit:'No check deposit',
        checkAndCash:'Cash / Check',
        qteMoneyIn:'Quantity of money in',
        doneOn:'Done on',
        changeBack:'Change back',
        deposited:'Deposited',
        giveChange:'Give change',
        notValidGiftCard:'This gift card is not valid in this shop',
        noFundAvailable:'No fund available on this gift card',
        noEnoughFund:'Not enough funds on this gift card',
        reminderMsg:'You have scheduled a reminder for the following documents',
        recurrenceMsg:'You have scheduled a recurrence for the following documents',
        moreDetails:'More details',
        credit:'Credit',
        debit:'Debit',
        dateExpire:'Date of expiration',
        itemReturn:'Item return',
        dontRemise:'Discount',
        scanReceiptHere:'Scan the receipt here',
        scanSalesOrderHere:'Scan the sale order here',
        scanQuotationHere:'Scan the quotation here',
        scanDocHere:'Scan the bar code here',
        docNum:'Document number',
        document:'Document',
        searchOneReceipt:'Search one receipt',
        receiptSearch:'Receipt search',
        devisSearch:'Quotation search',
        enterRefundMethod:'Enter the refund method',
        ticket:'Ticket',
        tickets:'Tickets',
        vente:'Sale',
        retour:'Return',
        refundEspece:'Cash refund',
        refundGiftcard:'Gift card refund',
        refundOther:'Other refund',
        noItemsToReturn:'No items to return',
        noItems:'No items',
        paid:'Paid',
        historical:'Historical',
        salesHistorical:'Sales historical',
        customerHistorical:'Customer historicl',
        availableFund:'Available funds',
        lostFund:'Lost funds',
        totalDepot:'Total funds deposits',
        depot:'Deposite',
        numberDepot:'Number of deposits',
        detail:'Detail',
        itemsNumber:'Number of items',
        bosexNumber:'Number of boxes',
        receiptInProgress:'Receipt in progress',
        messageEndReceipt:'thanks you for your visit',
        downloadingPdf:'Pdf downloading',
        lastSale:'Last sale',
        noPreviousSale:'No sale has been done previously',
        noPreviousDevis:'No quotation has been done previously',
        selectConcernedPeriod:'Select the concerned period',
        addNewPayment:'Add a payment',
        beginningPeriod:'Beginning of period',
        endPeriod:'End of period',
        editPaymentMethod:'Payment method edition',
        noPaymentMethodToEdit:'No payment method to edit',
        checkoutState:'Checkout state',
        totalWithGiftCard:'Total sales with gift card',
        totalWithoutGiftCard:'Total sales without gift card',
        averageReceiptWithGiftCard:'Average receipt with gift card',
        averageReceiptWithoutGiftCard:'Average receipt without gift card',
        wholeShop:'Whole shop',
        filterByEmployee:'Filter by employee',
        filterByCashRegister:'Filter by cash register',
        allStaff:'All staff',
        thisCashRegister:'This cash register',
        concernedShop:'Concerned shops',
        typePromo:'Promotion type',
        statePromo:'Promotion state',
        messageBienvenue:'Hello - Welcome',
        acroTel:'Pho',
        postalAdress:'Postal adress',
        depotGiftCard: 'Gift Card Deposites',
        finished:'Finished',
        poNbr:'Purchase order n°',
        removeAllArticlesConfirm:'Do you really want to remove all items from this promotion',
        manualDiscountPercent:'Manual discount %',
        manualDiscountNet:'Manual net discount',
        manualDeposit:'Manual deposit',
        autreFrais:'Autres cost',
        ecomConnexion:'E-commerce connection',
        caisseIdent:'POS identifier',
        posName:'POS name', 
        actualSumCaisse:'Cash fund expected',
        cashDepositgroup:'Grouped cash deposit',
        bill:'Banknotes',
        coin:'Coins',
        form:'Form',
        preview:'Preview',
        acutalSession:'Actual session',
        cashTotalInCaisse:'Total of cash',
        checksTotalDeposit:'Total of checks on the deposit',
        derniersRetraitEspeces: 'Last cash out',
        dernierRetraitEspecesSingle: 'Last cash out',
        generateAutoCashDeposit:'Generate a cash bank deposit with the total of cash not including the cash fund',
        newStaff:'New staff',
        refresh:'Refresh',
        correspondenceItem:'Item details correspondence ',
        connector:'E-commerce connector',
        personalizedFilter:'Personalized filter',
        periodFilter:'Filter by period',
        correspondenceDeliveryCost:'Correspondence of delivery cost',
        correspondenceDatas:'Datas matching',
        currentWeek:'Current week',
        lastWeek:'Last week',
        nextWeek:'Next week',
        month:'Month',
        currentMonth:'Current month',
        lastMonth:'Last month',
        nextMonth:'Next month',
        currentYear:'Current year',
        lastYear:'Last year',
        saveSale:'Save a sale',
        cancelSaleAndOrder:'Cancel sales or orders',
        paymentDetail:'Payment detail',
        receiptDetail:'Receipt detail',
        depositDetail:'Deposit detail',
        giftCardCredit:'Gift card credit',
        printItemTag:'Print a item tag',
        includeArchives:'Include archives',
        includeExpiredGiftCard:'Include expired gift cards',
        saleOrderDetail:'Sale order details',
        devisDetail:'Quotation details',
        openSaleOrder:'Open sale order',
        objectifAndCa:'Goals and turnover',
        user:'User',
        active:'Active',
        draft:'Draft',
        roughDraftList:'Rough draft list',
        templateList:'Templates list',
        archived:'Archived',
        peripheralDevice:'Peripheral devices',
        newPeripheralDevice:'New peripheral device',
        model:'Model',
        checkoutShortcut:'Checkout shortcut',
        checkoutShortcuts:'Checkout shortcuts',
        newShortcut:'New checkout shortcut',
        new:'New',
        allNotifications:'All notifications',
        noNotifications:'No notification',
        noNewNotifications:'No new notifications',
        seeReceipt:'See the receipt',
        read:'Read',
        unread:'Unread',
        newShipmentToPick:'New packing list assigned',
        newTo:'New transfer order to picked',
        ipAdress:'IP adress or COM port',
        afficheur:'Display',
        tpe:'EPT',
        ingenico:'Ingenico',
        comPort:'COM port',
        imprimante_ticket:'Receipt printer',
        imprimante_cheque:'Check printer',
        imprimante_etiquette:'Tag printer',
        etiquette:'Tag',
        matchBarCode:'Match a bar code',
        matchBarCodeToItem:'Match a bar code to an item',
        epson_tm_h6000V:'Epson TM-H6000V',
        zebra_zd421:'ZPL (Windows)',
        printCaisseIdent:'Print the checkout ident',
        aures_2_l:'Aures display',
        second_screen_display:'Second screen display',
        notAttributedPeripheral:'Not attributed peripherals',
        noPeripheral:'No peripheral',
        noConfiguredPeripheral:'No configured peripheral',
        otherSection:'Other shop',
        disponibleMini:'Avail',
        blockedMini:'Bloc',
        designation:'Designation',
        workTimeToday: 'Specify working hours',
        workTimeDetails:'Distribution of your working time',
        workTime:'Working time',
        chooseProject: 'Select a project',
        allProject: 'All project',
        chooseTask: 'Select a task',
        work_project:'Project',
        work_project_detail:'Task',
        nbrHours: 'Number of hours',
        nbrMinutes: 'Number of minutes',
        aVenir:'To come',
        datasRecorded:'Recorded datas',
        reorganizeColum:'Reorganize columns',
        changePrinter:'Change printer',
        changeZone:'Change zone',
        changeCustomer:'Change customer',
        selectAPrinter:'Select a printer',
        qteTag:'Tag quantity',
        tagPrint:'Tag print',
        limitTagPrint100:'The limit of tag printing is 100',
        mintTagPrint1:'The minimum quantity of tag printing is 1',
        bankDeposit:'Bank deposit',
        salesOrder:'Sale order',
        addToOrder:'Add to sale order',
        expectedCollectDate:'Expected date of collect',
        numberShopifyOrder:'Shopify Order Number',
        collectZone:'Collect location',
        order:'Order',
        commandeChangeCollectZone: 'Change of the collect location',
        commandeOut: 'Leaving the shop area',
        commandeRemoveOut: 'Leaving the order area for transfer order action',
        commandePut: 'Put in sales order area',
        commandeRemovePut: 'Put in sales shop area for transfer order action',
        commandeCancelledOut: 'Sales order cancelled - Leaving the sales order area',
        commandeCancelledPut: 'Sales order cancelled - Put in the shop area',
        orderHistorical:'Sales order historical',
        devisHistorical:'Quotation historical',
        searchByCreationDate:'Search by date of creation',
        searchByCollectDate:'Search by expected date of collect',
        searchASalesOrder:'Search a sale order',
        searchAQuotation:'Search a quotation',
        searchSalesOrder:'Search sales order',
        article:'Item',
        noCheckoutShortcut:'No checkout shortcut',
        validateNewOrder:'Confirm the new order',
        transferHere:'Transfer here',
        transferToOtherShop:'Transfer to other shop',
        expireSession:'Your session has expired ! You\'re gonna be redirected',
        myTime:'My Time',
        /************button********/
        btnAccess: 'Go !',
        btnCancelSalesOrder:'Cancel the sale order',
        btnSeeEditDelete : 'See / Edit / Delete',
        btnValidate: 'Ok',
        btnValidateAndSave:"Validate and save",
        btnDeleteUser: 'Delete this user',
        btnDeleteGrade: 'Delete this grade',
        btnAddRewardLevel:'Add a reward level',
        btnDeleteRh: 'Delete this staff',
        btnYes: "Yes",
        btnNo: "No",
        btnDelete: "Delete",
        btnAddField: "Add field",
        btnRemoveRh: "Remove from store",
        btnRemove: "Remove",
        btnGoClotureCaisse: 'Close Checkout',
        btnAddAllItems:'Add all items from the item base',
        btnFilterItemsToAdd:'Select the items to add',
        btnCancel: 'Cancel',
        btnBackToWelcome: 'Return to Home',
        btnPay: 'Pay',
        btnEffacer: 'Reset',
        btnClose: 'Close',
        btnImport: 'Import',
        btnAddClientForm: 'Add client form',
        btnRemoveClientForm: 'Remove client form',
        btnArchiveStaff: 'Archive this Staff',
        btnActiveStaff: 'Activate this Staff',
        btnArchiveProvider: 'Archive this provider',
        btnDeleteProvider: 'Delete this provider',
        btnUnarchiveProvider: 'Active this provider',
        btnSeeProfil: 'See profil',
        btnDeleteAssociate: 'Delete this association',
        btnSearchAssociateCustomer: 'Start a search for association possibilities',
        btnUnarchiveBrand: 'Active this brand',
        btnArchiveBrand: 'Archive this brand',
        btnDeleteBrand: 'Delete this brand',
        btnAssociate : 'Associate',
        btnCreateNewStaffCustomer: 'Generate a new customer associate to this Staff',
        btnUnarchiveZone: 'Active this area',
        btnArchiveZone: 'Archiver this area',
        btnDeleteZone: 'Delete this area',
        btnShowDetails: 'Show details',
        btnShowReminders:'Show reminders',
        btnInsert: 'Insert',
        btnUpdate: 'Update',
        btnBack: 'Back',
        btnMove:'Move',
        btnReturn:'Return',
        btnSeeBinsContent:'See bins content',
        btnSeeParentProfil:'Parent profil',
        btnValidateAsIs:'Validate as is',
        btnSeeStock:'Get the stock',
        btnItemProfil:'Item profil',
        btnModifier:'Edit',
        btnSave:'Save',
        btnSeePdf:'See the pdf receipt',
        btnPdf:'Voir le pdf',
        btnSeeDevisPdf:'See the pdf quotation',
        btnPrint:'Print',
        btnPayPrint:'Pay & Print',
        btnGiftPrint:'Gift Print',
        btnRemoveAllItems:'Remove all items',
        btnCancelDiscount:'Cancel the current discount',
        /***********Menu**************/
        welcome: 'Home',
        usersList: 'Users List',
        usersCreate: 'Create User',
        rhCreate: 'Create Staff',
        newCustomer:'New customer',
        administrationGenerale: 'General Administration',
        administrationModePaiement: 'Payment methods',
        administrationObjectifPos : 'Goals',
        administrationTurnoverPos : 'Turnover',
        ticketSalesOrder:'Tickets / Sales Order / Quotation',
        administrationCustomForm: 'Custom Forms',
        administrationClientForm: 'Customer',
        administrationFournisseurForm: 'Provider',
        administrationMarqueForm: 'Brand',
        administrationArticleForm: 'Item',
        administrationStaff: 'Staff',
        administrationStaffList: 'Staff List',
        yearOfExercise:'Year of exercise',
        shopName:'Shop name',
        allCreate: 'Create',
        searchStock: 'Search Stock',
        cashRegister: 'Cash Register',
        item: 'Item',
        addToCart:'Add to cart',
        addItem: 'Add new item',
        addImageLink:"Add the link of your item's image",
        importItem: 'Import Items',
        number:'Number',
        importInventory:'Import an inventory',
        importStockOrInventory:'Import a stock or an inventory',
        importItemLoyaltyPlan: 'Import items in the loyalty plan {name}',
        importItemPromotion: 'Import items in {name} promotion',
        importItemPromotionA: 'Import items on list A in the promotion {name}',
        importItemPromotionB: 'Import items on list B in the promotion {name}',
        listAMsgInfo:'Import here the items that will trigger the promotion',
        listBMsgInfo:'Import here the items that will benefit from the promotion',
        listTriggerPromo:'Items list that will trigger the promotion',
        listBenefitPromo:'Items list that will benefit from the promotion',
        noRestockNeeded:'No restock is needed',
        facturation: 'Invoicing',
        productOn:'Product(s) on',
        createBillingQuotation:'Create invoice/quotation',
        formProvider: 'Provider form',
        formSaleOrder: 'Sale order form',
        itemFamily: 'Item families',
        brand: 'Brands',
        client: 'Customers',
        customizedInterface: 'Customized interface',
        createRead:'Create and read',
        import: 'Import',
        allPromotions:'All promotions',
        promoWeight:'Promotion weight',
      valueBetween:'Value between',
        printingInProgress:'Printing in progress',
        movePeripheralsShop: 'Are sure you want to move {deviceName} to {shopName} ?',
        session:'Session',
        /***********Table***********/
        dataTable:'Data Table',
        tableSearch: 'Search',
        tableName: 'Name',
        tableFirst: 'First',
        tableBack: 'Back',
        tableNext: 'Next',
        tableLast: 'Last',
        tableFistTitle: 'First Page',
        tableBackTitle: 'Pre Page',
        tableNextTitle: 'Next Page',
        tableLastTitle: 'Last Page',
        tableNoData: 'No Data',
        tableNoModePaiement: 'No payment method added',
        tableNoCashOutReason: 'No proof of cash withdrawal added',
        tableSex: 'Sex',
        tableFirstName: 'Firstname',
        tablePhone: 'Phone',
        tableRemove: 'Remove',
        tableBarCode: 'Bar Code',
        tableOtherPhone: 'Other phone',
        tableProviderName: 'Provider name',
        tableContactName: 'Contact name',
        tableBrandName: 'Brand name',
        tableContraintes: 'Constraints',
        /*********** Label Form ***********/
        labelSex: 'Sex',
        labelName: 'Name',
        labelCsvSeparator: 'CSV Separator',
        labelCsvEncode: 'CSV Encode',
        labelFirstName: 'First Name',
        labelPassword: 'Password',
        labelPasswordConfirmation: 'Password Confirmation',
        labelPhone: 'Phone',
        labelMaxusemoisproforma: 'Lifespan of a proforma (in month)',
        labelRaisonmodifprix: 'Reasons why a seller can justify manually changing the price of an item',
        labelInfosTicket:'The provided informations will be displayed exactly as you have written it and in the order in which you added it.',
        labelPriorityCollect: 'Define the order in which the collection of a transfer order should be done',
        labelDateFormat: 'Date format',
        labelAdress: 'Adress',
        labelCodePostal: 'Zip code',
        labelVille: 'Town',
        labelPays: 'Country',
        labelCodeBank: 'Bank code',
        labelCodeGuichet: 'Sort code',
        labelNumeroDeCompte: 'Account number',
        labelCleRib: 'RIB key',
        labelBank: 'Bank',
        labelHoraires: 'Hours',
        labelProviderName: 'Provider name',
        labelContactName: 'Contact name',
        labelArchived: 'Archived',
        labelBankName: 'Bank name',
        labelSelectConfigue: 'Choose your configuration',
        labelReason:'Reason',
        labelMaxReaInterne: 'Max quantity by item for an intern restock',
        labelDelaiReglement: 'Payment time',
        labelContact: 'Contact',
        labelMailrelance: 'Reminder email',
        /********** Success Form Response ************/
        sucessUserCreate: 'User created successfully !',
        /**********Power BI module ********************/
        reportPbi:'Reporting',
        listOfGroup:'List of reports group',
        createAGroup:'Create a group of report',
        addPbiLink:'Add report links',
        link:'Link',
        adressRequired:'The link is required',
        adressUrl:'The link must be a valid adress',
        filterByUser:'Filter by user',
        filterByModule:'Filter by module',
        noReportingAvl:'No report available',
        /**********Payment methode ********************/
        espece: 'Cash',
        cheque: 'Check',
        check: 'Check',
        emv_touch:'EMV contactless payment',
        emv_multiple:'EMV Instalment payments',
        amex_touch:'American Express contactless payment',
        amex_multiple:'American Express Instalment payments',
        cb: 'Bleue/Visa Card',
        amex: 'American Express',
        mastercard: 'Mastercard',
        jade: 'Jade Card',
        jcb: 'Jcb Card',
        giftcard: 'Gift card',
        virement: 'Bank Transfer',
        emv:'EMV',
        bonmalistecadeaux: 'Coupon "Ma Liste Cadeaux"',
        bonatelierdujouet: 'Coupon "Atelier du Jouet"',
        annulCommande: 'Sales order cancelled',
        card:'Debit card',
        /********** Week days ************/
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        /********** Access ************/
        createCreditNote:'Create a credit note',
        settleInvoiceAccess:'Settle an invoice',
        assignUser:'Assign users',
        authorizationRequiredForThisAction:'An authorization is required to achieve this action',
      }
    },
    fr: {
      locale: 'fr',
      messages: {
        /*********form response***********/
        //required
        stockMinRequired:'Le seuil de déclenchement est obligatoire',
        stockGoodRequired:'La quantité à réassortir est obligatoire',
        nameRequired: 'Le champ "nom" est obligatoire',
        firstnameRequired: 'Le champ "prénom" est obligatoire',
        emailRequired: 'Le champ "e-mail" est obligatoire',
        mailRelanceRequired: 'Le champ "Mails de relance" est obligatoire',
        passwordRequired: 'Le champ "mot de passe" est obligatoire',
        password_confirmationRequired: 'Le champ "confirmer mot de passe" est obligatoire',
        companyRequired: 'Le champ "entreprise" est obligatoire',
        moduleRequired: 'Le champ "module" est obligatoire',
        sectionRequired: 'Le champ "commerce" est obligatoire',
        sexeRequired: 'Le champ "sexe" est obligatoire',
        sizeTagRequired:'Vous devez indiquer une dimension',
        typefideliteRequired: 'Le champ "Type de Carte de Fidelité" est obligatoire',
        maxusemoisRequired: 'Le champ "Durée de validité" est obligatoire',
        maxusemoisproformaRequired: 'Le champ "Durée de validité d\'une proforma" est obligatoire',
        modepaiementRequired: 'Le champ "Modes de paiement" est obligatoire',
        themeRequired: 'Le champ "Theme" est obligatoire',
        donneeclientRequired: 'Le champ "Activer l\'obligation de collecte des données client" est obligatoire',
        objectifvendeurRequired: 'Le champ "Activer les objectifs vendeurs" est obligatoire',
        objectifcommerceRequired: 'Le champ "Activer les objectifs commerce" est obligatoire',
        dixmilleRequired: 'Vous devez indiquer le nombre de billets de 10 000 XPF',
        cinqmilleRequired: 'Vous devez indiquer le nombre de billets de 5 000 XPF',
        milleRequired: 'Vous devez indiquer le nombre de billets de 1 000 XPF',
        cinqcentRequired: 'Vous devez indiquer le nombre de billets de 500 XPF',
        deuxcentRequired: 'Vous devez indiquer le nombre de pièces de 200 XPF',
        centRequired: 'Vous devez indiquer le nombre de pièces de 100 XPF',
        cinquanteRequired: 'Vous devez indiquer le nombre de pièces de 50 XPF',
        vingtRequired: 'Vous devez indiquer le nombre de pièces de 20 XPF',
        dixRequired: 'Vous devez indiquer le nombre de pièces de 10 XPF',
        cinqRequired: 'Vous devez indiquer le nombre de pièces de 5 XPF',
        deuxRequired: 'Vous devez indiquer le nombre de pièces de 2 XPF',
        unRequired: 'Vous devez indiquer le nombre de pièces de 1 XPF',
        gradeRequired: 'Vous devez indiquer un grade',
        caRequired: 'Vous devez indiquer un CA HT',
        cattcRequired: 'Vous devez indiquer un CA TTC',
        pmRequired: 'Vous devez indiquer un PM HT',
        pmttcRequired: 'Vous devez indiquer un PM TTC',
        tfRequired: 'Vous devez indiquer un TF',
        ivRequired: 'Vous devez indiquer un IV',
        entreRequired: 'Vous devez indiquer un nombre de passages clients (entrée et sortie)',
        beginRequired: 'Vous devez indiquer la date de début',
        endRequired: 'Vous devez indiquer la date de fin',
        dayRequired: 'Vous devez indiquer le jour',
        telephoneRequired: 'Vous devez indiquer le téléphone',
        adresseRequired: 'Vous devez indiquer l\'adresse physique',
        adressepostaleRequired: 'Vous devez indiquer l\'adresse postale',
        newsletterRequired: 'Vous devez cocher la newsletter',
        civiliteRequired: 'Vous devez indiquer la civilité',
        csvEncodeRequired: 'Vous devez indiquer l\'encodage CSV',
        csvSeparatorRequired: 'Vous devez indiquer le séparateur CSV',
        csvDateFormatRequired: 'Vous devez indiquer le format de date CSV',
        codeRequired:'Vous devez indiquer un code',
        rcsRequired:'Vous devez indiquer un RCS',
        adressRequired:'Vous devez indiquer l\'adresse',
        codePostalRequired:'Vous devez indiquer un code postal',
        villeRequired:'Vous devez indiquer une ville',
        paysRequired:'Vous devez indiquer un pays',
        codeBanqueRequired: 'Vous devez indiquer un code banque',
        codeGuichetRequired: 'Vous devez indiquer un code guichet',
        numeroCompteRequired: 'Vous devez indiquer un numéro de compte',
        cleRibRequired: 'Vous devez indiquer une clée RIB',
        clientTypeRequired: 'Vous devez indiquer un type',
        quartierRequired: 'Vous devez indiquer un quartier',
        typeRequired: 'Vous devez indiquer un type',
        dateDeNaissanceRequired: 'Vous devez indiquer une date de naissance',
        providerNameRequired: 'Vous devez indiquer un nom de fournisseur',
        contactNameRequired: 'Vous devez indiquer un nom de contact',
        statusRequired: 'Vous devez indiquer un status',
        itemFamilyNameRequired: 'Vous devez indiquer un nom de famille d\'article',
        brandNameRequired: 'Vous devez indiquer un nom de marque',
        categoryRequired: 'Vous devez indiquer une catégorie',
        referenceRequired: 'Vous devez indiquer un code UPC',
        referenceToRequired: 'Vous devez indiquer une référence',
        skuRequired: 'Vous devez indiquer un SKU',
        internalidRequired: 'Vous devez indiquer l\'ID interne de l\'article',
        priceRequired: 'Vous devez indiquer un prix TTC',
        tgcRequired: 'Vous devez indiquer un taux de TGC',
        prmpRequired: 'Vous devez indiquer un PRMP',
        havestockRequired: 'Vous devez indiquer si cet article doit être dépendant d\'un stock',
        colorHexaRequired: 'Vous devez indiquer un code couleur hexadecimal',
        banqueRequired: 'Vous devez indiquer le nom de la banque',
        customFieldRequired: 'Vous devez renseigner le champ "{name}"',
        numeroShipmentRequired:'Vous devez indiquer le numéro d\'expédition',
        shdeCartonNameRequired:'Vous devez indiquer une référence carton',
        shdeQuotaPrevuRequired:'Vous devez indiquer la quantité prévue',
        firstStartBinsRequired:'Vous devez indiquer le début du premier niveau',
        firstEndBinsRequired:'Vous devez indiquer la fin du premier niveau',
        zoneRequired:'Vous devez indiquer une zone',
        secondEndBinsRequiredWith:'Vous devez indiquer la fin du premier niveau si le début du premier niveau est renseigné',
        thirdEndBinsRequiredWith:'Vous devez indiquer la fin du second niveau si le début du second niveau est renseigné',
        fourthEndBinsRequiredWith:'Vous devez indiquer la fin du troisième niveau si le début du troisième niveau est renseigné',
        fifthEndBinsRequiredWith:'Vous devez indiquer la fin du quatrième niveau si le début du quatrième niveau est renseigné',
        firstBinsRequired:'Vous devez indiquer le premier niveau',
        quotaRequired: 'Vous devez indiquer la quantité',
        actionRequired:'Vous devez indiquer le champ action',
        binsRequired:'Vous devez indiquer la bins',
        quantiteRequired:'Vous devez indiquer la quantité',
        fieldRequired:'Ce champ est requis',
        expectedRequired: 'La date souhaitée d\'exécution est requise',
        rhRequired: 'Vous devez indiquer un staff',
        rhBarreRequired: 'Vous devez indiquer le code-barre d\'un staff',
        osectionRequired: 'Vous devez indiquer un commerce d\'origine',
        fsectionRequired: 'Vous devez indiquer un commerce de destination',
        rpRequired: 'Vous devez indiquer le niveau de stock minimum',
        pslRequired: 'Vous devez indiquer le niveau de stock optimal',
        toMaxRequired: 'Le nombre maximum d\'instructions pour un ordre de transfert est obligatoire',
        articleRequired: 'Vous devez choisr un article',
        promotionRequired: 'Vous devez choisir promotion',
        allFieldsRequired: 'Tous les champs doivent être remplis',
        promoImport:'Import promotions',
        codeCaisseRequired: 'Vous devez indiquer un code d\'identification de la caisse',
        caisseIdentRequired:'Vous devez indiquer l\'identifiant de caisse',
        hostRequired:'Vous devez indiquer une adresse IP',
        comPortRequired:'Vous devez indiquer un port COM',
        modelRequired:'Vous devez choisir un modèle',
        poRequired:'Vous devez choisir un bon de commande',
        waitingEpt:'En attente TPE',
        errorEpt:'Erreur réponse TPE',
        newCheckoutShortcutProfil:'Nouveau profil de raccourci',
        checkoutShorcutProfil:'Profils de raccourci',
        titreRequired:'Vous devez indiquer un titre',
        messageRequired:'Vous devez indiquer un message',
        dateRequired:'Vous devez indiquer une date',
        capitalRequired:'Vous devez indiquer le capital',
        exerciceStartRequired:'Vous devez indiquer le début de l\'exercice',
        montantRequired:'Vous devez indiquer un montant',
        itemsRequiredInTo:'Vous ne pouvez pas valider un ordre de transfert sans articles',
        pageNamerequired:'Vous devez indiquer le nom de la page',
        filtreNamerequired:'Vous devez indiquer le nom du favori',
        heureRequired:'Vous devez indiquer le nombre d\'heures',
        minuteRequired:'Vous devez indiquer le nombre de minutes',
        valueRequired:'Vous devez indiquer une valeur',
        clientRequired:'Vous devez indiquer un client',
        noteRequired:'Vous devez indiquer une note',
        delaiReglementRequired:'Vous devez indiquer un délai de règlement',
        //Min
        capitalMin1:'Le capital doit au moins être 1 XPF',
        poidsMin1:'Le poids minimum est 1',
        exerciceStartMin1:'Le début de l\'exercice doit au moins être 1 (janvier)',
        pageNameMin2: 'Le nom de la page doit comporter au moins 2 caractères',
        filtreNameMin2: 'Le nom du favori doit comporter au moins 2 caractères',
        nameMin2: 'Le champ "nom" doit comporter au moins 2 caractères',
        nameMin5: 'Le champ "nom" doit comporter au moins 5 caractères',
        skuMin2: 'Le champ "sku" doit comporter au moins 2 caractères',
        firstnameMin2: 'Le champ "prénom" doit comporter au moins 2 caractères',
        passwordMin8: 'Le champ "mot de passe" doit comporter au moins 8 caractères',
        phonedMin6: 'Le champ "téléphone" doit comporter au moins 6 caractères',
        phoneMin6: 'Le champ "téléphone" doit comporter au moins 6 caractères',
        maxusemoisdMin1: 'Le champ "Durée de validité" doit être au minimum à 1 mois',
        maxusemoisproformaMin1: 'Le champ "Durée de validité d\'une proforma" doit être au minimum à 1 mois',
        dixmilleMin0: 'Le nombre de billets de 10 000 XPF ne peut pas être inférieur à 0',
        cinqmilleMin0: 'Le nombre de billets de 5 000 XPF ne peut pas être inférieur à 0',
        milleMin0: 'Le nombre de billets de 1 000 XPF ne peut pas être inférieur à 0',
        cinqcentMin0: 'Le nombre de billets de 500 XPF ne peut pas être inférieur à 0',
        deuxcentMin0: 'Le nombre de pièces de 200 XPF ne peut pas être inférieur à 0',
        centMin0: 'Le nombre de pièces 100 XPF ne peut pas être inférieur à 0',
        cinquanteMin0: 'Le nombre de pièces 50 XPF ne peut pas être inférieur à 0',
        vingtMin0: 'Le nombre de pièces 20 XPF ne peut pas être inférieur à 0',
        dixMin0: 'Le nombre de pièces 10 XPF ne peut pas être inférieur à 0',
        cinqMin0: 'Le nombre de pièces 5 XPF ne peut pas être inférieur à 0',
        deuxMin0: 'Le nombre de pièces 2 XPF ne peut pas être inférieur à 0',
        unMin0: 'Le nombre de pièces 1 XPF ne peut pas être inférieur à 0',
        caMin0: 'Le CA HT ne peut pas être inférieur à 0',
        cattcMin0: 'Le CA TTC ne peut pas être inférieur à 0',
        pmMin0: 'Le PM HT ne peut pas être inférieur à 0',
        pmttcMin0: 'Le PM TTC ne peut pas être inférieur à 0',
        tfMin0: 'Le TF ne peut pas être inférieur à 0',
        ivMin0: 'Le IV ne peut pas être inférieur à 0',
        entreMin0: 'Le nombre de passages clients (entrée et sortie) ne peut pas être inférieur à 0',
        codeMin3: 'Le champ "code" doit comporter au moins 3 caractères',
        rcsMin5: 'Le champ "RCS" doit comporter au moins 5 caractères',
        codeBanqueMin5: 'Le champ "Code Banque" doit comporter au moins 5 caractères',
        codeGuichetMin5: 'Le champ "Code Guichet" doit comporter au moins 5 caractères',
        numeroCompteMin11: 'Le champ "Numéro de Compte" doit comporter au moins 11 caractères',
        cleRibMin2: 'Le champ "Clée RIB" doit comporter au moins 2 caractères',
        nomContactMin2: 'Le nom du contact doit comporter au moins 2 caractères',
        priceMin: 'Le prix ne peut être inférieur à 0',
        promoPriceMin: 'Le prix promotionnel ne peut être inférieur à 0',
        tgcMin: 'Le taux de TGC ne peut être inférieur à 0',
        colorHexaMin: 'Le code couleur hexadecimal doit faire 6 caractères',
        buyPriceMin: 'Le prix d\'achat ne peut être inférieur à 0',
        prmpMin: 'Le PRMP ne peut être inférieur à 0',
        prMin: 'Le prix de revient ne peut être inférieur à 0',
        indicePrmpMin: 'L\'indice PRMP ne peut être inférieur à 0',
        indicePrMin: 'L\'indice prix de revient ne peut être inférieur à 0',
        deviseAchatMin: 'La devise d\'achat doit compoter 3 caractères',
        quotaMin: 'La quantité ne peut pas être inférieur à 0',
        quotaCommandeMin: 'La quantité ne peut pas être inférieur à 0',
        numeroShipmentMin2:'Le champ "numéro d\'expédition" doit comporter au moins 2 caractères',
        shdeCartonNameMin2: 'La référence carton doit faire au moins 2 caractères',
        shdeQuotaPrevuMin: 'La quantité prévue ne peut pas être inférieur à 0',
        firstStartBinsMin0:'Le début du premier niveau ne peut pas être inférieur à 1',
        firstEndBinsMin0:'La fin du premier niveau ne peut pas être inférieur à 1',
        secondStartBinsMin0:'Le début du second niveau ne peut pas être inférieur à 1',
        secondEndBinsMin0:'La fin du second niveau ne peut pas être inférieur à 1',
        thirdStartBinsMin0:'Le début du troisième niveau ne peut pas être inférieur à 1',
        thirdEndBinsMin0:'La fin du troisième niveau ne peut pas être inférieur à 1',
        fourthStartBinsMin0:'Le début du quatrième niveau ne peut pas être inférieur à 1',
        fourthEndBinsMin0:'La fin du quatrième niveau ne peut pas être inférieur à 1',
        fifthStartBinsMin0:'Le début du cinquième niveau ne peut pas être inférieur à 1',
        fifthEndBinsMin0:'La fin du cinquième niveau ne peut pas être inférieur à 1',
        firstBinsMin0:'Le premier niveau ne peut pas être inférieur à 1',
        secondBinsMin0:'Le second niveau ne peut pas être inférieur à 1',
        thirdBinsMin0:'Le troisième niveau ne peut pas être inférieur à 1',
        fourthBinsMin0:'Le quatrième niveau ne peut pas être inférieur à 1',
        fifthBinsMin0:'Le cinquième niveau ne peut pas être inférieur à 1',
        referencePoMin2:'La référence de bon de commande doit comporter au moins 2 caractères',
        rpIsMin0:'Le niveau de stock minimum RP de {name} ne peut pas être inférieur 0',
        pslIsMin0:'Le niveau de stock optimal PSL de {name} ne peut pas être inférieur 0',
        quantiteMin1:'La quantité ne peut être inférieur à 1',
        quantiteMin0:'La quantité ne peut être inférieur à 0',
        referenceToMin2:'La référence doit faire au moins 2 caractères',
        rpMin0:'Le niveau de stock minimal ne peut pas être inférieur à 0',
        pslMin0:'Le niveau de stock optimal ne peut pas être inférieur à 0',
        toMaxMin10:'Le nombre maximum d\'instructions pour un ordre de transfert ne peut pas être inférieur 10',
        giftCardMin1:'La durée de validité d\'un dépôt sur une carte cadeau ne peut pas être inférieur à 1 mois',
        giftCardMontantMin: 'Le montant de la carte cadeau ne peut pas être inférieur à 1',
        paiementMin: 'Les indications de paiement doivent être supérieur à 0',
        modifManualValueMin1: 'La valeur doit être supérieur à 1',
        codeCaisseMin4: 'L\'identifiant de caisse doit comporter au moins 4 caractères',
        detailModePaiementMin0: 'Les valeurs ne peuvent pas être inférieur à 0',
        titreMin2: 'Le titre doit faire au moins 2 caractères',
        messageMin2:'Le message doit faire au moins 2 caractères',
        typeMin2:'Le type doit faire au moins 2 caractères',
        ticketNameMin1:'L\'intitulé sur ticket doit faire au moins un caractère',
        heureMin0:'Les heures ne peuvent pas être inférieur à 0',
        minuteMin0:'Les minutes ne peuvent pas être inférieur à 0',
        //Max
        exerciceStartMax12:'Le début de l\exercice ne peut être supérieur à 12 (december)',
        poidsMax100:'Le poids maximum est 100',
        lineLimitationZoneContent:'L\'affichage est limitée à 1000 lignes',
        quantiteMax8000000:'La quantité doit être inférieur à 8 000 000',
        pageNameMax191:'Le nom de la page doit comporter moins de 191 caractères',
        filtreNameMax191:'Le nom du favori doit comporter moins de 191 caractères',
        nameMax191: 'Le champ "nom" doit comporter moins de 191 caractères',
        nameMax250: 'Le champ "nom" doit comporter moins de 250 caractères',
        nameMax50: 'Le champ "nom" doit comporter moins de 50 caractères',
        skuMax250: 'Le champ "Sku" doit comporter moins de 250 caractères',
        phoneMax20: 'Le champ "téléphone" doit comporter moins de 20 caractères',
        firstnameMax50: 'Le champ "prénom" doit comporter moins de 50 caractères',
        firstnameMax191: 'Le champ "prénom" doit comporter moins de 191 caractères',
        nameMax20: 'Le champ "nom" doit comporter moins de 20 caractères',
        logoMax50: 'Le champ "logo" doit comporter moins de 50 caractères',
        gradeMax50: 'Le champ "grade" doit comporter moins de 50 caractères',
        maxusemoisdMax120: 'Le champ "Durée de validité" ne peut pas excéder 120 mois',
        maxusemoisproformaMax120: 'Le champ "Durée de validité d\'une proforma" ne peut pas excéder 120 mois',
        caMax2000000000: 'Le CA HT ne peut pas excéder 2 000 000 000 XPF',
        cattcMax2000000000: 'Le CA TTC ne peut pas excéder 2 000 000 000 XPF',
        pmMax2000000000: 'Le PM HT ne peut pas excéder 2 000 000 000 XPF',
        pmttcMax2000000000: 'Le PM TTC ne peut pas excéder 2 000 000 000 XPF',
        tfMax100: 'Le TF ne peut pas excéder 100 %',
        ivMax999: 'Le IV ne peut pas excéder 999 articles',
        entreMax10000000: 'Le nombre de passages clients (entrée et sortie) ne peut pas excéder 10 000 000',
        codeMax5: 'Le champ "code" doit comporter moins de 5 caractères',
        rcsMax20: 'Le champ "RCS" doit comporter moins de 20 caractères',
        codeBanqueMax5: 'Le champ "Code Banque" doit comporter moins de 5 caractères',
        codeGuichetMax5: 'Le champ "Code Guichet" doit comporter moins de 5 caractères',
        numeroCompteMax11: 'Le champ "Numéro de Compte" doit comporter moins de 11 caractères',
        cleRibMax2: 'Le champ "Clée RIB" doit comporter moins de 2 caractères',
        nomContactMax191: 'Le nom du contact doit comporter moins de 191 caractères',
        priceMax: 'Le prix ne peut pas être supérieur à 999999999.99',
        referenceMax191: 'Le code UPC doit faire moins de 191 caractères',
        reference2Max191: 'Le code UPC 2 doit faire moins de 191 caractères',
        internalIdMax191: 'L\'ID interne doit faire moins de 191 caractères',
        referenceToMax191: 'Le référence doit faire moins de 191 caractères',
        skuMax191: 'Le SKU doit faire moins de 191 caractères',
        parentNameMax250: 'Le Nom Parent doit faire moins de 250 caractères',
        promoPriceMax: 'Le prix promotionnel ne peut pas être supérieur à 999999999.99',
        prmpMax: 'Le PRMP ne peut pas être supérieur à 999999999.99',
        prMax: 'Le prix de revient ne peut pas être supérieur à 999999999.99',
        tgcMax: 'Le taux de TGC ne peut pas être supérieur à 100',
        imageMax250: 'Le lien image article doit comporter moins de 250 caractères',
        colorHexaMax: 'Le code couleur hexadecimal doit faire 6 caractères',
        buyPriceMax: 'Le prix d\'achat ne peut pas être supérieur à 999999999.99',
        indicePrmpMax: 'L\'indice PRMP ne peut pas être supérieur à 999999999.99',
        indicePrMax: 'L\'indice prix de revient ne peut pas être supérieur à 999999999.99',
        deviseAchatMax: 'La devise d\'achat doit compoter 3 caractères',
        maxPhoneLength6: 'Le numéro de téléphone doit comporter 6 chiffres maximum',
        quotaCommandeMax: 'La quantité ne peut pas être supérieur à 8000000',
        quotaMax: 'La quantité ne peut pas être supérieur à 8000000',
        quotaMax10000: 'La quantité ne peut pas être supérieur à 10000',
        numeroShipmentMax190:'Le numéro d\'expédition doit faire moins de 190 caractères',
        shdeCartonNameMax250: 'La référence carton doit faire moins de 250 caractères',
        shdeQuotaPrevuMax: 'La quantité prévue ne peut pas être supérieur à 8000000',
        firstStartBinsMax32000:'Le début de premier niveau ne peut pas être supérieur à 32000',
        firstEndBinsMax32000:'La fin du premier niveau ne peut pas être supérieur à 32000',
        secondStartBinsMax32000:'Le début de second niveau ne peut pas être supérieur à 32000',
        secondEndBinsMax32000:'La fin du second niveau ne peut pas être supérieur à 32000',
        thirdStartBinsMax32000:'Le début de troisième niveau ne peut pas être supérieur à 32000',
        thirdEndBinsMax32000:'La fin du troisième niveau ne peut pas être supérieur à 32000',
        fourthStartBinsMax32000:'Le début de quatrième niveau ne peut pas être supérieur à 32000',
        fourthEndBinsMax32000:'La fin du quatrième niveau ne peut pas être supérieur à 32000',
        fifthStartBinsMax32000:'Le début de cinquième niveau ne peut pas être supérieur à 32000',
        fifthEndBinsMax32000:'La fin du cinquième niveau ne peut pas être supérieur à 32000',
        firstBinsMax32000:'Le premier niveau ne peut pas être supérieur à 32000',
        secondBinsMax32000:'Le second niveau ne peut pas être supérieur à 32000',
        thirdBinsMax32000:'Le troisième niveau ne peut pas être supérieur à 32000',
        fourthBinsMax32000:'Le quatrième niveau ne peut pas être supérieur à 32000',
        fifthBinsMax32000:'Le cinquième niveau ne peut pas être supérieur à 32000',
        referencePoMax190:'La référence de bon de commande doit être inférieur à 190 caractères',
        rpIsMax8000000:'Le niveau de stock minimum RP de {name} ne peut pas être supérieur à 8000000',
        pslIsMax8000000:'Le niveau de stock optimal PSL de {name} ne peut pas être supérieur à 8000000',
        generateBarcodeMax1000:'Vous ne pouvez pas générer plus 1000 code-barres',
        rpMax8000000:'Le niveau de stock minimal ne peut pas être supérieur à 8000000',
        pslMax8000000:'Le niveau de stock optimal ne peut pas être superieur à 8000000',
        toMaxMax1000:'Le nombre maximum d\'instructions pour un ordre de transfert ne peut pas être supérieur 1 000',
        giftCardMax120:'La durée de validité d\'un dépôt sur une carte cadeau ne peut pas être supérieur à 120 mois',
        giftCardMontantMax: 'Le montant de la carte cadeau ne peut pas être supérieur à 100 000 000',
        paiementMax: 'Les indications de paiement doivent être inférieures à 1 000 000 000',
        codeCaisseMax30: 'L\identifiatn de caisse doit faire moins de 30 caractères',
        cashOutReasonMax100: 'La raison d\'un retrait d\'espèces doit faire moins de 100 caractères',
        titreMax250: 'Le titre doit faire moins de 250 caractères',
        typeMax50: 'Le type doit faire moins de 50 caractères',
        ticketNameMax15:'L\'intitulé sur ticket doit faire moins de 15 caractères',
        heureMax23:'Les heures doivent être inférieures à 23',
        minuteMax59:'Les minutes doivent être inférieur à 59',
        //email
        emailEmail: 'Le champ "email" doit être un e-mail valide',
        //unique
        emailUnique: 'Il y a déjà un compte enregistré avec cette adresse e-mail',
        nameUnique: 'Ce nom exist déjà',
        skuUnique: 'Un autre article possède déjà la même Sku',
        referenceUnique: 'Le code UPC est déjà utilisé par un autre article',
        referenceEnumereUnique: 'Le code-barre du conditionnement est déjà utilisé par un autre conditionnement',
        reference2Unique: 'Le code UPC 2 est déjà utilisé par un autre article',
        skuUnique: 'Le SKU est déjà utilisé par un autre article',
        alreadyExist: 'à déjà été assigné',
        sectionUnique: 'Cette rubrique existe déjà',
        zoneInventaireAlreadyExists: 'Vous avez déjà une zone d\'inventaire qui porte le même nom',
        //confirmed
        passwordConfirmed: 'Le mot de passe a mal été confirmé',
        confirmDifferentQuantityCollected:'La quantité collectée ne correspond pas à la quantité demandée, confirmer ?',
        confirmDifferentQuantityReceipt:'La quantité réceptionnée ne correspond pas à la quantité collectée, confirmer ?',
        confirmCancelSaleOrder:'Êtes-vous sûre de vouloir annuler cette commande client',
        confirmValidateDocument:'Êtes-vous sûre de vouloir valider ce document',
        confirmCancelDocument:'Êtes-vous sur de vouloir annuler ce document ?',
        confirmValidateDeliveryNote:'Êtes-vous sur de vouloir valider ce bon de livraison? Cela va sortir du stock les articles de ce bon de livraison',
        confirmConvertDeliveryNoteToInvoice:'Êtes-vous sur de vouloir convertir ce bon de livraison en facture?',
        //In
        roleIn: 'Le role attribué n\'est pas accepté',
        sexeIn: 'Le sexe attribué n\'est pas accepté',
        themeIn: 'Le thème attribué n\'est pas accepté',
        modepaiementIn: 'Un des modes de paiements indiqué n\'est pas accepté',
        priorityIn: 'La valeur de "Priorité" doit être Oui ou Non',
        customFieldIn: 'Le champ "{name}" a une valeur non valide',
        //exists
        companyExists: 'Cette entreprise n\'existe pas',
        moduleExists: 'Ce module n\'existe pas',
        sectionExists: 'Ce commerce n\'existe pas',
        rhExists: 'Ce staff n\'existe pas',
        gradeExists: 'Ce grade n\'existe pas',
        typefideliteExists: 'Ce type de carte de fidelité n\'existe pas',
        programfideliteExists: 'Ce programme de fidelité n\'existe pas',
        programgiftcardExists: 'Ce programme de Gift Card n\'existe pas',
        clientExists: 'Il existe déjà un client enregistré avec ce nom et ce prénom',
        giftCardExists: 'Il existe déjà une carte cadeau avec cette référence',
        internalIdExists: 'L\'internal ID est incorrect',
        expectedInternalIdAlreadyExists: 'L\'internal ID souhaité existe déjà',
        internalNotIdExists: 'L\'internal ID existe déjà',
        firstlevelcatExists: 'Aucune correspondance trouvée avec le champ "Classification"',
        secondlevelcatExists: 'Aucune correspondance trouvée avec le champ "Famille"',
        thirdlevelcatExists: 'Aucune correspondance trouvée avec le champ "Sous-famille"',
        fourthlevelcatExists: 'Aucune correspondance trouvée avec le champ "Catégorie"',
        fifthlevelcatExists: 'Aucune correspondance trouvée avec le champ "Sous-catégorie"',
        fournisseurExists: 'Aucune correspondance trouvée avec le champ "Fournisseur"',
        marqueExists: 'Aucune correspondance trouvée avec le champ "Marque"',
        nameExists: 'Ce nom est déjà utilisé',
        referenceExists: 'Cette référence est déjà utilisée',
        articleNameExists: 'Aucun article ne correspond à ce nom d\'article',
        articleNameAlreadyExists: 'Le nom de cet article est déjà utilisé par un autre article',
        articleSkuExists: 'Aucun article ne correspond à ce Sku',
        poNameExists: 'Ce bon de commande n\'existe pas',
        devisNotExists: 'Ce devis n\'existe pas',
        sectionCodeExists: 'Ce code de commerce n\'existe pas',
        numeroShipmentExists: 'Ce numéro de bon d\'expédition est déjà utilisé',
        shipmentNameExists: 'Ce numéro de bon d\'expédition n\'existe pas',
        idsExists: 'L\'ID est incorrect',
        binsExists: 'Une bins avec la même adresse existe déjà pour cette zone de stockage',
        shdeCartonNameExists: 'Ce carton n\'existe pas dans ce bon d\'expédition',
        binsAlreadyExistsInSection: 'Une bins avec la même adresse existe déjà pour ce commerce',
        binsNotInThisSection: 'Cette bins n\'existe pas dans ce commerce',
        binsNotInThisZone: 'Cette bins n\'existe pas dans cette zone',
        zoneNotInThisSection: 'Cette zone n\'existe pas dans ce commerce',
        binsNotExist: 'Cette bins n\'existe pas',
        binsNotExistInToList: 'Cette bins ne figure pas dans votre ordre de transfert',
        binsNotExistInInventory:'Cette bins ne fait pas partie de votre zone d\'inventaire',
        itemNotExistInInventory:'Cet article ne fait pas partie de votre zone d\'inventaire',
        codeCaisseExists: 'Cet identifiant de caisse est déjà utilisé',
        caisseIdentExists:'Cet identifiant de caisse n\'existe pas',
        namePeripheriqueExists:'Ce nom de périphérique est déjà utilisé',
        articleAlreadyInTo:'Cet article est déjà présent dans cet ordre de transfert',
        //array
        moduleArray: 'Une erreur inattendue s\'est produite',
        sectionArray: 'Une erreur inattendue s\'est produite',
        rhArray: 'Une erreur inattendue s\'est produite',
        modepaiementArray: 'Une erreur inattendue s\'est produite',
        raisonmodifprixArray: 'Une erreur inattendue s\'est produite',
        //General
        sectionGeneral: 'Une erreur inattendue s\'est produite',
        rhGeneral: 'Une erreur inattendue s\'est produite',
        programfideliteGeneral: 'Une erreur inattendue s\'est produite',
        programgiftcardGeneral: 'Une erreur inattendue s\'est produite',
        forbiddenGeneral: 'Vous n\'êtes pas autorisé à réaliser cette action',
        //Numeric
        maxusemoisNumeric: 'Le champ "Durée de validité" doit être une valeur numeriqe',
        maxusemoisproformaNumeric: 'Le champ "Durée de validité d\'une proforma" doit être une valeur numeriqe',
        dixmilleNumeric: 'Le nombre de billets de 10 000 XPF doit être une valeur numérique',
        cinqmilleNumeric: 'Le nombre de billets de 5 000 XPF doit être une valeur numérique',
        milleNumeric: 'Le nombre de billets de 1 000 XPF doit être une valeur numérique',
        cinqcentNumeric: 'Le nombre de billets de 500 XPF doit être une valeur numérique',
        deuxcentNumeric: 'Le nombre de pièces de 200 XPF doit être une valeur numérique',
        centNumeric: 'Le nombre de pièces de 100 XPF doit être une valeur numérique',
        cinquanteNumeric: 'Le nombre de pièces de 50 XPF doit être une valeur numérique',
        vingtNumeric: 'Le nombre de pièces de 20 XPF doit être une valeur numérique',
        dixNumeric: 'Le nombre de pièces de 10 XPF doit être une valeur numérique',
        cinqNumeric: 'Le nombre de pièces de 5 XPF doit être une valeur numérique',
        deuxNumeric: 'Le nombre de pièces de 2 XPF doit être une valeur numérique',
        unNumeric: 'Le nombre de pièces de 1 XPF doit être une valeur numérique',
        caNumeric: 'Le CA HT doit être une valeur numérique',
        cattcNumeric: 'Le CA TTC doit être une valeur numérique',
        pmNumeric: 'Le PM HT doit être une valeur numérique',
        pmttcNumeric: 'Le PM TTC doit être une valeur numérique',
        tfNumeric: 'Le TF doit être une valeur numérique',
        ivNumeric: 'Le IV doit être une valeur numérique',
        capitalNumeric:'Le capital doit être une valeur numérique',
        exerciceStartNumeric:'Le début de l\'exercice doit être une valeur numérique',
        entreNumeric: 'Le nombre de passages clients (entrée et sortie) doit être une valeur numérique',
        codeBanqueNumeric: 'Le champ "Code Banque" doit être une valeur numérique',
        codeGuichetNumeric: 'Le champ "Code Guichet" doit être une valeur numérique',
        numeroCompteNumeric: 'Le champ "Numéro de Compte" doit être une valeur numérique',
        cleRibNumeric: 'Le champ "Clée Rib" doit être une valeur numérique',
        priceNumeric: 'Le prix doit être une valeur numérique',
        promoPriceNumeric: 'Le prix promotionnel doit être une valeur numérique',
        prmpNumeric: 'Le PRMP doit être une valeur numérique',
        prNumeric: 'Le prix de revient doit être une valeur numérique',
        tgcNumeric: 'Le taux de TGC doit être une valeur numérique',
        buyPriceNumeric: 'Le prix d\'achat doit être une valeur numérique',
        indicePrmpNumeric: 'L\'indice PRMP doit être une valeur numérique',
        indicePrNumeric: 'L\'indice prix de revient doit être une valeur numérique',
        internalIdNumeric: 'L\'internal Id doit être une valeur numérique',
        expectedInternalIdNumeric: 'L\'internal Id souhaité doit être une valeur numérique',
        referenceNumeric: 'Le code UPC doit être une valeur numérique',
        quotaNumeric: 'La quantité doit être une valeur numérique',
        quotaCommandeNumeric: 'La quantité doit être une valeur numérique',
        shdeQuotaPrevuNumeric: 'La quantité prévue doit être une valeur numérique',
        firstStartBinsNumeric: 'Le début du premier niveau doit être une valeur numérique',
        firstEndBinsNumeric: 'La fin du premier niveau doit être une valeur numérique',
        secondStartBinsNumeric: 'Le début du second niveau doit être une valeur numérique',
        secondEndBinsNumeric: 'La fin du second niveau doit être une valeur numérique',
        thirdStartBinsNumeric: 'Le début du troisième niveau doit être une valeur numérique',
        thirdEndBinsNumeric: 'La fin du troisième niveau doit être une valeur numérique',
        fourthStartBinsNumeric: 'Le début du quatrième niveau doit être une valeur numérique',
        fourthEndBinsNumeric: 'La fin du quatième niveau doit être une valeur numérique',
        fifthStartBinsNumeric: 'Le début du cinquième niveau doit être une valeur numérique',
        fifthEndBinsNumeric: 'La fin du cinquième niveau doit être une valeur numérique',
        firstBinsNumeric: 'Le premier niveau doit être une valeur numérique',
        secondBinsNumeric: 'Le second niveau doit être une valeur numérique',
        thirdBinsNumeric: 'Le troisième niveau doit être une valeur numérique',
        fourthBinsNumeric: 'Le quatrième niveau doit être une valeur numérique',
        fifthBinsNumeric: 'Le cinquième niveau doit être une valeur numérique',
        rpIsNumeric:'Le niveau de stock minimum RP de {name} doit être une valeur numérique',
        pslIsNumeric:'Le niveau de stock optimal PSL de {name} doit être une valeur numérique',
        quantiteNumeric:'La quantité doit être une valeur numérique',
        rpNumeric:'La niveau de stock minimum doit être une valeur numérique',
        pslNumeric:'La niveau de stock optimal doit être une valeur numérique',
        toMaxNumeric:'Le nombre maximum d\'instructions pour un ordre de transfert doit être une valeur numérique',
        giftCardNumeric:'La durée de validité d\'un dépôt sur une carte cadeau doit être une valeur numérique',
        giftCardMontantNumeric: 'Le montant de la carte cadeau doit être une valeur numérique',
        paiementNumeric: 'Les indications de paiement doivent être des valeurs numériques',
        modifManualValueNumeric: 'La valeur doit être numérique',
        detailModePaiementNumeric: 'Toutes les valeurs doivent être numériques',
        maxArticleInventaire:'Votre inventaire ne peut pas contenir plus de 500 références',
        maxZoneInventaire:'Votre inventaire ne peut pas contenir plus de 500 zones d\'inventaires',
        //Boolean
        donneeclientBoolean: 'Une erreur inattendue s\'est produite',
        objectifvendeurBoolean: 'Une erreur inattendue s\'est produite',
        objectifcommerceBoolean: 'Une erreur inattendue s\'est produite',
        //General
        unknowError: 'Une erreur inattendue s\'est produite',
        identFailed: 'Les identifiants de connexion sont incorrects',
        //divers
        delivery:'Livraison',
        createDeliveryVoucher:'Créer un bon',
        validationDelivery:'Validation des bons',
        returnNotes:'Bons de retour',
        shipmentVouchers:'Bons d\'expédition',
        poB2bWms:'Commandes client B2B',
        poB2cWms:'Commandes client B2C',
        createListItem:'Créer une liste d\'articles',
        customizeTag:'Personnaliser l\'étiquette',
        generatedByPurchaseOrder:'Généré par un bon de commande',
        billingInvoireGeneratedMsg:'Une facture d\'achat a été générée par ce bon de commande',
        selectAPrinter:'Sélectionner une imprimante',
        printerNameRegistered:'Nom de l\'imprimante enregistrée dans Windows',
        image_file:'Fichier image (Windows)',
        defaultCustomer:'Client par défaut',
        noFolderLinked:'Aucun dossier lié',
        convertPoBillinBuyErr:'Ce bon de commande ne peut pas être converti car il contient plusieurs devises',
        convertIntoBillingBuy:'Convertir en facture d\'achat',
        dont:'Dont',
        last_pr:'Dernier prix de revient',
        last_date_buy:'Dernière date d\'achat',
        ecotaxe:'Eco-participation',
        quotaIsOverArticleQuantity:'La quantité indiquée est supérieure à la quantité attendue',
        qteToReceive:'Quantitée à réceptionner',
        docCompletelyReceived:'Document entièrement réceptionné',
        noMoreItemReceive:'Il n\'y a plus d\'articles à réceptionner',
        noReturnNote:'Aucun bon de retour',
        receiptReturnNote:'Réception des bons de retour',
        returnNote:'Bon de retour',
        addToBoxMsg:'Voulez-vous intégrer la sélection à un carton ?',
        poB2b:'Bon de commande client',
        collectPoClient:'Collecte des bons de commande client',
        cancelFiltre:'Annuler le filtre',
        showOnlyLineToBeConverted:'Afficher uniquement les lignes à convertir',
        allItemsConverted:'Tous les articles ont été convertis',
        cancelledDocu:'Document annulé',
        documentClosedAt:'Document finalisé le',
        confirmCancelpo:'Êtes-vous sur de vouloir annuler ce bon de commande ?',
        cancelDocument:'Annuler {document}',
        quantityAlreadyPo:'Quantité convertie',
        quantityToConvert:'Quantité à convertir',
        convertToPo:'Convertir en bon de commande',
        convertToCreditNote:'Convertir en avoir',
        convertToDeliveryNote:'Convertir en bon de livraison',
        deliveryNote:'Bon de livraison',
        validateDeliveryNote:'Valider le bon de livraison',
        convertToReturnOrder:'Convertir en bon de retour',
        mustHave1Bouton:'Le balise de bouton de téléchargement est obligatoire ([bouton]) et ne peut être présent qu\'une seule fois',
        linkToDouaneCat:'Lier à une catégorie douanière',
        noSupplierProvided:'Aucun fournisseur renseigné',
        noCurrencyProvided:'Aucune devise renseignée',
        exchangeRate:'Taux de change',
        indiceCalcul:'Calcul des indices prmp',
        categorie_douane:'Catégorie douanière',
        confirmCancelFactureAchat:'Êtes-vous sur de vouloir annuler cette facture ?',
        billingBuy:'Facture d\'achat',
        mustDeletePaymentBeforeCancel:'Vous devez soit annuler l’ensemble des règlements ou compléter les règlements manquants avant d\'annuler la facture',
        linkToFolder:'Associé au dossier',
        linkTo:'Associé à',
        billingDeposit:'Facture d\'acompte',
        depositLabel:'Acompte',
        error404Msg:'Désolé ! La page que vous recherchez n\'existe pas',
        retourAccuil:'Retour à l\'accueil',
        noEmailAdressConfigured:'Aucune adresse e-mail configurée',
        errDestinataireEmail:'Veuillez inclure "@" dans l\'adresse e-mail',
        addMoreRecipientsMsg:'Ajouter plusieurs destinataires en les séparant d\'une ","',
        documentAlreadyUseForPaiement:'Ce document a déjà été utilisé pour le règlement d\'une autre facture',
        montantIsOver:'Le montant indiqué est supérieur au restant du solde à payer',
        changeFolder:'Changer de dossier',
        allInfosCorrect:'Toutes les informations sont elles correctes ?',
        allDocuments:'Tous les documents',
        noPriorityPricing:'Aucun prix prioritaire',
        priorityPricingCat:'Catégorie tarifaire prioritaire',
        noB2cPrice:'Aucun prix B2C renseigné pour cet article',
        searchForExistingFolder:'Rechercher un dossier existant',
        folderNotExist: 'Le dossier n°{numeroDossier} n\'existe pas',
        folder:'Dossier',
        linkToOtherCompany:'Lier à une autre compagnie',
        docName:'Nom du dossier',
        linkToDoc:'Lier à un dossier',
        createFolder:'Créer un dossier',
        createDocument:'Créer un document',
        createTemplate:'Créer un modèle',
        editTemplate:'Editer un modèle',
        deleteEmailAccount:"Supprimer le compte e-mail",
        send:'Envoyer',
        sendTestEmail:'Envoyer un e-mail de test',
        addEmailAccount:'Ajouter un compte e-mail',
        emailAccount:'Comptes Email',
        emailLayout:'Modèles Email',
        miseEnPage:'Mise en page',
        balisesPossibles:'Balises possibles',
        newEmailAccount:'Nouveau compte email',
        accountName:'Nom du compte',
        emailAdress:'Adresse e-mail',
        hostOutgoing:'Serveur mail sortant',
        username:'Nom d\'utilisateur',
        allFieldRequiredForEnumere:'Tous les champs de l\'énuméré de conditionnement doivent être renseignés (nom, code-barre, quantité)',
        price:'Prix',
        prixAchat:'Prix d\'achat',
        addAdditionalPrice:'Ajouter un prix supplémentaire',
        portrait:'Portrait',
        landscape:'Paysage',
        generateExport:'Générer un export',
        referedToQuotation:'En référence au devis n°',
        referedToDocument:'En référence au document n°',
        parentToDocument:'Parent des documents',
        childToDocument:'Enfant des documents',
        priceTtcB2c:'Prix TTC B2C',
        priceTtcB2b:'Prix TTC B2B',
        priceHtB2b:'Prix HT B2B',
        priceHtB2c:'Prix HT B2C',
        margeB2b:'Marge Brute B2B',
        margePB2b:'Marge % B2B',
        margeB2c:'Marge Brute B2C',
        margePB2c:'Marge % B2C',
        b2bPrice:'Prix B2B',
        b2cPrice:'Prix B2C',
        mustIndicateAPrice:'Vous devez indiquer au moins un prix',
        additionalPriceName:'Nom du prix supplémentaire',
        additionalPrice:'Prix supplémentaire',
        includedTaxe:'Taxes comprises',
        useB2bPrice:'Utiliser le prix B2B',
        useB2cPrice:'Utiliser le prix B2C',
        itemPrice:'Prix article',
        productPriceMaxNumber:'Nombre maximum de prix supplémentaire pour un article',
        noUserRestricted:'Aucun utilisateurs n\'est restreint',
        unauthorized:'Non autorisé',
        fieldReading:'Lecture du champ',
        fieldEditing:'Edition du champ',
        fieldReadingNotAvl:'Lecture non disponible à',
        fieldEditingNotAvl:'Edition non disponible à',
        designation:'Désignation',
        accessMenu:'Access',
        stockInZoneNotSufficient:'Le stock de votre zone ou bins n\'est pas suffisant pour effectuer votre action',
        padFormat:'Format du PAD',
        smallPad:'Petit PAD',
        bigPad:'Grand PAD',
        seuilMiniCashBack:'Seuil minimum d\'utilisation du cashback',
        reliquatCashBack:'Obligation d\'utiliser tout son cashback',
        hostname:'Nom de l\'hôte',
        noHostnameAssigned:'Aucun nom d\'hôte attribué',
        clientAlreadyAssigned:'Ce client à soit déjà été associé ou est en cours d\'association',
        relatedCompanies:'Entreprises concernées',
        poids:'Poids',
        companyOrOtherCompany:'Entreprise ou autre entreprise',
        noAssignablePromo:'Aucune promotions attribuables',
        noAssignedPromo:'Aucune promotions attribuées',
        assignablePromo:'Promotions attribuables',
        assignedPromo:'Promotions attribuées',
        assignPromoClient:'Attribuer des promotions',
        addCustomer:'Ajouter des clients',
        allModificationWillBeLost:'Toutes les modifications seront perdus, continuer ?',
        allCustomer:'Tous les clients',
        listOfCustomer:'Liste de client',
        selectionOfCustomer:'Sélection de client',
        selectionTemplate:'Selectionner un modèle',
        voucherManagement:'Gestion des bons',
        itemListWillBeDelete:'Votre sélection d\'articles sera supprimé, voulez-vous continuer ?',
        convertIntoToCollected:'Ordre de transfert déjà collecté',
        convertThisList:'Convertir cette liste',
        quantityManualModificationListeArticle:'Modification manuelle des quantitées sur les listes d\'articles',
        noList:'Aucune liste',
        listName:'Nom de la liste',
        listOfItems:'Liste d\'articles',
        packaging:'Conditionnement',
        packagedQuantity:'Quantité conditionnée',
        packagingName:'Nom du conditionnement',
        enumere:'Enuméré de conditionnement',
        packagingList:'Enuméré de conditionnement',
        pricingHt:'Tarification hors taxes',
        nbSales:'Nombre de ventes',
        qteSold:'Quantité vendues',
        salesStatistics:'Statistiques de vente',
        copy:'Copier',
        copied:'Copié',
        infoZoneAffectedByRestock:'Par défaut, freebird utilise toutes les zones de stockage si aucune configuration existe',
        zoneAffectedByARestock:'Zone concernée par un réassort interne',
        triggerThresholdInternalRestock:'Seuil de déclenchement d\'un réassort interne',
        quantityToRestock:'Stock optimal',
        internalRestockException:'Exception de réassort interne',
        noZoneOfStock:'Aucune zone de stock',
        rppslMenuName:'RP/PSL',
        emptyValue:'Valeur vide',
        reopenQuotation:'Réouvrir devis',
        devisValidite:'Durée de validité du devis',
        quotationGenerated:'Votre devis a été généré',
        continueWithoutIdentification:'Continuer sans s\'identifier',
        expired:'Expiré',
        notAvailable:'Non disponible',
        infosOnReceipt:'Infos à afficher sur le ticket',
        infosOnTopReceipt:'Infos à afficher sur le haut du ticket',
        infosOnBottomReceipt:'Texte à afficher sur le bas du ticket',
        listedUserWillBeNotified:'Les utilisateurs listés seront notifiés',
        cancellationOfInvoice:'Annulation de la facture n°',
        cancelledByCreditNote:'Annulée par l\'avoir n°',
        assignCountZeroEmptyBins:'Attribuer un comptage à zéro à l\'ensembe des bins restantes',
        confirmAssignCountZeroEmptyBins:'Souhaitez-vous vraiment attribuer un compte à zéro à l\'ensembe des bins restantes',
        confirmDeleteTemplate:'Souhaitez-vous vraiment supprimer ce modèl?',
        assignPointsManually:'Attribuer des point manuellement',
        unauthorizedMovement:'Le transfert de stock sur cette zone n\'est pas autorisé',
        nbPointsToAssign:'Nombre de points à attribuer',
        reminder:'Rappel',
        reminders:'Rappels',
        a:'A',
        list:'Liste',
        title:'Titre',
        addSettle:'Acquitter le document',
        addLimitDate:'Ajouter une date limite de paiement',
        addLimitDateValidity:'Ajouter une date limite de validité',
        addFund:'Ajouter des fonds sur la carte cadeau',
        addUsers:'Ajouter des utilisateurs',
        addItems:'Ajouter des articles',
        addingItemsToPromo:"Ajout d'articles à la promotion",
        addTopText:'Ajout de texte sur la partie supérieure',
        addBottomText:'Ajout de texte sur la partie inférieure',
        specifyASeller:'Indiquer un vendeur',
        message:'Message',
        retraitZone:'Zone de retrait',
        transferAllProducts:'Transferer tous les produits',
        toMomentarilyFiniched:'La collecte de cet ordre de transfert est momentanément terminée',
        toFiniched:'La collecte de cet ordre de transfert est terminée',
        collectOrderFinish:'La collecte des articles pour cette commande est terminée',
        toReceiptMomentarilyFiniched:'La réception de cet ordre de transfert est momentanément terminée',
        toReceiptFiniched:'La réception de cet ordre de transfert est terminée',
        toItemCollectMessage:'Cet article n\'a soit pas de stock soit a été totalement collecté',
        assignedTo:'Attribué à',
        finalize:'Finaliser',
        finalized:'Finalisé',
        getToItemsSelection:'Accéder à la sélection des articles',
        selectCustomer:'Sélectionner un client',
        orderedInThisShop:'Commandé dans ce magasin',
        toBeCollectedInThisShop:'A collecter dans ce magasin',
        informPaymentLate:'Informer le client des conséquences d\'un retard de paiement',
        toCollect:'A collecter',
        toCollectWms:'A collecter depuis votre WMS',
        enCoursTo:'Faisant l\'objet d\'un ordre de transfert',
        necessaryActionOrder:'Une action manuelle est nécessaire pour finaliser la collecte de cet article',
        createATransferOrder:'Créer un ordre de transfert',
        generateAnTransferOrder:'Générer un ordre de transfert',
        dontIndicate:'Ne pas indiquer',
        itemManagement:'Gestion des articles',
        autoCreationItemInfos:'Création automatique des classifications, familles, sous-familles, catégories, sous-catégorie, marques et fournisseurs',
        skuObligation:'Obligation de créer un SKU',
        barCodeObligation:'Obligation de créer un code-barre',
        temporary:'Provisoire',
        ecomTemporaryMsg:'Cet article ne peut être mis en ligne car il est actuellement provisoire',
        showOnlyTemporaryItem:'Afficher uniquement les articles provisoires',
        temporaryItem:'Article provisoire',
        addThisItemToItemBase:'Ajouter définitivement cet article à la base article',
        hour:'Heure',
        ordered:'Commandé',
        errorDifferenceQteValidate:'Il existe un écart de valeur sur les articles suivants',
        paiementFideliteButNoFideliteArticle:"Le paiement par fidélité n'est pas accepté dans ce panier",
        closeWithDifference:'Le compte de votre caisse ne semble pas correct. Souhaitez-vous tout de même clôturer en l\'état ?',
        onReceipt:'Sur ticket',
        articleNoReferenceFind: 'Aucun article ne correspond à cette référence',
        mustIndicateReasonRetrait: 'Vous devez indiquer la raison pour laquelle vous retirez de l\'espèces',
        gradeHasUser: 'Vous ne pouvez pas supprimer ce grade car certains membres du staff ont actuelement ce grade. Pour supprimer ce grade, vous devez d\'abord retirer ce grade pour tous les membres du staff pour qui ce grade est attribué.',
        modepaiementRemove: 'Vous ne pouvez pas supprimer ce mode de paiement car il est attribué à certains de vos commerces. Si vous souhaitez vraiment le supprimer, vous devez d\'abord le retirer des commerces pour lesquels ce mode de paiement est attribué.',
        adminOverQuota: 'Vous ne pouvez pas ajouter le module "Configuration" à cet utilisateur car votre quota d\'utilisateurs pour ce module est déjà atteint. Merci de contacter Ciweb si vous souhaitez modifier ce quota.',
        erpOverQuota: 'Vous ne pouvez pas ajouter le module "ERP" à cet utilisateur car votre quota d\'utilisateurs pour ce module est déjà atteint. Merci de contacter Ciweb si vous souhaitez modifier ce quota.',
        usersOverQuota: 'Vous ne pouvez pas ajouter le module "Utilisateurs" à cet utilisateur car votre quota d\'utilisateurs pour ce module est déjà atteint. Merci de contacter Ciweb si vous souhaitez modifier ce quota.',
        wmsOverQuota: 'Vous ne pouvez pas ajouter le module "WMS" à cet utilisateur car votre quota d\'utilisateurs pour ce module est déjà atteint. Merci de contacter Ciweb si vous souhaitez modifier ce quota.',
        posOverQuota: 'Vous ne pouvez pas ajouter le module "POS" à cet utilisateur car votre quota d\'utilisateurs pour ce module est déjà atteint. Merci de contacter Ciweb si vous souhaitez modifier ce quota.',
        endUnderrBegin: 'La date de fin de période ne peut pas être inférieur à celle du début de période',
        perdioExceedAYear: 'La période ne peut pas être supérieur à une année',
        dayDateFormat: 'La date de cette ligne du fichier CSV n\'est pas dans un format correct. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        commandeDateFormat: 'La date de commande n\'est pas dans un format correct. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        expectedDateFormat: 'La date de transfert n\'est pas dans un format correct. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        expeditionDateFormat: 'La date attendue d\'expédition n\'est pas dans un format correct. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        realExpeditionDateFormat: 'La date réelle d\'expédition n\'est pas dans un format correct. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        receptionDateFormat: 'La date attendue de réception n\'est pas dans un format correct. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        beginDateFormat: 'La date de commencement n\'est pas dans un format correct. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        endDateFormat: 'La date de fin n\'est pas dans un format correct. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        dateDepartDateFormat: 'La date de départ n\'est pas dans un format correct. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        dateArriveDateFormat: 'La date d\'arrivage n\'est pas dans un format correct. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        dateFormat: 'La date n\'est pas au bon format. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        beginRequiredWithEnd: 'Vous devez indiquer une date de commencement puisque vous avez indiqué une date de fin',
        endRequiredWithBegin: 'Vous devez indiquer une date de fin puisque vous avez indiqué une date de commencement',
        sizeLineCsv: 'Cette ligne du fichier CSV ne contient pas le nombre correct de colonnes',
        row: 'Ligne',
        lines: 'Lignes',
        noRhOrNoAuth: 'Staff inexistant ou non autorisé à accéder à cette section',
        staffNotAttributeToInventory:'Ce staff n\'est pas attribué à cet inventaire',
        noRhOrNoAttributed: 'Staff inexistant ou non attribué à cet inventaire',
        noRhTos: 'Vous n\'avez pas d\'ordres de transfert en attente',
        noInternetTos: 'Vous n\'avez pas de commande internet en attente',
        imageUrl: 'Le lien image article doit être une URL valide',
        havestockIn: 'Vous devez indiquer si cet article doit être dépendant d\'un stock',
        zoneAlreadyHaveCommercial: 'Ce commerce a déjà une zone de stock de type lieu de vente',
        zoneAlreadyHaveCommande: 'Ce commerce a déjà une zone de stock de type commande',
        archiveZoneAddArticle: 'Cette zone de stock contient des articles et ne peut donc pas être archivée',
        deleteZoneAddArticle: 'Cette zone de stock contient des articles et ne peut donc pas être supprimée',
        customFieldsAlreadyExists: 'Un champ personalisé avec le même nom exists déjà',
        youMustIndicateField: 'Vous devez renseigner le champ',
        ecomMustBeConfigured:'Ce e-commerce n\'est pas configuré',
        limitCsv: 'Le fichier CSV doit contenir moins de {limit} lignes',
        filename: 'Nom du fichier',
        name:'Nom',
        description:'Description',
        categorization:'Catégorisation',
        ptype:'Type de produit',
        pcat:'Catégorie de produit',
        image:'Image',
        couleur:'Couleur',
        taille:'Taille',
        notANameForCustomFields: 'Ce nom ne peut pas être donné à un champ personnalisé',
        quotaCommandeInteger: 'La quantité doit être un nombre entier',
        quotaInteger: 'La quantité doit être un nombre entier',
        selectBinsOrZone: 'Vous devez scanner une bins ou sélectionner une zone',
        shipmentLineAlreadyReceptione:'Ce bloc a déjà été validé',
        changHaveBinsMustEqualZero:'Vous ne pouvez pas modifier l\'option bins pour une zone qui contient déjà des articles. Cette zone contient actuellement: {quota} articles',
        toIsClosed:'Cet ordre de transfert a été clôturé',
        rhNotInSection:'Ce staff n\'existe pas ou ne travaille pas dans le commerce d\'origine',
        communToLineNotTheSame:'Certaines données qui doivent être communes à toutes les lignes d\'un même ordre de transfère ne le sont pas',
        noDoubleArticleTo:'Cet article est déjà présent dans dans une autre ligne de cet ordre de transfert',
        noAutorizedUpdateTo:'Vous ne pouvez pas mettre à jour un ordre de transfert qui a passé le stade de collecte',
        cantDeleteLine: 'Vous ne pouvez pas supprimer cette ligne',
        pslGte: 'Le niveau de stock optimal ne peut pas être inférieur au niveau de stock minimum',
        noDockForReassort: 'Vous n\'avez aucun dock défini',
        noArticleForReassort: 'Il n\'y a aucun article dans votre sélection',
        noToMaxForReassort: 'Vous devez d\'abord définir le nombre maximum d\'instructions que peut contenir un ordre de transfert dans les paramètres de votre entreprise',
        valuePromotionNotOk: 'Les valeurs de promotion sont incorrectes',
        amountOverDue:'Le montant payé ne peut être supérieur au restant dû',
        resteIsOverTicket: 'Le montant payé est supérieur au restant à payer du ticket',
        majorPaiementError: 'Erreur de paiement majeure, le client a payé plus que le prix demandé',
        paiementIsNegative: 'Le montant à payer ne peut pas être négatif',
        paiementIsPositive: 'Le restant dû doit être à 0',
        noCommercialZone:'Aucune zone commerciale n\'est définie pour ce commerce',
        noGiftCardFound: 'Aucune carte cadeau ne correspond à cette référence',
        noFactureFound: 'Aucun document trouvé',
        noTicketFound: 'Aucun ticket ne correspond à cette référence',
        noCommandeFound: 'Aucune commande ne correspond à cette référence',
        noPossibilityToReturnArticle:'Une erreur empêche le retour de l\'article',
        articleAlreadyReturn: 'Cet article a déjà été retourné',
        hostIp:'Ceci n\'est pas une adresse IP valide',
        optional:'Optionnel',
        orderTitle:'Commande',
        sectionDontHaveCommandeZone: 'Les sections concernées par cette commande ne possèdent pas de zone de commande',
        commandeAlreayCancel: 'Cette commande client a déjà été annulée',
        mustSpecifyRemboursement: 'Vous devez spécifier le mode de remboursement',
        mustSpecifyGiftCardReference: 'Vous devez indiquer le code-barres de la carte cadeau',
        mustChooseOneRaccourci: 'Vous devez choisir un raccourci',
        someItemsNotPresentInPo: 'Certains articles ne sont pas présents dans le bon de commande associé',
        thisItemsNotPresentInPo: 'Cet article n\'est pas présents dans le bon de commande associé ({name})',
        lineCannotBeDelated: 'Cette ligne ne peut pas être supprimée',
        thisArticleNotLinkToStock: 'Cet article n\'est pas lié à un stock',
        articleIsNotInPo:'Cet article n\'est pas présent dans ce bon de commande',
        articleIsNotInTo:'Cet article n\'est pas présent dans cet ordre de transfert',
        articleIsNotInRestock:'Cet article n\'est pas présent dans ce réassort interne',
        toQuantityCantBeEdit: 'Le status de cet ordre de transfert ne vous permet d\'éditer les quantités à collecter',
        tooManyOrTooLongTags:'Il y a trop de tags (250 max) ou l\'un d\'entre eux est trop long (255 caractères max)',
        ecommerceSendError:'Le ecommerce renvoie l\'erreur {erreur}',
        variantAreTheSame:'Les articles enfants ne sont pas détaillées correctements et sont donc interprétés comme étant les mêmes articles par Shopify. Vérifiez les options que vous avez indiqué.',
        noUserToSendAlert:'Aucun utilisateur n\'est configuré pour recevoir ce type d\'alerte',
        noUser:'Aucun utilisateur n\'a été attribué',
        indicateBinsInZoneThatHaveNoBins: 'Vous avez indiquer une bins pour une zone qui n\'en contient pas',
        binsOrZone:'Vous devez indiquer une bins ou une zone',
        reassortInterneAlreadyOpen:'Il y a déjà un reassort interne ouvert. Merci de le fermer avant d\'en générer un nouveau',
        negativeShopStockAndNoAutoPossibilities:'Les articles suivants ont un stock négatif dans votre zone de commerce. Vous devez d\'abord reajuster leur stock avant de pouvoir générer un réassort.',
        reassortCantDeleteAlreadyMove:'Vous ne pouvez pas supprimer une ligne qui a déjà commencé à être collectée',
        clientNotMatchWithDossier:'Le client ne correspond pas à ce dossier',
        /*************Modules name**************/
        moduleadmin:'Configuration',
        moduleerp:'ERP',
        modulewms:'WMS',
        modulepos:'POS',
        moduleusers:'Utilisateurs',
        moduleinvoice:'Facturation',
        modulerhtime:'Gestion du temps de travail',
        /*********divers***********/
        addDiscountSelection:'Appliquer une remise sur la sélection', 
        addDiscountAll:'Appliquer une remise sur l\'ensemble des articles', 
        documentCantBeDeleteBecauseLinesAlreadyProcessed:'Le document ne peut pas être annulé car certaines lignes de ce document ont déjà été traitées',
        convert:'Convertir',
        chat:'Message',
        thisLine:'Cette ligne',
        manualCounting:'Comptage manuel',
        cetteLigne:'cette ligne',
        emisLe:'Emis le',
        acquittee:'Acquittée',
        cancelInvoice:'Annuler cette facture',
        acquitterFacture:'Acquitter cette facture',
        generateNewFrom:'Générer un nouveau document depuis celui-ci',
        sendDocEmail:'Envoyer ce document par e-mail',
        defaultMail:'Bonjour,<br /><br />Cliquez sur le bouton ci-dessous pour télécharger votre [type]<br /><br />[bouton]<br /><br />Cordialement,<br />',
        saveDocTemplate:'Enregistrer ce document comme modèle',
        createReminderFromDoc:'Programmer un rappel pour ce document',
        editReminderDoc:'Modifier le rappel pour ce document',
        convertToInvoice:'Convertir en facture',
        notConvertToInvoice:'Ne pas convertir en facture',
        allLocalisations:'Toutes localisations',
        filterTheSelection:'Filtrer la sélection',
        filterBysection:'Filtrer par commerce',
        supplierOrder:'Commande fournisseur',
        yes: 'Oui',
        no: 'Non',
        signIn: 'Connexion',
        password: 'Mot de passe',
        newPassword: 'Nouveau mot de passe',
        signOut: 'Deconnexion',
        administration: 'Configuration',
        users: 'Licences',
        gestionStockCommandes: 'ERP',
        shops: 'Commerces',
        shop: 'Commerce',
        delete: 'Supprimer',
        remove: 'Retirer',
        update: 'Mettre à jour',
        upload:'Mettre en ligne',
        online:'En ligne',
        optional:'Facultatif',
        offline:'Hors ligne',
        calculationMethod:'Méthode de calcul',
        activeOnPromotion:'Actif sur les promotions',
        forAmountOf:'Pour un montant de',
        perSlice:'Par tranche',
        inProportion:'Au prorata',
        activateOnPromotion:'Activer sur les promotions',
        totalCumulLoyaltyPoint:'Total des points de fidélité cumulés',
        fusionClientInfo:'Le client sélectionné disparaîtra de votre base client et toutes ses informations seront fusionnées avec celle de {clientName}',
        earnedLoyaltyPoint:'Points de fidélité acquis',
        loyaltyPoints:'Points de fidélité',
        confirmDeleteAllPage:'Souhaitez-vous vraiment supprimer toutes les lignes de la page',
        confirmDeleteUser: 'Souhaitez-vous vraiment supprimer l\'utilisateur',
        confirmDeleteGrade: 'Souhaitez-vous vraiment supprimer le grade',
        typeDeleteX: 'Souhaitez-vous vraiment supprimer ',
        typeDeleteReminderX: 'Souhaitez-vous vraiment supprimer le rappel pour ',
        confirmDeleteCheckDeposit: 'Souhaitez-vous vraiment supprimer cette remise de chèque ?',
        typeRemoveX: 'Souhaitez-vous vraiment retirer ',
        confirmArchiveX: 'Souhaitez-vous vraiment archiver ',
        confirmActiveX: 'Souhaitez-vous vraiment activer ',
        confirmDeleteGoals: 'Souhaitez-vous vraiment supprimer les objectifs de cette période ?',
        confirmRemoveRh: 'Souhaitez-vous vraiment retirer',
        confirmOpenFonCaisse: 'Êtes-vous sur de vouloir ouvrir cette caisse avec ',
        confirmAddClientForm: 'Souhaitez-vous vraiment ajouter un formulaire client',
        confirmRemoveClientForm: 'Souhaitez-vous vraiment retirer le formulaire client',
        loyaltyRewardConfig:'Configuration des récompenses de fidélité',
        selectLoyaltyRewardMode:'Sélectionner le mode de récompense de la fidélité clientèle',
        noRewardModeSelected:'Aucun mode de récompense sélectionné',
        cashback:'Cashback',
        availableCashBack:'Cashback disponible',
        toApplyTo:'A appliquer à',
        recompense:'Article offert',
        ofFondCaisse: 'de fond de caisse',
        confirmArchiveX: 'Souhaitez-vous vraiment archiver ',
        confirmUnarchiveX: 'Souhaitez-vous vraiment activer ',
        confirmClockingX: 'Souhaitez-vous vraiment pointer ',
        confirmValidateLine:'Etes-vous sûr de vouloir valider ce bloc',
        confirmValidateAll:'Etes-vous sûr de vouloir tout valider',
        confirmCancelSaleOrder:'Etes-vous sûr de vouloir annuler cette commande',
        warningToCancelSaleOrder:'Des ordres de transfert sont associés à cette commande client, il est fortement recommandé de les annuler',
        confirmClosefondCaisse:'Êtes-vous sûr de vouloir clôturer cette caisse avec ',
        confirmDifferenceFondCaisse:'Êtes-vous sûr de vouloir ouvrir cette caisse avec une différence de ',
        confirmValidateInventory:'Êtes-vous sûr de vouloir valider cet inventaire, cette action sera irréversible',
        clotureDetailPdf:'Détail clôture de caisse',
        returnToItemReceipt:'Retour à la réception des articles',
        associateToAnItem:'Remplacer par un article existant',
        addPermanentlyMsg:'Attention, cette action est irréversible',
        de: 'de',
        exerciseStart:'Début d\exercice',
        ecart:'Écart',
        irreversibleAction: 'Attention: cette action sera irréversible',
        company: 'Entreprise',
        canceled: 'Annulé',
        noLogoActualy: 'Aucun logo actuellement',
        man: 'Homme',
        woman: 'Femme',
        rh: 'Staff',
        modePaiement: 'Mode de paiement',
        loyaltyMode:'Mode de fidélité',
        general: 'Général',
        genere:'Générer',
        proposal:'Propositions',
        newTotalValueStock:'Valeur totale du nouveau stock',
        oldTotalValueStock:'Valeur totale de l\'ancien stock',
        totalValueSaleOrder:'Valeur totale des commandes client',
        totalPaiementSaleOrder:'Valeur totale des acomptes versés',
        tarifSellerModification: 'Modification manuelle d\'un prix',
        width:'Largeur',
        height:'Hauteur',
        openFondCaisse: 'Ouverture Caisse - Fond de caisse',
        fond_caisse:'Fond de caisse',
        closeFondCaisse: 'Clôture de Caisse',
        closeCaisse:'Clôture de caisse',
        repartGoals: 'Répartition objectifs',
        openCaisse: 'Ouverture de caisse',
        openTiroir:'Ouverture du tiroir',
        loyalty:'Fidélité',
        customerLoyalty:'Fidélité client',
        loyaltyPlan:'Plan de fidélité',
        accumulatedPoints:'Points cumulés',
        spentPoints:'Points dépensés',
        open:'Ouvrir',
        opened:'Ouvert',
        closeWith:'Clôturé avec',
        a: 'à',
        allShops:'Tous les commerces',
        allUsers:'Tous les utilisateurs',
        searchArticle: 'Recherche article...',
        searchInWait: 'Recherche en cours',
        cashier: 'Caissier',
        inventory:'Inventaire',
        clickOnZoneWithoutBins:'Cliquez sur une zone sans bins',
        mustCompleteOrCancelReceipt:'Vous devez d\'abord finaliser ou annuler votre ticket en cours',
        inventaireZone:'Inventaire type zone',
        inventaireBins:'Inventaire type bins',
        inventaireCategorie:'Inventaire type categorie',
        finalizedInventory:'Inventaire finalisé',
        inventoryType:'Type d\'inventaire',
        inventaireImport:'Importation d\'inventaire',
        loyaltyPlanImport:'Import fidelité',
        ajustImport:'Ajustement de stock importé',
        returnInventory:'Retour à l\'inventaire',
        returnInventoryHome:'Retour à l\'accueil des inventaires',
        startCounting:'Démarrer le comptage',
        seeInventoryReport:'Voir le bilan de l\'inventaire',
        validateInventory:'Valider l\'inventaire',
        addSelection:'Ajouter la sélection',
        validateCount:'Valider le comptage',
        addToInventoryList:'Ajouter à la liste d\'inventaire',
        cancelCount:'Annuler comptage',
        cancelAllCount:'Annuler tous les comptages',
        cancelComparisonwithStok:'Annuler la comparaison avec le stock',
        allInventories:'Tous les inventaires',
        readInventoryDetail:'Consulter le détail d\'un inventaire',
        compareInventoryToStock:'Comparer l\'inventaire au stock',
        inventoryComparison:'Comparaison de l\'inventaire',
        allCounts:'Tous les comptages',
        selectCancellationOption:'Sélectionner l\'option d\'annulation',
        countNotFinished:'Comptage non terminé',
        wrongCount:'Comptage erroné',
        inventoryBinsMsg:'Le système créera plusieurs inventaires si le nombre de bins de la zone est trop important',
        confirmSecondCount:'Vous avez déjà effectué ce comptage, voulez-vous vraiment en effectuer un nouveau',
        zoneName:'Nom de la zone',
        customerPlacehlder: 'Client...',
        noRhForSection: 'Aucun staff n\'a été attribué pour ce point de vente',
        consultPriceItem: 'Voir prix article...',
        seeSaleOrder:'Voir la commande client',
        selectColumnFileToMatch:'Sélectionner la colonne du fichier à associer',
        selectColumnBaseToMatch:'Sélectionner la colonne de la base article à associer',
        stockArea: 'Zone de Stock',
        createStockArea: 'Créer une Zone de Stock',
        creerGiftCard:'Créer une carte cadeau',
        stockHaveBins: 'Cette zone de stock doit avoir des bins',
        stockZoneEmpty:'Cette zone est vide',
        errorEcomOrderTraitement:'Erreur sur une commande client',
        newEcomOrder:'Nouvelle commande client',
        itemReference: 'Référence article...',
        cashOut: 'Retrait espèces',
        consulter: 'Consulter',
        attribuer: 'Attribuer',
        attribuerA: 'Attribuer à',
        synchronized:'Synchronisé',
        notSynchronized:'Non synchronisé',
        itemMustHaveParentToBeOnline:'Cet article doit avoir un nom de parent pour etre en ligne',
        attribuerSelection: 'Attribuer la sélection',
        generateReassort: 'Générer une proposition de réassort',
        reassortGenerate:'Générer un réassort',
        addToFavorite:'Ajouter aux favoris',
        editFavorite:'Modifier favori',
        listOfFavorite:'Liste des favoris',
        favoriteName:'Nom du favori',
        generateReadjustment:'Générer un réajustement',
        proposalReassortList: 'Proposition de réassort',
        forDepotBanque: 'Ce retrait est pour un dépôt en banque',
        retraitForOtherReason: 'Ce retrait est pour une autre raison',
        retraitOtherLabel: 'Indiquez la raison pour laquelle vous retirez de l\'espèces',
        confirmRetraitOtherReason: 'Souhaitez-vous vraiment retirer de l\'espèces pour un montant de',
        confirmRetraitBanqueReason:' Souhaitez-vous vraiment déposer en banque un montant de',
        confirmDeleteCustomerAssociate: 'Souhaitez-vous vraiment supprimer l\'association à ce client',
        confirmDeleteDivers: 'Souhaitez-vous vraiment supprimer',
        confirmDelete: 'Souhaitez-vous confirmer la suppression',
        confirmGenerateTo:"Êtes-vous sûr de vouloir générer un ordre de transfert depuis ce bon d'expédition",
        successOperation: 'Opération effectuée avec succès',
        successInHistorique: 'Importation lancée avec succès. Vous pouvez suivre l\'état de l\'importation dans votre historique d\'importation.',
        ruleUpDateAlreadyAssigned:'Ce champ a déjà été attribué à une règle de mise à jour',
        addClient: 'Ajouter un client',
        editColumnPdf: 'Edition des colonnes',
        editRecapPdf: 'Edition des lignes recapitulatives',
        addLogo: 'Ajouter un logo',
        cattc:'CA TTC',
        caht:'CA HT',
        taxesExcluded:'HT',
        taxesIncluded:'TTC',
        totalTTC:'Total TTC',
        goalsttc:'Objectifs TTC',
        goalsht:'Objectifs HT',
        grossMargin:'Marge brute',
        grossMarginPercent:'Marge brute en %',
        beginIsGreaterThanEnd:'Le début de période ne peut être supérieur à la fin de la période',
        periodIsGreaterThanAYear:'La période sélectionnée doit être inférieure à un an',
        importGoals: 'Importer des objectifs',
        createGrade: 'Créer un grade',
        createModePaiement: 'Ajouter un mode de paiement',
        createCashOutReason: 'Ajouter un justificatif de retrait d\'espèces en caisse',
        cashOutMore: 'Vos justificatif de retrait d\'espèces en caisse',
        modePaiementForced: 'Modes de paiement basiques',
        modePaiementMore: 'Vos modes de paiements ajoutés',
        items: 'Articles',
        today: 'Aujourd\'hui',
        hier: 'Hier',
        demain: 'Demain',
        noObjectif: 'Il n\'y a aucun objectif POS pour cette période',
        objectifs: 'Objectifs',
        realise: 'Réalisé',
        nbrEntreClient: 'Nombre de passage clients (entrée et sortie)',
        edit: 'Editer',
        period: 'Période',
        oneDay: 'Une journée',
        manyDays: 'Plusieurs jours',
        searchBy:'Rechercher par',
        day: 'Jour',
        de: 'De',
        vers: 'Vers',
        toA: 'À',
        seeAll:'Voir tout',
        seeMore:'Voir plus',
        createNotification:'Créer une notification',
        disponible: 'Disponible',
        addCashOut: 'Retrait d\'espèces',
        noEnougthForReassort: '{name} ne possède pas suffisament de stock pour satisfaire cette modification',
        confirmAddItemPromo: 'Confirmer la sélection de {quantity} articles',
        needEntre: 'Ce graphique sera disponible lorsque le nombre d\'entrée client sera renseigné',
        createGoals: 'Créer des objectifs commerce',
        attributeStaffGoals: 'Attribuer des objectifs au staff',
        deleteGoals: 'Supprimer des objectifs',
        createOneDayGoals: 'Créer les objectifs d\'une journée',
        report:'Reporting',
        datas:'Données',
        dropCsv: 'Déposez le fichier CSV ici ou cliquez pour télécharger.',
        noDataInCsv: 'Aucne donnée n\'a été trouvé dans ce fichier csv',
        fileCsvToLarge: 'Le fichier dépasse la taille maximale autorisée de 7 mégabytes',
        objectifCsvFileToLong: 'Le fichier csv doit avoir au maximum 367 lignes',
        doublonCsv: 'Certaines colones du fichier csv sont utilisées sur plusieurs données différentes',
        indicateRecurrence:'Indiquer une récurrence',
        indicateRecurrenceMsg:'Indiquer le jour du mois pour lequel vous souhaitez que cette récurrence s\'applique pour le modèle {modeleName}',
        deleteRecurrence:'Supprimer la récurrence',
        exonerateFromTaxes:'Exonérer de taxes',
        every:'Tous les',
        ofMonth:' du mois',
        template: 'Modèle',
        recurrence:'Récurrence',
        reprogramRecurrenceOnNextMonth:'Reprogrammer la récurrence pour le mois suivant',
        templateConfiguration: 'Configuration du modèle',
        templateEmailConfiguration: 'Configuration du modèle d\'e-mail',
        emailBodyQuotation:'Corps par défaut de l\'e-mail d\'un devis',
        emailBodyInvoice:'Corps par défaut de l\'e-mail d\'une facture',
        emailBodyCreditNote:'Corps par défaut de l\'e-mail d\'un avoir',
        msgInfoContenuMail:'Les éléments notés entre crochets [] seront récupérés à partir des informations du document . Voici l\'ensemble des éléments que vous pouvez écrire entre crochet:',
        msgInfoContenuMailClient:'sera remplacé par le nom du client',
        msgInfoContenuMailNumero:'sera remplacé par le numéro du document',
        msgInfoContenuMailMontant:'sera remplacé par le montant TTC du document',
        msgInfoContenuClient:'[client]',
        msgInfoContenuNumero:'[numero]',
        msgInfoContenuMontant:'[montant]',
        msgEmailConfigure:'Lorsque vous créez une facture, un devis ou un avoir, il vous est proposé de l\'envoyer directement par e-mail à votre client. Le corps de l\'email qui vous sera proposé pour cette envoi sera celui que vous aurez préalablement indiqué ici.',
        seeMyTemplate:'Voir le modèle',
        importedRows: 'Lignes importées: ',
        infoModePaiement: 'Il s\'agit ici de lister les modes de paiement possibles pour votre entreprise. Attention: chacun de vos commerces peut utiliser des modes de paiements différents. De ce fait, il vous faut donc vous rendre dans les options de chacun de vos commerces pour y indiquer les modes de paiements qui y sont acceptés.',
        restrictionStaff: 'Restrictions Staff',
        reassortWait:'Proposition de réassort en cours de calcul. Cette tache peut prendre quelques minutes...',
        inventoryCompareWait:'Comparaison de l\'inventaire avec le stock en cours de calcul. Cette tache peut prendre quelques minutes...',
        inventoryValidateWait:'L\'inventaire est en cours de validation. Cette tache peut prendre quelques minutes...',
        waitReassortTo:'Ordre(s) de transfert(s) en cours de création. Vous allez être bientôt redirigé. Cette tâche peut prendre quelques minutes...',
        authorized: 'Autorisés',
        noRestriction: 'Aucune restriction',
        scanBadge: 'Scannez votre badge ici...',
        scanSellerBadge: 'Scannez votre badge de vendeur ici...',
        scanBadgeOrSearch:'Scannez votre badge ici ou recherchez un staff',
        formclient: 'Formulaire client',
        explicationFormClient: 'Indiquez les champs que vous souhaitez faire apparaitre dans le formulaire de création d\'un client',
        noFilter:'Aucun filtre',
        field:'Champ',
        obligatoire:'Obligatoire',
        requiredField: 'Champ obligatoire',
        notRequiredField: 'Champ non obligatoire',
        uniqueField: 'Valeur unique',
        notUniqueField: 'Valeur non unique',
        freeField: 'Valeur libre',
        fieldListData: 'Soumis à une liste de données',
        civilite: 'Civilité',
        activer: 'Activer',
        adressePhysique: 'Adresse Physique',
        adressePostale: 'Adresse Postale',
        communeVille:'Commune/Ville',
        inscriptionNewsletter: 'Inscription Newsletter',
        noFormClient: 'Le formulaire client de votre entreprise n\'est pas configuré',
        customerReward:'Récompenses client',
        homme: 'Homme',
        femme: 'Femme',
        noClientToEdit: 'Vous devez d\'abord indiquer un client',
        noCashierIndicated : 'Vous devez d\'abord indiquer un caissier',
        periodHigherOneMonth:'La période sélectionnée doit être inférieur à 1 mois',
        newItem: 'Nouvel article',
        stamp:'Tampon',
        parameterName: 'Paramètres avancés',
        otherCompany: 'Autres entreprises',
        clientType: 'Type de client',
        quartier: 'Quartier',
        dateDeNaissance: 'Date de naissance',
        selectAType: 'Veuillez choisir un type',
        customField: 'Champ personnalisé',
        customFields: 'Champs personnalisés',
        category: 'Catégories',
        providerName: 'Nom du fournisseur',
        contactName: 'Nom du contact',
        categoryName: 'Nom de la catégorie',
        companyName: 'Nom de l\'entreprise',
        legalForm:'Forme juridique',
        documentType:'Type de document',
        draftListMsgInfo:'Seuls les brouillons de moins de 48 heures sont encore sauvegardés',
        fieldName: 'Nom du champ',
        defaultFields: 'Champs par défaut',
        items: 'Articles',
        providers: 'Fournisseurs',
        nouveauFournisseur: 'Nouveau fournisseur',
        editFournisseur: 'Editer un fournisseur',
        CDD: 'CDD',
        CDIE: 'CDI - Période d\'essai',
        CDI: 'CDI',
        archive: 'Archiver',
        thisStaffIsArchive: 'Attention: ce staff est archivé',
        includeArchiveStaff: 'Inclure les Staff archivés',
        includeArchiveProvider: 'Inclure les fournisseurs archivés',
        includeArchiveData: 'Inclure les données archivées',
        includeArchivetem: 'Inclure les articles archivés',
        clientAssocie: 'Client Associé',
        clientArchiveAccocie: 'Ce Staff est archivé et ne peut donc pas être associé à un client',
        clientArchiveBadge: 'Ce Staff est archivé et il n\'est donc pas possible de générer un badge',
        deleteCheckDeposit:'Supprimer des remises de chèques',
        archive: 'Archiver',
        downloadCSV: 'Exporter en csv',
        downloadZone:'Exporter le contenu de la zone',
        downloadBins:'Exporter le contenu de la bins',
        downloadQuickCSVSelection: 'Exporter une version rapide des lignes sélectionnées en csv',
        downloadCSVSelection: 'Exporter uniquement les colonnes sélectionnées en csv',
        downloadCSVPeriod: 'Exporter la période sélectionnée en csv',
        downloadCSVThisSelection: 'Exporter la selection en csv',
        downloadCSVThisSalesSelection: 'Exporter les ventes de la selection en csv',
        downloadCSVThisTicketsSelection: 'Exporter les tickets de la selection en csv',
        downloadCSVThisDocumentsSelection: 'Exporter les documents de la selection en csv',
        downloadCSVThisPaiementSelection: 'Exporter les moyens de paiement de la sélection en csv',
        downloadCSVAll: 'Exporter toutes les colonnes en csv',
        downloadAllPoCsv:'Exporter tous les bons de commande en csv',
        downloadAllToCsv:'Exporter tous les ordres de transfert en csv',
        downloadAllToWithoutDetailsCsv:'Exporter uniquement les lignes sélectionnées du tableau courant',
        downloadAllShipmentCsv:'Exporter tous les bons d\'expédition en csv',
        downloadAllGiftCardCsv:'Exporter toutes les cartes cadeaux en csv',
        downloadSelectGiftCardCsv:'Exporter uniquement la sélection en csv',
        downloadStockGap:'Exporter les écarts de stock',
        downloadExcel: 'Exporter en excel',
        downloadArticlesCSV: 'Exporter les articles en csv',
        listDonnéesCsv: 'Exporter la liste des données possibles',
        firstlevelcat:'Classification',
        secondlevelcat: 'Famille',
        thirdlevelcat: 'Sous-famille',
        fourthlevelcat:'Categorie',
        fifthlevelcat: 'Sous-categorie',
        imageLink: 'Lien image article',
        categorysingular: 'Catégorie',
        addone: 'Ajouter une',
        addOption:'Ajouter une option',
        tagsMsg:'Vous pouvez cumuler plusieurs tags en les séparant d\'une virgule',
        emailsCumul:'Vous pouvez cumuler plusieurs emails en les séparant d\'une virgule',
        tagsMax:'Vous pouvez cumuler jusqu\'à 250 tags',
        staffIsAssociate: 'Ce Staff est associé au client',
        staffIsNotAssociate: 'Ce Staff n\'est associé à aucun client',
        newbrand: 'Nouvelle marque',
        includeArchiveBrands: 'Inclure les marques archivées',
        formBrand: 'Formulaire marque',
        archivedBrand: 'Cette marque est archivée',
        archivedProvider:'Ce fournisseur est archivé',
        associationStaffCustomer: 'Association d\'un Staff et d\'un client',
        transferOrderPriority:'Priorité de collecte d\'un ordre de transfert',
        transferOrderAssociation:'Association ordres de transfert',
        saleOrderAssociation:'Association commande client',
        noAssociateCustomerResult: 'Il n\'y a pas de possibilités d\'associations trouvées',
        impossibleDeletingFamily: 'La supréssion est impossible !',
        familyContainItems: 'Cette catégorie contient des articles',
        impossibleArchivingFamily: 'L\'archivage est impossible !',
        formItem: 'Formulaire article',
        internalId: 'ID interne',
        expectedInternalId: 'ID interne souhaite',
        fournisseur: 'Fournisseur',
        notConnectedToFreebird:'Non connecté à Freebird',
        otherItemWithSameRefName:'Autres articles avec le même nom de référence',
        marque: 'Marque',
        reference: 'Code UPC',
        reference2: 'Code UPC 2',
        arrowUp: 'Flêche vers le haut',
        arrowDown: 'Flêche vers le bas',
        ecoTaxe: 'Eco-participation',
        barcode: 'Code-barres',
        barcodePackaginglist: 'Code-barres du conditionnement',
        shopCode:'Code du commerce',
        itemName: 'Nom article',
        priceTtc: 'Prix TTC',
        priceHt: 'Prix HT',
        priceHtWithoutDiscount: 'Prix HT non remisé',
        marge: 'Marge',
        margeNet: 'Marge Nette',
        totalMargeNet: 'Total Marge Nette',
        differenceMargeNet: 'Ecart Marge Nette',
        totalBuyPrice:'Total prix d\'achat en devise',
        margePercent: 'Marge %',
        discountPrice: 'Prix promotionnel',
        discountProfil: 'Profil de promotion',
        unitDiscount:'Remise unitaire',
        discountType:'Type remise',
        buy_price: 'Prix achat en devise',
        devise_achat: 'Devise achat',
        stores: 'Commerces',
        tgc: 'Taux TGC',
        downloadBadge: 'Télécharger le badge',
        generateBadge: 'Générer le badge',
        thisItemMustHaveStock: 'Cet article sera dépendant d\'un stock',
        thisItemIsArchived:'Cet article est achivé',
        itemHaveStock: 'Liaison au stock',
        customizationBadge: 'Personnalisation du badge',
        addIdPicture: 'Ajouter une photo sur le badge',
        addGrade: 'Ajouter le grade',
        addShopName: 'Ajouter le nom de l\'entité',
        addLastname: 'Ajouter le nom de famille',
        addShopLogo:'Ajouter le logo du commerce',
        chooseYouCompany: 'Selectionnez l\'entité',
        chooseYourBgColor: 'Choisissez une couleur de fond',
        chooseYourTextEntityColor: 'Choisissez la couleur du texte de l\'entité',
        chooseRefundMethod:'Choisissez le mode de remboursement',
        enterYourCustomColor: 'Ajouter une couleur en hexadécimal',
        addCustomColor: 'Ajouter une couleur de fond personnalisée',
        fieldToUpdate:'Champ à mettre à jour',
        fieldAutoUpdateItemRule:'Tous les articles qui auront ce champ renseigné de la même manière, seront automatiquement mis à jour',
        noUpdateRule:'Aucune règle de mise à jour',
        noEntity: 'Aucune entité',
        red: 'Rouge',
        blue: 'Bleu',
        yellow: 'Jaune',
        pink: 'Rose',
        green: 'Vert',
        black: 'Noir',
        white: 'Blanc',
        pr:'Prix de revient',
        prmp:'PRMP',
        beneficiary: 'Bénéficiaire',
        selectABeneficiary: 'Sélectionner un bénéficiaire',
        muststock: 'Article a un stock',
        mobil: 'Zone de transition',
        collect:'Collecter',
        receive:'Réceptionner',
        chosseMobile: 'Choisir le véhicule de livraison',
        choseReceptionZone:'Choisir la zone de réception',
        receiptPoDefaultZone:'Zone de réception par défaut d\'un bon de commande',
        receiptToDefaultZone:'Zone de réception par défaut d\'un ordre de transfert',
        collectToDefaultZone:'Zone de collecte par défaut d\'un ordre de transfert',
        receiptAndCollectDefaultZone: 'Zone de réception et collecte par défaut',
        choseAReceptionZoneToReceive:'Choisir une zone pour réceptionner l\'ordre de transfert',
        choseAReceptionZoneToCollect:'Choisir une zone pour collecter l\'ordre de transfert',
        choseAReceptionZoneToReceivePo:'Choisir une zone pour réceptionner le bon de commande',
        unauthorizedActionOnZoneBins:'La zone sélectionnée contient des bins, veuillez utiliser votre WMS pour la réception de cet ordre de transfert',
        duplicatePoReliquat:'Dupliquer la ligne en fonction du reliquat',
        duplicatePoReliquatConfirm:'Êtes-vous sûr de vouloir dupliquer la ligne en fonction du reliquat',
        reception: 'Zone de réception',
        commerciale: 'Lieu de vente',
        prive: 'Zone non accéssible à la vente et aux mouvements de stock',
        unpaidInvoice:'Factures non acquittées',
        stockage: 'Zone traditionnelle de stockage',
        commande: 'Stockage des commandes B2C',
        commandeB2b: 'Stockage des commandes B2B',
        zoneArchived: 'Attention : cette zone est archivée',
        importHistory: 'Imports',
        importReport: 'Rapport d\'import',
        articleImport: 'Import d\'articles',
        poImport: 'Import Bon de Commande',
        toImport: 'Import Ordre de Transfère',
        shipmentImport: 'Import Bon d\'Expedition',
        rppslImport: 'Import Réassort automatique',
        enCours: 'En cours',
        open:'Ouvert',
        close:'Fermé',
        on:'Sur',
        dataListpossible: 'Liste possible de données',
        editPersonalField: 'Editer un champ personalisé',
        customFieldCreation:'Création de champ personalisé',
        updateDatasRule:'Règle de mise à jour des données',
        editBrand: 'Editer une marque',
        clocking: 'Pointage',
        errorClocking: 'Badge non attribué pour ce commerce ou incorrect',
        errorPriorityCollect:'Vous ne pouvez pas définir deux fois le même commerce',
        errorNoBinsSelected:'Aucune bins n\'a été sélectionnée ',
        validateProposalReassort: 'Êtes-vous sûr de vouloir convertir cette proposition de réassort en ordre de transfert ?',
        errorClotureSaveTicket:'Veuillez supprimer tous vos tickets sauvegardés avant de clôturer votre caisse',
        nonAttribue: 'Non attribué',
        detailHistory: 'Détails de l\'historique',
        detailArticle: 'Détails de l\'article',
        detailPrice: 'Détails du prix',
        detailItem:'Détails article',
        stockItem:'Stock article',
        stockItemPos:'Stock article',
        negativeStock:'Stock négatif',
        stockParent:'Stock parent',
        colonnes: 'Colonnes',
        rechercheSurValeurUnique: 'Recherche sur valeur unique',
        deleteLineItem:'Supprimer une ligne d\'article',
        removeQuantityOnLineItem:'Retirer une quantité de la ligne d\'article',
        removeDepositPayment:'Supprimer une ligne de paiement',
        filtres: 'Filtres',
        prmpIndice: 'Indice PRMP',
        prIndice: 'Indice prix de revient',
        parent_name: 'Nom du parent',
        itemInfos: 'Informations sur l\'article',
        paymentInfos:'Informations sur le paiement',
        limitDatePay:'Date limite de paiement',
        limitDateValidity:'Date limite de validité',
        detail:'Détail',
        customer: 'Client',
        customerProfil:'Profil du client',
        customerInfos: 'Informations du client',
        requiredInformations:'Informations obligatoires',
        principalInfo: 'Informations principales',
        secondInfo: 'Informations secondaires',
        warningImportInventaire:'Attention, cette fonctionalité écrasera de façon irreversible votre stock précédent par le nouveau stock indiqué dans votre fichier, pour chacun des articles qui s\'y trouvent et dans chacune des zones indiquées. Vous ne devez JAMAIS importer l\'inventaire d\'un article pour une zone, si vous n\'avez pas réaliser son inventaire complet sur l\'ensemble de cette zone.',
        infoImportInventaireAddStock:'Cette fonctionalité ajoutera le stock indiqué dans votre fichier à votre stock actuel, pour chacun des articles qui s\'y trouvent et dans chacune des zones indiquées.',
        itemLinkAndNotLinkToTock: 'Articles liés et non liés au stock',
        itemLinkToTock: 'Articles liés au stock',
        itemNotLinkToTock: 'Articles non liés au stock',
        itemWithAndWitoutStock:'Articles avec et sans stock',
        itemWithStock:'Articles avec du stock',
        itemWWitoutStock:'Articles sans stock',
        diverseInfo: 'Informations diverses',
        devise: 'Devise',
        inDevise: 'En devise',
        migrate: 'Migrer',
        migrateFrom: 'Migrer de',
        vers: 'vers',
        errorMsgMin2:'Votre message doit contenir au minimum 2 caractères',
        errorSelectFamily: 'Vous devez indiquer une destination de migration valide',
        aucune: 'Aucune',
        noShops:'Aucun magasins',
        noSalesOrder:'Aucune commande client',
        justItemsWhoHaveStock: 'Uniquement les articles qui ont du stock',
        impossibleExport: 'Export impossible',
        maxLineAuth: 'Vous pouvez exporter au maximum {limit} lignes d\'un coup. Vous essayez actuellement d\'en exporter {actual}',
        csvWillBeReady: 'Demande d\'export enregistrée. Votre fichier CSV sera disponible sous peu dans votre gestionnaire de téléchargement de fichier CSV',
        inventoryUpdateWillBeReady: 'Mise à jour de l\'inventaire en cours, cela sera effectif d\'ici 5 minutes sur {ecomName}',
        lastExportCsv: 'Derniers exports de fichiers CSV',
        exportDate: 'Date d\'export',
        exportSelectedColumnToCsv:'Exporter les colonnes sectionnées en csv',
        state:'État',
        itemHasNoStock:'Cet article n\'a pas de stock',
        errorExport: 'Une erreur est survenue lors de l\'export',
        errorImport: 'Une erreur est survenue lors de l\'import',
        errorNoStaffSelected:'Aucun staff n\'a été sélectionné',
        attributeSelectionToStaff:'Voulez-vous attribuer dès maintenant votre sélection',
        download: 'Télécharger',
        editArticle: 'Edition d\'un article',
        editClient:'Edition d\'un client',
        havestockInImport: 'Vous devez indiquer si cet article doit être dépendant d\'un stock',
        itemCard: 'Fiche Article',
        defaultConfig: 'Configuration par défaut',
        personalizedConfig: 'Configuration personnalisée',
        addNewSection:'Ajouter une nouvelle rubrique',
        add: 'Ajouter',
        newApiKey:'Nouvelle clé API',
        apiKey:'Clé API',
        msgApiKeyExpire:'Votre clé API va expirer dans moins de 30 jours',
        msgNoApiKey:'Aucune clé API',
        noUserConf: 'Aucune configuration personnalisée',
        createApiKey:'Créer une clé API',
        activeApiKey:'Clé API active',
        createNewApiKey:'Créer une nouvelle clé API',
        msgApiKey:'Voici votre clé API, veillez à la conserver car elle ne réapparaîtra plus',
        msgRecreateApiKey:'Êtes-vous sûr de vouloir regénérer une clé API',
        deleteUserConf: 'Supprimer la configuration personnalisée',
        persoConfigVide: 'Votre configuration personnalisée est vide',
        defautConfigVide: 'Votre configuration par défaut est vide',
        noDefaultConf:'Aucune configuration par défaut',
        noConfAvailable:'Aucune configuration de disponible',
        itemAndZoneDetail:'Détail articles et zones',
        customizeInterface: 'Customiser l\'interface',
        customizationInterface: 'Customisation de l\'interface',
        importArticleAlreadyInProgress:'Un import d\'articles est déjà en cours d\'exécution',
        purchaseOrder:'Bon de commande fournisseur',
        purchaseOrderB2b:'Bon de commande',
        purchaseOrderForm:'Formulaire bon de commande',
        orderDate: 'Date de commande',
        expectedDeliveryDate:'Date attendue d\'expedition',
        requestedDeliveryDate:'Date d\'expédition demandée',
        requestedDeliveryDateNoAccent:'Date expedition demandee',
        realDeliveryDate:'Date reelle d\'expedition',
        estimateDeliveryDate:'Date estimée d\'expedition',
        expectedReceiptDate:'Date attendue de réception au dock',
        expectedReceiptDateInShop:'Date attendue de réception en magasin',
        estimatedDelivery:'Livraison estimee',
        lastSalesOrder:'Dernière commande client',
        lastQuotation:'Dernier devis',
        searchSalesOrder:'Rechercher une commande client',
        searchBins:'Rechercher une bins',
        quantity:'Quantite',
        leave:'Quitter',
        leaveConversation:'Quitter la conversation',
        leaveNotifConfirm:'Êtes-vous sûre de vouloir vous retirer de la conversation',
        quantityTotal:'Quantité totale',
        quantityDisp:'Quantité disponible',
        quantityBloque:'Quantité bloquée',
        quantiteOnPurchase: 'Quantite commande',
        quantiteWait: 'Quantité en attente',
        purchaseOrders:'Bons de commande',
        createPurchaseOrder:'Créer un bon de commande',
        purchaseOrderName:'Nom du bon de commande',
        createdAt:'Date de création',
        lastUpdate:'Dernière mise à jour',
        divers:'Divers',
        createBy: 'Créé par',
        notDelivered: 'Non réceptionné',
        notDeliveredLines: 'les lignes non réceptionnées',
        poCompletelyReceived:'Bon de commande entièrement réceptionné',
        noMoreItemToReceive:'Il n\'y a plus d\'articles à réceptionner dans ce bon de commande',
        completed: 'Finalisé',
        notCompleted: 'Non Finalisé',
        includeAllPurchaseOrders: 'Inclure les bons de commande finalisés',
        editPurchaseOrder: 'Editer le bon de commande',
        importPo: 'Importer du contenu aux Bons de Commande',
        importShipment: 'Importer du contenu aux Bons d\'expéditions',
        importReassort: 'Importer du contenu au Réassort automatique',
        importTo: 'Importer du contenu aux Ordres de Transferts',
        refer:'Nom de référence',
        billingRef:'Référence de la facture',
        quota_recu: 'Quantite recue',
        confirmLogout:'Voulez-vous vraiment changer de staff',
        status:'Statut',
        regenerate:'Regénérer',
        depotBanqueTitle:'Attestation de versement d\'espèces',
        remiseCheckTitle:'Attestation de remise de chèque',
        toAlreadyCompleted: 'Cet ordre de tranfert a déjà été finalisé',
        allItems: 'Tous les articles',
        statusItems:'Statut des articles',
        statusLines:'Statut des lignes',
        itemsTotallyReceived: 'Articles entièrement réceptionnés',
        itemsPartiallyReceived: 'Articles partiellement réceptionnés',
        itemsNotReceived: 'Articles non réceptionnés',
        sku:'SKU',
        january:'Janvier',
        february:'Février',
        march:'Mars',
        april:'Avril',
        may:'Mai',
        june:'Juin',
        july:'Juillet',
        august:'Août',
        september:'Septembre',
        october:'Octobre',
        november:'Novembre',
        december:'Décembre',
        member:'Membres',
        writeNewMessageHere:'Ecrivez votre message ici...',
        relatedRecord: 'Enregistrements liés',
        relatedItems:'Articles concernés',
        addItemsInfoPromo:'Utilisez le filtre pour cibler les articles à ajouter à la promotion',
        redirect:'Rediriger',
        updatePoRecords: 'Mise à jour dans',
        insertPoRecords: 'Ajout dans',
        updateShipmentRecords: 'Mise à jour dans',
        updateInventory:'Mise à jour du stock',
        updateTheInventory:'Mettre à jour le stock',
        insertShipmentRecords: 'Ajout dans',
        entreStockRecords: 'Entrée en stock',
        voirLe: 'Voir',
        saleOrderCanceled:'Cette commande client a été annulée',
        saleOrderFinish:'Cette commande client a été finalisée',
        saleOrderFinishTicket:'Cette commande a été finalisée sous le numéro de facture: ',
        itemsDetail:'Détail article',
        registrationDate:'Date d\'enregistrement',
        shipmentVoucher: 'Bon d\'expédition',
        shipment: 'Bon d\'expédition',
        transferOrder: 'Ordre de transfert',
        transferOrders: 'Ordres de transfert',
        shipmentVoucherAccent: 'Bon expedition',
        entre: 'Entrée',
        expected:'Prévu',
        numero_shipment: 'Bon d\'expédition',
        numero_container_shipment: 'Numéro du conteneur',
        numero_container_shipment_accent: 'Numero du conteneur',
        date_depart_shipment:'Date de départ',
        date_depart_shipment_accent:'Date depart',
        date_arrive_shipment:'Date d\'arrivage',
        date_alert:'Date de rappel',
        date_arrive_shipment_accent:'Date arrivage',
        status_shipment:'Statut',
        createShipmentVoucher: 'Créer un bon d\'expédition',
        manualEntry:'Saisie manuelle',
        inventoryZone:'Zone d\'inventaire',
        inventoryOption:'Option d\'inventaire',
        inventoryZoneList:'Liste des zones d\'inventaire',
        countFromInventory:'Comptage de l\'inventaire',
        cours_chargement: 'En cours de chargement',
        alert_texte:'Texte de rappel',
        cancelReminder:'Effacer le rappel',
        warehouse:'Dock',
        mer: 'En mer',
        douane: 'En douane',
        dock: 'En cours de réception',
        receptione:'Réceptionné',
        receipt:'Réception',
        receptioneLines:'lignes réceptionnées',
        includeAllShipmentVoucher: 'Inclure tous les bons d\'expédition',
        exceptReceivedVoucher: 'hors réceptionnés',
        depositDate:'Date de dépôt',
        only:'Uniquement',
        customCreateForm: 'Personnaliser le formulaire de création',
        customVoucherDetails: 'Personnaliser les details du bon',
        PurchaseOrderCard: 'Fiche bon de commande',
        shipmentVoucherCard:'Fiche bon d\'expédition',
        shipmentVoucherForm:'Formulaire bon d\'expédition',
        toCustomForm:'Formulaire d\'ordre de transfert',
        shde_carton_name:'Reference carton',
        shde_quota_prevu:'Quantité prévue en carton',
        shde_quota_prevu_accent:'Quantite prevue en carton',
        shde_quota_recu:'Quantité reçue',
        shde_carton_recu:'Carton receptionne',
        shde_carton_recu_accent:'Carton receptione',
        receiptShipmentVoucher:'Réception des bons d\'expédition',
        receiptPo:'Réception des bons de commande fournisseur',
        scanBoxHere:'Scanner un carton ici',
        scanBoxToCheckHere:'Scanner un carton ici',
        scanItems:'Scanner un article ici',
        boxeChoice:'Choix du carton',
        itemChoice:'Choix de l\'article',
        drawerName:'Nom du tireur',
        priorityBox:'Carton prioritaire',
        french:'Français',
        english:'Anglais',
        po: 'Bon de commande fournisseur',
        to: 'Ordre de transferts',
        totalPrmp: 'Total PRMP',
        totalPr: 'Total prix de revient',
        allBoxes: 'Tous les cartons',
        allSalesOrder:'Toute les commandes client',
        allLines: 'Toutes les lignes',
        allInvoices:'Toutes les factures',
        allCompanies:'Toutes les entreprises',
        onlyUnpaidInvoices:'Seulement les factures non acquittées',
        boxesStatus: 'Statut des cartons',
        assignAWms: 'Attribuer à un WMS',
        assignAShop: 'Attribuer à un commerce',
        noWmsAttribution: 'Aucune attribution à un WMS',
        noShipmentVoucher: 'Aucun bon d\'expédition',
        noPurchaseOrder: 'Aucun bon de commande',
        noReassortInterne: 'Aucun reassort interne en cours',
        editShipmentVoucher: 'Editer le bon d\'expédition',
        editTransferOrder: 'Editer l\'ordre de transfert',
        receivedBy:'Receptionne par',
        of:'sur',
        poLineAlreadyReceptione:'Ce bloc a déjà été réceptionné',
        ZoneWithBins:'Aucune bins n\'a été renseignée pour cette zone',
        someItemsNotPresentInPo:'Certains des articles réceptionnés ne font pas partie du bon de commande',
        unauthorizedDivision:'La ligne que vous souhaitez dupliquer ne peut pas être divisée par le reliquat',
        cannotOpenToBecauseAllClose: 'Vous ne pouvait rouvrir cet ordre de transfert car toutes les actions y sont achevées',
        cannotEditToBecauseIsClose: 'Vous ne pouvez pas éditer cet ordre de transfert car il est fermé',
        errorScan:'Le carton {barcode} n\'existe pas',
        successScan:'Le carton {barcode} a été scanné avec succès',
        reinstateScan:'Le carton {barcode} a déjà été scanné, voulez vous le réintégrer aux cartons non scannés ?',
        successReinstate:'Le carton {barcode} a été réintégrer avec succès',
        checkingBoxes:'Réception des cartons',
        checkingItems:'Réception des articles',
        itemsReceipt:'Réception des articles',
        itemsCollect:'Collecte des article',
        notScannedYet:'Ce carton n\'a pas encore été scanné, voulez-vous être redirigé sur la page de réception des cartons ?',
        quantityCommande:'Quantité commandée',
        createStorageSpace:'Créer des bins en masse',
        binsList:'Liste de Bins',
        createOneBins:'Créer une bins',
        beginningFirstLevel:'Début du premier niveau',
        endFirstLevel:'Fin du premier niveau',
        beginningSecondLevel:'Début du second niveau',
        endSecondLevel:'Fin du second niveau',
        beginningThirdLevel:'Début du troisième niveau',
        endThirdLevel:'Fin du troisième niveau',
        beginningFourthLevel:'Début du quatrième niveau',
        endFourthLevel:'Fin du quatrième niveau',
        beginningFifthLevel:'Début du cinquième niveau',
        endFifthLevel:'Fin du cinquième niveau',
        firstEndBinsGte:'La fin du premier niveau ne peut pas être supérieur à la valeur du début du premier niveau',
        secondEndBinsGte:'La fin du second niveau ne peut pas être supérieur à la valeur du début du second niveau',
        thirdEndBinsGte:'La fin du troisième niveau ne peut pas être supérieur à la valeur du début du troisième niveau',
        fourthEndBinsGte:'La fin du quatrième niveau ne peut pas être supérieur à la valeur du début du quatrième niveau',
        fifthEndBinsGte:'La fin du cinquième niveau ne peut pas être supérieur à la valeur du début du cinquième niveau',
        firstBins:'Premier niveau',
        secondBins:'Second niveau',
        thirdBins:'Troisième niveau',
        fourthBins:'Quatrième niveau',
        fifthBins:'Cinquième niveau',
        zone:'Zone',
        successBinsSkip:'Opération effectuée avec succès, cependant les bins suivantes n\'ont pas été créées car elles existent déjà',
        scanedBox:'Cartons scannés',
        scanBinsHere:'Scanner la bins ici',
        attributeAStorageArea:'Attribuer à un espace de stockage',
        selectAStorageArea:'Selectionner une zone de stockage',
        billing:'Facture',
        quotation:'Devis',
        quotationCreate:'Création devis',
        purchaseOrderB2bCreate:'Création bon de commande',
        deliveryNoteCreate:'Création bon de livraison',
        creditNoteCreate:'Création avoir',
        returnNoteCreate:'Création bon de retour',
        billingBuyCreate:'Création facture d\'achat',
        poB2bCollectedUp:'Bon de commande: collecte',
        poB2bCollectedDown:'Bon de commande: mise en zone commande',
        poB2bMouvement:'Mouvement bon de commande',
        deliveryNoteOutStock:'Sortie de stock bon de livraison',
        outStock:'Sortie de stock',
        returnNoteReturnInStock:'Retour en stock bon de retour',
        rnReceipt:'Retour en stock',
        creditNote:'Avoir',
        searchModel:'Rechercher un modèle',
        searchRoughtDraft:'Rechercher un brouillon',
        selectOnProductList:'Sélectionner dans la liste de produits',
        itemsSelection:'Sélection d\'articles',
        addStock:'Importer du stock',
        or:'Ou',
        totalStock:'Total en stock',
        boxesInfos:'Informations sur les cartons',
        binsNumber:'Numéro de bins',
        binsNumb:'Numero de bins',
        storageArea:'Zone de stockage',
        articlesList:'Liste des articles',
        boxNotScaned:'Carton non scanné',
        itemNotExist:'L\'article {sku} n\'existe pas',
        itemNotExistInThisTo:'L\'article scanné n\'apparait pas dans cet ordre de transfert',
        itemNotExistInThisPoB2b:'L\'article scanné n\'apparait pas dans ce bon de commande',
        finishScanBefore:'Vous devez finir de scanner cet article avant d\'en scanner un autre',
        selectRowScan:'Sélectionner le bloc à scanner',
        location:'Localisation',
        binsDetails: 'Contenu des bins',
        zoneDetails: 'Contenu des zones',
        onOrder:'En commande fournisseur',
        receivedLines: 'Ligne receptionnee',
        totalHt: 'Total HT',
        differencePrmp:'Ecart PRMP',
        differencePr:'Ecart prix de revient',
        differenceHt:'Ecart HT',
        ecart:'Ecart',
        caddie:'Caddie',
        providedCashFund:'Fond de caisse prévu',
        prevu:'Fond de caisse prévu',
        prevu_session:'Prévu',
        recipient:'Destinataire',
        limitDayPayment:'Date limite de paiement',
        caseLatePayment:'En cas de retard de paiement',
        optionalNoteMsg:'Mentions facultatives à faire apparaître sur le document',
        settleInvoice:'Voulez-vous acquitter cette facture ?',
        cancelLimitDatePaymentMsg:'Cela annulera la date limite de paiement si vous l\'avez renseigné',
        cancelSettleMsg:'Cela annulera l\'acquittement de la facture si vous l\'avez renseigné',
        indicateLimitDateOfPayment:'Voulez-vous indiquer une date limite de paiement ?',
        indicateLimitValidDate:'Voulez-vous indiquer une date limite de validité ?',
        addTextOnTopPart:'Voulez-vous ajouter un texte dans la partie supérieure du document ?',
        addTextOnBottomPart:'Voulez-vous ajouter un texte dans la partie inférieure du document ?',
        topText:'Texte supérieur',
        bottomText:'Texte inférieur',
        paidOn:'Payé le',
        generateFreebird:'Généré par Freebird',
        dashboard:'Tableau de bord',
        total:'Total',
        totalWithoutDiscount:'Total non remisé',
        tax:'Taxe',
        openWith:'Ouvert avec',
        binsMovement:'Mouvement de stock',
        stockDeplacement:'Déplacement de stock',
        simpleMove: 'Mouvement simple',
        massMouvement:'Mouvement de stock en masse',
        avlQuantity:'Quantité disponible',
        qteToMove:'Quantité à déplacer',
        qteToTransfer:'Quantité à transférer',
        qteReceived:'Quantité réceptionnée',
        receivedOn:'Réceptionné le',
        qteThinkMove:'Quantité collectée',
        qteIsMove:'Quantité déposée',
        collectedAt:'Collecté le',
        deposedAt:'Déposé le',
        collected:'Collecté',
        qteToCollect:'Quantité à collecter',
        inLivraison:'Mis en livraison',
        outLivraison:'Sorti de livraison',
        shipmentDelivery:'Expédition et livraison', 
        carriers:'Transporteurs',
        orderPreparationZone:'Zone de préparation de commande',
        matchInternalId:'Associer les ids interne',
        matchingInternalIdImport:'Association des ids interne',
        deposed:'Déposé',
        closed:'Fermé',
        qteReached:'Quantité maximale atteinte',
        qteExceed:'Quantité maximale dépassée',
        qteAvlInsufficient:'La quantité disponible est insuffisante',
        selectStorageDestination:'Sélectionner la zone de stockage',
        cantScanDifferentItem:'Vous ne pouvez pas scanner plusieurs articles en même temps',
        itemNotInThisArea:'Cet article n\'apparaît pas dans cette zone de stockage',
        origin:'Provenance',
        destination:'Destination',
        scanTheItemHere:'Scanner l\'article ici',
        binsContent:'Contenu de la bins',
        emptyBins:'Cette bins est vide',
        emptyTrolley:'Votre caddie est vide',
        totalDevise:'Total en devise',
        referencePo:'Référence',
        internetRef:'Référence commande internet',
        seeReceiptVoucher:'Voir le bon de réception',
        voucherNumber:'Numéro du bon',
        receiptDate:'Date de réception',
        boxNumber:'Numéro du carton',
        itemsDetails:'Détails des articles',
        receiptVoucher:'Bon de réception',
        cancelBoxReceipt:'Annulation de la réception d\'un carton',
        cancelItemReceipt:'Annulation de la réception d\'un article',
        cancellationOnReceipt:'Annulation sur ticket',
        someItemsAlreadyScan: 'Action impossible, les articles de ce carton sont déjà intégrée au stock',
        cancelItemsEntry:'Annuler l\'entrée en stock des articles',
        itemsReinstate:'Réintégration articles',
        cancellationStockEntry:'Annulation entrée en stock',
        resolveNegative:'Résolution automatique de stock en magasin',
        errorReinstateNotPossible:'Vous ne pouvez pas réintégrer un article non réceptionné',
        selectBlockToReinstate:'Sélectionner le block à réintégrer',
        selectBlocksToReinstate:'Sélectionner les blocks à réintégrer',
        qteInSalesPoint:'Quantité dans l\'espace de vente',
        qteInReserve:'Quantité en réserve',
        errorStockReassortMsg:'Il existe actuellement des articles avec des quantités de stock négatives dans votre espace de vente, voulez-vous procéder au réajustement automatique ci-dessous ?',
        btnReadjustmentAndRestock:'Appliquer le réajustement et générer un réassort',
        btnReadjustmentAndNoRestock:'Générer le réassort sans appliquer le réajustement',
        autoRestock:'Réassort automatique',
        reassortInterne:'Reassorts internes',
        resolveNegative:'Correction de stock automatique',
        resolveShopNegative:'Correction stock negatif en magasin',
        internalRestock:'Réassort interne',
        restockList:'Liste des réassorts',
        listSelection:'Choix de la liste',
        actionOnExistingListItem:'Action sur la liste d\'articles existante',
        orderList:'Liste des commandes client',
        underwayRestockMsg:'Un réassort est en cours',
        toCorrectionDeposed:'Correction de stock pour différence entre quantité collectée et quantité livrée',
        reorderPoint: 'Niveau de stock minimum RP',
        referenceTo:'Référence',
        trolley:'Caddie',
        referenceToNoAccent:'Reference',
        transfert_leNoAccent: 'A executer le',
        transfert_le: 'A exécuter le',
        origin_section: 'Commerce d\'origine',
        destination_section: 'Commerce de destination',
        rhBarre: 'Code-barre staff',
        preferredStockLevel:'Niveau de stock optimal PSL',
        actualStock:'Stock actuel disponible',
        reassortEnCours:'Réassort à venir',
        recommendedDestination:'Destinations conseillées',
        sameItem:'Même article',
        sameParent:'Même Parent',
        box:'Carton',
        tagSize:'Dimension de l\'étiquette',
        rpInteger:'Le niveau de stock minimum RP de {name} doit être un nombre entier',
        pslInteger:'Le niveau de stock optimal PSL de {name} doit être un nombre entier',
        rppslAllOrNone:'Vous ne pouvez pas renseigner un RP sans PSL (et vice versa) pour {name}',
        detailProvider:'Détails du fournisseur',
        providerCard:'Fiche fournisseur',
        allPo:'Tous les bons de commande',
        generateABarCode:'Générer un code-barre',
        generateMasseBarCode:'Générer les codes barres en masse',
        generateMasseBarCodePdf:'Générer les codes barres pdf en masse',
        generateABarCodePdf:'Générer un code barre pdf',
        quantityManualModification:'Modification manuelle des quantitées',
        stockReorganization:'Réorganisation du stock',
        removeFromStock:'Retirer du stock',
        removeOption:'Retirer une option',
        addToStock:'Ajouter au stock',
        chooseAnAction:'Choisir une action',
        choisir:'Choisir',
        selectSection:'Sélectionner un commerce',
        stockIntegration:'Intégration au stock',
        stockRemove:'Retrait du stock',
        stockAdjustment:'Ajustement du stock',
        attemptedStockAdjustment:"Tentative d'ajustement du stock",
        error_open_checkout:'Erreur sur l\'ouverture de caisse',
        error_close_checkout:'Erreur sur la fermeture de caisse',
        validateAll:'Valider l\'ensemble',
        reinstateAllItems:'Réintégrer tous les articles',
        reinstateOneItem:'Réintégrer un article',
        reinstateSelection:'Réintégrer la sélection',
        confirmReinstateItems:'Souhaitez-vous vraiment réintégrer tous les articles ?',
        noBarcodeFounded:'Aucun code-barre n\'a été trouvé',
        rppsl:'Rppsl',
        createATransferOrder:'Créer un ordre de transfert',
        allTransferOrders:'Tous les ordres de transferts',
        exceptFinished:'hors finalisé',
        transferOrderNotStarted:'Ordres de transferts non commencé uniquement',
        notStarded:'Non commencé',
        processOfCollecting:'En cours de collecte',
        toEnCours:'Ordres de transferts en cours uniquement',
        processOfDelivery:'En cours de livraison',
        processOfEmptying:'En cours de dépotage',
        transferOrderEdit:'Edition d\'un ordre de transfert',
        errorDeleteTO:'Vous ne pouvez pas supprimer cet ordre de transfert',
        wrongBins:'Vous avez scanné la mauvaise bins',
        wrongItem:'Vous avez scanné le mauvais article',
        stockNegativeAlert:"Alerte stock négatif",
        stockNegativeAlertLabel:"Alerter lors d'une vente si l'article vendu est ou sera en stock négatif",
        stockNegativeAlertMsg:"L'article que vous venez d'ajouter aura un stock négatif si vous finalisez cette vente",
        allowTransferOrderWithDifferentValue:'Ordres de transfert et commandes avec des quantitées différentes',
        zoneWithoutBins:'Zone sans bins', 
        uncompletedLines:'Lignes inachevées',
        transferTo:'Transférer vers',
        transferOrderCompleted:'Ordre de transfert finalisé',
        errorToStockMissing:'L\'ordre de transfert n\'a pas pu être finalisé correctement du à un défaut de stock',
        confirmValidateAsIs:'Etes vous sûr de vouloir valider en l\'état ?',
        confirmCheckDeposit:'Etes vous sûr de vouloir valider votre remise de chèque ?',
        confirmGenerateRestockNoReadjust:'Etes vous sûr de vouloir continuer ? Les articles ayant un stock négatif ne seront pas pris en compte dans le réassort',
        transferOrdersToCollect:'Ordres de transfert à collecter',
        transferOrdersToReceive:'Ordres de transfert à réceptionner',
        stockTransfer:'Transfert de stock',
        stockTransferConfirm:'Etes vous sûr de vouloir transférer ce stock',
        ecomToCollect:'Commande client à collecter',
        transferOrderRelatedPo:'Cet ordre de transfert est lié à une commande client',
        quantityToReceive:'Quantité réceptionnée',
        receiveAllItems:'Réceptionner tous les articles',
        receiveItemsByItems:'Réceptionner article par article',
        validAllArticleToReceive:'Valider tous les articles à la réception d\'un ordre de transfert',
        noTransferOrdersToReceive:'Aucun ordres de transfert à réceptionner',
        confirmReceptionWithDifferentQte:'La quantité réceptionnée et différente de la quantité collectée, cela entraînera un réajustement de stock, continuer ?',
        linesNumber:'Nombre de lignes',
        notCollected:'Non collecté',
        alertItemsNotCollected:'Tous les articles n\'ont pas été collectés. Si vous continuez, vous aurez probablement des erreurs de stock à l\'avenir. Souhaitez-vous vraiment continuer ?',
        collection:'Collecte',
        depose:'Dépose',
        website:'Site internet',
        previousMonthTurnover:'Chiffre d\'affaires du mois précédent',
        presentMonthTurnover:'Chiffre d\'affaires du mois en cours',
        itemsBase:'Base articles',
        stockInShop:'Stock en magasin',
        stockInOtherShop:'Stock dans autre magasin',
        priority:'Priorité',
        toBeCollectedpriority: 'A réceptionner en priorité',
        restockPriority:'Priorité de réassort',
        collectedOn:'Collecté le',
        rppslNotConfigured:'Le RPPSL de cet article n\'a pas été configuré',
        rppslEdit:'Edition du rppsl',
        nbMaxToInstruction:'Nombre maximum d\'instructions dans un ordre de transfert',
        nbMaxEnumere:'Nombre maximum d\'énumérés de conditionnement',
        allItemsAlreadyScaned:'L\'ensemble des articles a été scanné',
        ecommerce:'E-commerce',
        shopLiaison:'Liaison commerce',
        seller:'Vendeur',
        discount:'Remise',
        unitaireDiscount:'Remise unitaire',
        price:'Prix',
        unitairePrice:'Prix unitaire',
        unitaireDiscountPrice:'Prix unitaire remise',
        priceWithoutDiscount:'Prix non remisé',
        priceEdit:'Edition unitaire du prix',
        priceModification:'Modification du prix',
        newPrice:'Nouveau prix',
        titleOnReceipt:'Intitulé sur ticket',
        discountPercent:'Remise %',
        cancelDiscount:'Annulation remise',
        discountNet:'Remise net',
        noDiscount:'Non remisé',
        addNote:'Ajouter une note',
        modifColumnPosition:'Modifier la position des colonnes',
        clickToHideOrShow:'Cliquer pour afficher ou masquer une colonne',
        dragAndDropToChangePosition:'Glisser et déposer pour changer la position d\'une colonne',
        espece:'Espèce',
        check:'Chèque',
        card:'Carte',
        other:'Autre',
        amount:'Montant',
        amountTtc:'Montant TTC',
        insertToRecords:'Ajout à un ordre de transfert',
        discountedPrice:'Prix remisé',
        programmedRecurrence:'Récurrences programmées',
        availableTemplate:'Modèles disponibles',
        checkNumber:'Numéro de chèque',
        modePay:'Mode de paiement',
        bank:'Banque',
        checkNum:'Numéro de chèque',
        reason:'Raison',
        scanGiftCard:'Scanner la carte cadeau ici',
        codeBarreContain:'Dont le code barre contient',
        payment:'Règlement',
        due:'Restant dû',
        promotion:'Promotions',
        zoneNameErrorCarac:'Le nom de la zone ne doit pas contenir de caractères spéciaux',
        percent:'Remise en %',
        franche:'Remise net',
        prixfixe:'Prix fixe',
        xpoury:'Offre X pour Y',
        percentsecond:'Remise en % sur 2nd article',
        xpourfixe:'Prix fixe pour N articles',
        cadeaupourx:'Cadeau pour panier supérieur à',
        suppercent:'Remise supplémentaire en % sur article déjà remisé en %',
        suppercentInstruction:'Cumuler les remises en %, en les séparant par une virgule',
        noPromoPeriodDefined:'Aucune date de fin',
        specifyPaymentMode:'Préciser le mode de paiement',
        createPromo:'Créer une promotion',
        qteItems:'Quantité d\'article',
        addImage:'Ajouter une image',
        seeTheList:'Voir la liste',
        reviewAllGols:'Consulter tous les objectifs des vendeurs',
        reviewIndividualGoals:'Consulter les objectifs individuels',
        percentDesc:'Réduction en pourcentage, exemple : -20%',
        francheDesc:'Réduction net du prix, example : -10XPF',
        prixfixeDesc:'Attribution d\'un prix fixe à un article',
        xpouryDesc:'Vendez une quantité X d\'articles pour le prix de Y articles, exemple : 2 pour le prix d\'un',
        percentsecondDesc:'Réduction en pourcentage sur le second article, exemple : -50% sur le deuxième article',
        xpourfixeDesc:'Vendez une quantité X d\'articles pour un prix fixe, exemple : 2 pour 5 000 XPF',
        cadeaupourxDesc:'Un cadeau offert pour un panier minimum, exemple : un tee-shirt offert à partir de 100$ d\'achat',
        suppercentDesc:'Remise supplémentaire en pourcentage sur un article déjà remisé : -10% en plus sur un article déjà remisé à -30%',
        for:'Pour',
        automaticRestockPlanning:'Planification des réassorts automatique',
        itemCreation:'Création d\'un article',
        numberPointCumule:'Nombre de points cumulés',
        numberPointsToAccumulate:'Nombre de points à cumuler',
        totalFideliteCumul:'Total de points cumulés',
        totalFideliteSpent:'Total de points dépensés',
        totalFideliteAdjustment:'Total des ajustements manuels',
        mergeWithCustomer:'Fusionner avec un autre client',
        perSliceOf:'Par tranche de',
        beginPromo:'Début de la promotion',
        endPromo:'Fin de la promotion',
        assignedPoint:'Points attribués',
        editLoyaltyPlan:'Modifier un plan de fidélité',
        createLoyaltyPlan:'Créer un plan de fidélité',
        editPromo:'Modifier une promotion',
        editGeneralInfos:'Modifier les infos générales',
        generalInfos:'Infos générales',
        undefined:'Non défini',
        addPromotion:'Ajouter une promotion',
        currentAndUpcomingPromo: 'Promotions en cours et à venir',
        currentPromo:'Promotion en cours',
        upcomingPromo:'Promotion à venir',
        currentLoyaltyPlan:'Plan de fidélité en cours',
        upcomingLoyaltyPlan:'Plan de fidélité à venir',
        noCurrentPromo:'Aucune promotions en cours',
        noUpcomingPromo:'Aucune promotions à venir',
        addToTheSelection:'Ajouter à la liste',
        replaceTheList:'Remplacer la liste',
        addToPromotion:'Ajouter à la promotion',
        additionPromo:'Ajout de la promotion',
        currentAndUpcomingLoyaltyPlan: 'Plans de fidélité en cours et à venir',
        allLoyaltyPlans:'Tous les plans de fidélité',
        dependingItemPrice:'Dépend du prix des articles',
        giftOffered:'Cadeau offert',
        receiptSaved:'Tickets sauvegardés',
        receiptNumber:'Numéro de ticket',
        saleSaved:'Vente sauvegardée',
        favoriteSaved:'Favori sauvegardée',
        noCurrentSale:'Aucune vente en cours',
        noSalesSaved:'Aucune ventes sauvegardées',
        createGiftCard:'Création d\'une carte cadeau',
        scanGiftCardHere:'Scanner la carte cadeau ici',
        deposit:'Montant versé',
        totalDeposit:'Total remise',
        totalDiscountPrice:'Total remisé',
        discountTotal:'Remise totale',
        cashDepositExecutedBy:'Remise d\'espèces effectuée par',
        checkDeposit:'Remise de chèque',
        checkDepositHistorical:'Historique de remise de chèque',
        noCheckDeposit:'Aucune remise de chèque',
        checkAndCash:'Espèces / Chèques',
        qteMoneyIn:'Quantité de monnaie en',
        doneOn:'Fait le',
        changeBack:'Rendu',
        deposited:'Versé',
        giveChange:'A rendre',
        notValidGiftCard:'Cette carte cadeau n\'est pas valide pour ce commerce',
        noFundAvailable:'Aucun fond de disponible sur cette carte cadeau',
        noEnoughFund:'Il n\'y a pas assez de fonds sur cette carte cadeau',
        reminderMsg:'Vous avez programmé un rappel pour les documents suivants',
        recurrenceMsg:'Vous avez programmé une récurrence pour les documents suivants',
        moreDetails:'Plus de détails',
        credit:'Crédit',
        debit:'Débit',
        dateExpire:'Date d\'expiration',
        itemReturn:'Retour article',
        dontRemise:'Dont remise',
        scanReceiptHere:'Scanner le ticket ici',
        scanSalesOrderHere:'Scanner le bon de commande ici',
        scanQuotationHere:'Scanner le devis ici',
        scanDocHere:'Scanner le code-barre ici',
        docNum:'Numéro du document',
        document:'Document',
        searchOneReceipt:'Rechercher un ticket',
        receiptSearch:'Recherche de tickets',
        devisSearch:'Recherche de devis',
        enterRefundMethod:'Renseigner le mode de remboursement',
        ticket:'Ticket',
        tickets:'Tickets',
        vente:'Vente',
        retour:'Retour',
        refundEspece:'Remboursement espèce',
        refundGiftcard:'Remboursement carte cadeau',
        refundOther:'Remboursement autre',
        noItemsToReturn:'Aucun articles à retourner',
        noItems:'Aucun articles',
        paid:'Payé',
        historical:'Historique',
        salesHistorical:'Historique des ventes',
        customerHistorical:'Historique client',
        availableFund:'Fonds disponibles',
        lostFund:'Fonds perdus',
        totalDepot:'Total des dépôts',
        depot:'Dépôt',
        numberDepot:'Nombre de dépôts',
        detail:'Détail',
        itemsNumber:'Nombre d\'articles',
        bosexNumber:'Nombre de cartons',
        receiptInProgress:'Ticket en cours',
        messageEndReceipt:'vous remercie pour votre visite',
        downloadingPdf:'Téléchargement du pdf',
        lastSale:'Dernière vente',
        noPreviousSale:'Aucune vente effectuée précédemment',
        noPreviousDevis:'Aucun devis effectué précédemment',
        selectConcernedPeriod:'Choisissez la période concernée',
        addNewPayment:'Ajouter un paiement',
        beginningPeriod:'Début de période',
        endPeriod:'Fin de période',
        editPaymentMethod:'Modification du mode de paiement',
        noPaymentMethodToEdit:'Aucun mode de paiement à modifier',
        checkoutState:'État caisse',
        totalWithGiftCard:'Total des ventes avec cartes cadeaux',
        totalWithoutGiftCard:'Total des ventes sans cartes cadeaux',
        averageReceiptWithGiftCard:'Ticket moyen avec cartes cadeaux',
        averageReceiptWithoutGiftCard:'Ticket moyen sans cartes cadeaux',
        wholeShop:'Tout le commerce',
        filterByEmployee:'Filtre par employé',
        filterByCashRegister:'Filtre par caisse',
        allStaff:'Tout le staff',
        thisCashRegister:'Cette caisse',
        concernedShop:'Commerces concernés',
        typePromo:'Type de promotion',
        statePromo:'État de la promotion',
        messageBienvenue:'Bonjour - Bienvenue',
        acroTel:'Tel',
        postalAdress:'Adresse postale',
        depotGiftCard: 'Dépôts des cartes cadeaux',
        finished:'Terminé',
        poNbr:'Bon de commande n°',
        removeAllArticlesConfirm:'Voulez vous vraiment vider les articles de cette promotion',
        manualDiscountPercent:'Remise manuelle en %',
        manualDiscountNet:'Remise manuelle net',
        manualDeposit:'Remise manuelle',
        autreFrais:'Autres frais',
        ecomConnexion:'Connexion e-commerce',
        caisseIdent:'Identifiant de caisse',
        posName:'Nom de la caisse', 
        actualSumCaisse:'Fond de caisse attendu',
        cashDepositgroup:'Remise d\'espèces groupée',
        bill:'Billets',
        coin:'Pièces',
        form:'Formulaire',
        preview:'Prévisualisation',
        acutalSession:'Session actuelle',
        cashTotalInCaisse:'Total des espèces',
        checksTotalDeposit:'Total des chèques sur la remise',
        derniersRetraitEspeces: 'Derniers retraits d\'espèces',
        dernierRetraitEspecesSingle: 'Dernier retrait d\'espèces',
        generateAutoCashDeposit:'Générer une remise d\'espèces en banque avec le total des espèces, hors fond de caisse',
        newStaff:'Nouveau staff',
        refresh:'Rafraîchir',
        correspondenceItem:'Correspondence sur le détail des articles',
        connector:'Connecteur e-commerce',
        personalizedFilter:'Filtre personnalisé',
        periodFilter:'Filtre par période',
        correspondenceDeliveryCost:'Correspondance des frais de livraison',
        correspondenceDatas:'Correspondance des données',
        currentWeek:'Semaine en cours',
        lastWeek:'Semaine dernière',
        nextWeek:'Semaine prochaine',
        month:'Mois',
        currentMonth:'Mois en cours',
        lastMonth:'Mois dernier',
        nextMonth:'Mois prochain',
        currentYear:'Année en cours',
        lastYear:'Année dernière',
        saveSale:'Sauvegarder une vente',
        cancelSaleAndOrder:'Annuler une vente ou une commande',
        paymentDetail:'Détail du paiement',
        receiptDetail:'Détail du ticket',
        depositDetail:'Détail de l\'acompte',
        giftCardCredit:'Crédit carte cadeau',
        printItemTag:'Imprimer une étiquette',
        includeArchives:'Inclure les archives',
        includeExpiredGiftCard:'Inclure les cartes cadeaux expirées',
        saleOrderDetail:'Détail de la commande',
        devisDetail:'Détail du devis',
        openSaleOrder:'Ouvrir la commande client',
        objectifAndCa:'Objectifs et CA',
        user:'Utilisateur',
        active:'Actif',
        draft:'Brouillon',
        roughDraftList:'Liste des brouillons',
        templateList:'Liste des modèles',
        archived:'Archivé',
        peripheralDevice:'Périphériques',
        newPeripheralDevice:'Nouveau périphérique',
        model:'Modèle',
        checkoutShortcut:'Raccourci caisse',
        checkoutShortcuts:'Raccourcis caisse',
        newShortcut:'Nouveau raccourci caisse',
        new:'Nouveau',
        allNotifications:'Toutes les notifications',
        noNotifications:'Aucune notification',
        noNewNotifications:'Aucune nouvelles notifications',
        seeReceipt:'Voir le ticket',
        read:'Lu',
        unread:'Non lu',
        newShipmentToPick:'Nouveau bon d\expédition attribué',
        newTo:'Nouvel ordre de transfert à collecter',
        ipAdress:'Adresse IP ou port COM',
        afficheur:'Afficheur',
        tpe:'TPE',
        ingenico:'Ingenico',
        comPort:'Port COM',
        imprimante_ticket:'Imprimante ticket',
        imprimante_cheque:'Imprimante chèque',
        matchBarCode:'Associer un code-barre',
        matchBarCodeToItem:'Associer un code-barre à un article',
        imprimante_etiquette:'Imprimante étiquette',
        etiquette:'Etiquette',
        epson_tm_h6000V:'Epson TM-H6000V',
        zebra_zd421:'ZPL (Windows)',
        printCaisseIdent:'Imprimer l\'identifiant de caisse',
        aures_2_l:'Afficheur Aures',
        second_screen_display:'Afficheur second écran',
        notAttributedPeripheral:'Périphériques non attribués',
        noPeripheral:'Aucun périphérique',
        noConfiguredPeripheral:'Aucun périphérique configuré',
        otherSection:'Autre commerce',
        disponibleMini:'Dispo',
        blockedMini:'Bloq',
        designation:'Désignation',
        workTimeToday:'Indiquer un temps de travail',
        workTimeDetails:'Répartition de votre temps de travail',
        workTime:'Temps de travail',
        chooseProject:'Selectionner un projet',
        allProject: 'Tous les projets',
        chooseTask:'Selectionner une tache',
        work_project:'Projet',
        work_project_detail:'Tache',
        nbrHours: 'Nombre d\'heures',
        nbrMinutes: 'Nombre de minutes',
        aVenir:'A venir',
        datasRecorded:'Données enregistrées',
        reorganizeColum:'Réorganiser les colonnes',
        changePrinter:'Changer d\'imprimante',
        changeZone:'Changer de zone',
        changeCustomer:'Changer de client',
        selectAPrinter:'Selectionner une imprimante',
        qteTag:'Quantité d\'étiquette',
        tagPrint:'Impréssion étiquette',
        limitTagPrint100:'La limite d\'impréssion est de 100 étiquettes',
        mintTagPrint1:'La quantité minimum d\'étiquette à imprimer est de 1',
        bankDeposit:'Dépôt bancaire',
        salesOrder:'Commandes client',
        addToOrder:'Ajouter à la commande',
        expectedCollectDate:'Date prévue de collecte',
        numberShopifyOrder:'Numéro de commande Shopify',
        collectZone:'Lieu de collecte',
        order:'Commander',
        commandeChangeCollectZone: 'Modification du lieu de collecte',
        commandeOut: 'Sorti de la zone de commerce',
        commandeRemoveOut: 'Sorti de la zone de commande pour mis en ordre de transfert',
        commandePut: 'Mis en zone de commande',
        commandeRemovePut: 'Mis en zone de commerce pour mis en ordre de transfert',
        commandeCancelledOut: 'Annulation commande - Sorti de la zone de commande',
        commandeCancelledPut: 'Annulation commande - Sorti de la zone de commerce',
        orderHistorical:'Historique commande client',
        devisHistorical:'Historique devis',
        searchByCreationDate:'Rechercher par date de création',
        searchByCollectDate:'Rechercher par date prévue de collecte',
        searchASalesOrder:'Rechercher une commande client',
        searchAQuotation:'Rechercher un devis',
        searchSalesOrder:'Recherche de commandes client',
        article:'Article',
        noCheckoutShortcut:'Aucun raccourci caisse',
        validateNewOrder:'Valider le nouvel ordre',
        transferHere:'Transférer ici',
        transferToOtherShop:'Transférer vers un magasin',
        expireSession:'Votre session a expiré ! Vous allez être redirigé.',
        myTime:'Mon temps',
        /***********Button**************/
        btnAccess: 'Go !',
        btnCancelSalesOrder:'Annuler la commande client',
        btnSeeEditDelete : 'Voir / Editer / Supprimer',
        btnValidate: 'Valider',
        btnValidateAndSave:"Valider et sauvegarder",
        btnDeleteUser: 'Supprimer cet utilisateur',
        btnDeleteGrade: 'Supprimer ce grade',
        btnAddRewardLevel:'Ajouter un palier de récompense',
        btnDeleteRh: 'Supprimer ce staff',
        btnYes: "Oui",
        btnNo: "Non",
        btnDelete: "Supprimer",
        btnAddField: "Ajouter un champ",
        btnRemoveRh: "Retirer du commerce",
        btnRemove: "Retirer",
        btnGoClotureCaisse: 'Clôturer la caisse',
        btnAddAllItems:'Ajouter tous les articles de la base article',
        btnFilterItemsToAdd:'Sélectionner les articles à ajouter',
        btnCancel: 'Annuler',
        btnBackToWelcome: 'Retour à l\'accueil',
        btnPay: 'Payer',
        btnEffacer: 'Effacer',
        btnClose: 'Fermer',
        btnImport: 'Importer',
        btnAddClientForm: 'Ajouter le formulaire client',
        btnRemoveClientForm: 'Retirer le formulaire client',
        btnArchiveStaff: 'Archiver ce Staff',
        btnActiveStaff: 'Activer ce Staff',
        btnArchiveProvider: 'Archiver ce fournisseur',
        btnDeleteProvider: 'Supprimer ce fournisseur',
        btnUnarchiveProvider: 'Activer ce fournisseur',
        btnSeeProfil: 'Voir le profil',
        btnDeleteAssociate: 'Supprimer l\'association',
        btnSearchAssociateCustomer: 'Lancer une recherche des possibilités d\'association',
        btnUnarchiveBrand: 'Activer cette marque',
        btnArchiveBrand: 'Archiver cette marque',
        btnDeleteBrand: 'Supprimer cette marque',
        btnAssociate : 'Associer',
        btnCreateNewStaffCustomer: 'Générer un nouveau client associé à ce Staff',
        btnUnarchiveZone: 'Activer cette zone',
        btnArchiveZone: 'Archiver cette zone',
        btnDeleteZone: 'Supprimer cette zone',
        btnShowDetails: 'Voir les détails',
        btnShowReminders:'Voir les rappels',
        btnInsert: 'Insérer',
        btnUpdate: 'Mettre à jour',
        btnBack: 'Retour',
        btnMove:'Déplacer',
        btnReturn:'Retour',
        btnSeeBinsContent:'Voir le contenu de la bins',
        btnSeeParentProfil:'Profil du parent',
        btnValidateAsIs:'Valider en l\'état',
        btnSeeStock:'Voir le stock',
        btnItemProfil:'Profil article',
        btnModifier:'Modifier',
        btnSave:'Sauvegarder',
        btnSeePdf:'Voir le ticket pdf',
        btnPdf:'Voir le pdf',
        btnSeeDevisPdf:'Voir le devis pdf',
        btnPrint:'Imprimer',
        btnPayPrint:'Payer & Imprimer',
        btnGiftPrint:'Ticket Cadeau',
        btnRemoveAllItems:'Retirer tous les articles',
        btnCancelDiscount:'Annuler la remise en cours',
        /***********Menu**************/
        welcome: 'Accueil',
        usersList: 'Liste Utilisateurs',
        usersCreate: 'Créer un Utilisateur',
        rhCreate: 'Créer un Staff',
        newCustomer:'Nouveau client',
        administrationGenerale: 'Administration Générale',
        administrationModePaiement: 'Modes de paiement',
        administrationObjectifPos : 'Objectifs',
        administrationTurnoverPos : 'Chiffre d\'affaires',
        ticketSalesOrder:'Tickets / Commandes Client / Devis',
        administrationCustomForm: 'Formulaires Personalisés',
        administrationClientForm: 'Client',
        administrationFournisseurForm: 'Fournisseur',
        administrationMarqueForm: 'Marque',
        administrationArticleForm: 'Article',
        administrationStaff: 'Staff',
        administrationStaffList: 'Liste Staff',
        yearOfExercise:'Année d\'exercice',
        shopName:'Nom du magasin',
        allCreate: 'Créer',
        searchStock: 'Recherche stock',
        cashRegister: 'Caisse',
        item:'Article',
        addToCart:'Ajouter au panier',
        addItem: 'Ajouter un article',
        addImageLink:"Ajouter le lien de l'image de votre article",
        importItem: 'Importer des articles',
        number:'Numéro',
        importInventory:'Importer un inventaire',
        importStockOrInventory:'Importer un stock ou un inventaire',
        importItemLoyaltyPlan: 'Importer des articles au plan de fidélité {name}',
        importItemPromotion: 'Importer des articles à la promotion {name}',
        importItemPromotionA: 'Importer des articles dans la liste A, à la promotion {name}',
        importItemPromotionB: 'Importer des articles dans la liste B, à la promotion {name}',
        listAMsgInfo:'Importez ici les articles qui déclencheront la promotion',
        listBMsgInfo:'Importez ici les articles qui bénéficieront de la promotion',
        listTriggerPromo:'Liste d\'articles qui déclencheront la promotion',
        listBenefitPromo:'Liste d\'articles qui bénéficieront de la promotion',
        noRestockNeeded:'Aucun réassort est nécessaire',
        facturation: 'Facturation',
        createBillingQuotation:'Créer facture/devis',
        productOn:'Produit(s) sur',
        formProvider: 'Formulaire fournisseur',
        formSaleOrder: 'Formulaire commande client',
        itemFamily: 'Familles d\'articles',
        brand: 'Marques',
        client: 'Clients',
        customizedInterface: 'Interface personnalisée',
        createRead:'Créer et consulter',
        import: 'Importer',
        allPromotions:'Toutes les promotions',
        promoWeight:'Poids de la promotion',
      valueBetween:'Valeur entre',
        printingInProgress:'Impression en cours',
        movePeripheralsShop: 'Etes-vous sûr de vouloir déplacer {deviceName} vers {shopName} ?',
        session:'Session',
        /***********Table***********/
        dataTable:'Tableau de données',
        tableSearch: 'Rechercher',
        tableName: 'Nom',
        tableFirst: 'Début',
        tableBack: 'Précédent',
        tableNext: 'Suivant',
        tableLast: 'Dernière',
        tableFistTitle: 'Première Page',
        tableBackTitle: 'Page Précédente',
        tableNextTitle: 'Page Suivante',
        tableLastTitle: 'Dernière Page',
        tableNoData: 'Aucune donnée',
        tableNoModePaiement: 'Aucun mode de paiement ajouté',
        tableNoCashOutReason: 'Aucun justificatif de retrait d\'espèces en caisse ajouté',
        tableSex: 'Sexe',
        tableFirstName: 'Prénom',
        tablePhone: 'Téléphone',
        tableRemove: 'Retirer',
        tableBarCode: 'Code-barre',
        tableOtherPhone: 'Autre téléphone',
        tableProviderName: 'Nom du fournisseur',
        tableContactName: 'Nom du contact',
        tableBrandName: 'Nom de la marque',
        tableContraintes: 'Contraintes',
        /*********** Label Form ***********/
        labelSex: 'Sexe',
        labelName: 'Nom',
        labelCsvSeparator: 'Séparateur CSV',
        labelCsvEncode: 'Encodage CSV',
        labelFirstName: 'Prénom',
        labelPassword: 'Mot de passe',
        labelPasswordConfirmation: 'Confirmer mot de passe',
        labelPhone: 'Téléphone',
        labelMaxusemoisproforma: 'Durée de validité d\'une proforma (en mois)',
        labelRaisonmodifprix: 'Raisons pour lesquelles un vendeur peut justifier de la modification manuelle du prix d\'un article',
        labelInfosTicket:'Les informations renseignées seront affichées telles que vous l\'avez écrit et dans l\'ordre dans lequel vous les avez ajoutées.',
        labelPriorityCollect: 'Définissez l\'ordre dans lequel la collecte d\'un ordre de transfert doit se faire',
        labelDateFormat: 'Format de date',
        labelAdress: 'Adresse',
        labelCodePostal: 'Code postal',
        labelVille: 'Ville',
        labelPays: 'Pays',
        labelCodeBank: 'Code banque',
        labelCodeGuichet: 'Code Guichet',
        labelNumeroDeCompte: 'Numéro de compte',
        labelCleRib: 'Clé RIB',
        labelBank: 'Banque',
        labelHoraires: 'Horaires',
        labelProviderName: 'Nom du fournisseur',
        labelContactName: 'Nom du contact',
        labelArchived: 'Archivé',
        labelBankName: 'Nom de la banque',
        labelSelectConfigue: 'Choisissez votre configuration',
        labelReason:'Raison',
        labelMaxReaInterne: 'Quantité maximum par article pour un reassort interne',
        labelDelaiReglement: 'Délai de règlement',
        labelContact: 'Contact',
        labelMailrelance: 'Mails de relance',
        /********** Success Form Response ************/
        sucessUserCreate: 'Utilisateur créé avec succès !',
        /**********Power BI module ********************/
        reportPbi:'Reporting',
        listOfGroup:'Liste des groupes de report',
        createAGroup:'Créer un groupe report',
        addPbiLink:'Ajouter des liens de report',
        link:'Lien',
        adressRequired:'Le lien est obligatoire',
        adressUrl:'Le lien doit être une adresse valide',
        filterByUser:'Filtrer par utilisateur',
        filterByModule:'Filtrer par module',
        noReportingAvl:'Aucun report disponible',
        /**********Payment methode ********************/
        espece: 'Espèces',
        cheque: 'Chèque',
        check: 'Cheque',
        emv_touch:'EMV sans contact',
        emv_multiple:'EMV paiement en plusieurs fois',
        amex_touch:'American Express sans contact',
        amex_multiple:'American Express paiement en plusieurs fois',
        cb: 'Carte Bleue/Visa',
        amex: 'American Express',
        mastercard: 'Mastercard', 
        jade: 'Carte Jade',
        jcb: 'Carte Jcb',
        giftcard: 'Carte cadeau',
        virement: 'Virement bancaire',
        emv:'EMV',
        annulCommande: 'Commande client annulée',
        card:'Carte bancaire',
        /********** Week days ************/
        monday: 'Lundi',
        tuesday: 'Mardi',
        wednesday: 'Mercredi',
        thursday: 'Jeudi',
        friday: 'Vendredi',
        saturday: 'Samedi',
        sunday: 'Dimanche',
        /********** Access ************/
        createCreditNote:'Créer un avoir',
        settleInvoiceAccess:'Acquitter une facture',
        assignUser:'Assigner des utilisateurs',
        authorizationRequiredForThisAction:'Une autorisation est nécessaire pour effectuer cette action',
      }
    }
  }

  export default translation