import React, {useState,useEffect,useContext} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {Loading} from '@gull'
import axios from 'axios'
import swal from "sweetalert2";
import SelectedInvoiceLine from './IndiceCalculatorComp/SelectedInvoiceLine'
import SelectedFraisManuelsLine from './IndiceCalculatorComp/SelectedFraisManuelsLine'
import { IsAuthContext} from 'app/App';
import {SelectFournisseur} from '@gull'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus} from '@fortawesome/free-solid-svg-icons'

const useLoad = (po,devise) => {
    const t = useTranslate();
    const [loading,setLoading]=useState(true)
    const [relance,setRelance]=useState(Date.now()) 
    const [allFactureAchat,setAllFactureAchat]=useState([])
    const [error,setError] = useState(false)
    const [fournisseurId,setFournisseurId]=useState(false)
    const [fournisseurName,setFournisseurName] = useState(false)
    const [factureAchatSelected,setFactureAchatSelected] = useState([])

    useEffect(function () {
        (async function() {
            if(fournisseurId){
                setError(false)
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/facture/invoice/get_billing_buy_by_fournisseur/"+fournisseurId
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }else{
                        setLoading(false)
                        setAllFactureAchat(response.data.data)
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    swal.fire(t('unknowError'), "", "error");
                    setLoading(false)
                })
            }else{
                setLoading(false)
            }
        })()
    }, [relance,fournisseurId])

    useEffect(function () {
        (async function() {
            if(po.calcul_indice && po.calcul_indice.categorie_douane && po.calcul_indice.facture_achat){
                setError(false)
                let douaneFactures = (po.calcul_indice.categorie_douane.length!=0) ? Object.keys(po.calcul_indice.categorie_douane) : []
                let noDouaneFactures = (po.calcul_indice.facture_achat.length!=0) ? Object.keys(po.calcul_indice.facture_achat) : []
                let listFactures = {factures:[...douaneFactures,...noDouaneFactures]}
                const response = await axios.post(process.env.REACT_APP_API_URL+"api/facture/invoice/list_of_facture",listFactures
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }
                    else{
                        let arr=[]
                        if(po.calcul_indice.facture_achat.length!=0){
                            Object.entries(po.calcul_indice.facture_achat).map(([key,value])=>{
                                let cetteFacture=response.data.datas.filter(val=>val.id==key)
                                let obj = {}
                                obj.id= key
                                obj.name= cetteFacture[0].bar+' - '+cetteFacture[0].ht+devise
                                obj.associate=[]
                                obj.percent=value
                                arr.push(obj)
                            })
                        }
                        if(po.calcul_indice.categorie_douane.length!=0){
                            Object.entries(po.calcul_indice.categorie_douane).map(([key,value])=>{
                                Object.entries(value).map(([key2,value2])=>{
                                    let cetteFacture=response.data.datas.filter(val=>val.id==key)
                                    let obj = {}
                                    obj.id= key
                                    obj.name= cetteFacture[0].bar+' - '+cetteFacture[0].ht+devise
                                    obj.associate=value2
                                    obj.percent=key2
                                    arr.push(obj)
                                })
                            })
                        }
                        setFactureAchatSelected(arr)
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    swal.fire(t('unknowError'), "", "error");
                    setLoading(false)
                })
            }else{
                setLoading(false)
            }
        })()
    }, [])
   
    return [loading,relance,setRelance,allFactureAchat,error,fournisseurId,setFournisseurId,fournisseurName,setFournisseurName,factureAchatSelected,setFactureAchatSelected]
}

const IndiceCalculationForm = (props) => {
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext)
    let devise = authParam.company.devise;
    const [loading,relance,setRelance,allFactureAchat,error,fournisseurId,setFournisseurId,fournisseurName,setFournisseurName,factureAchatSelected,setFactureAchatSelected] = useLoad(props.po,devise)
    const [validButton,setValidButton] = useState(false)
    const [errorField,setErrorField] = useState(false)
    const [errorDevise,setErrorDevise] = useState(false)

    const [deviseValue,setDeviseValue] = useState({})
    const [fraisManuels,setFraisManuels] = useState([])
    const [arrAchatSelected,setArrAchatSelected] = useState([])
    const [selectInvoice,setSelectInvoice] = useState('')

    const [displayingInvoiceId,setDisplayingInvoiceId] = useState(false)

    useEffect(function () {
        //On constuit le state de toutes les devises afin de pouvoir écrire sur chaque champs devise
        if(props.allDevises.length==0){
            setErrorDevise('noCurrencyProvided')
        }else{
            let obj = {}
            props.allDevises.map(devise=>{
                obj[devise] = (props.po.calcul_indice && props.po.calcul_indice.taux_de_change[devise]) ? props.po.calcul_indice.taux_de_change[devise] : ''
            })
    
            setDeviseValue(obj)
        }
    }, [props.allDevises])

    useEffect(function () {
        if(props.po.calcul_indice!=null && props.po.calcul_indice.ventilation){
            let arr = []
            props.po.calcul_indice.ventilation.map(value=>{
                let obj = {}
                obj.note=value.note
                let keys = Object.keys(value);
                let montant = keys.find(key => key !== "note");
                obj.montant=montant
                obj.customs=value[montant]
                arr.push(obj)
            })
            setFraisManuels(arr)
        }
    }, [])

    const handleChange = (e,name,devise) => {//devise est true uniquement lorsque la modification vient de l'un des inputs de devise
        if(name=='devise'){
            setDeviseValue({...deviseValue,[devise]:e.target.value.replace(/[^0-9\.]/g, '')})
        }else{
            if(e.target.value!=''){
                let dataFactureAchat = e.target.value.split('@!?')
                //On ajoute la facture sélectionné à la liste à afficher
                setFactureAchatSelected([...factureAchatSelected,{id:dataFactureAchat[0], name:dataFactureAchat[1], associate:[],percent:100}])
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrorField(false)
        setValidButton(true)

        let valueDeviseGo = true //Si une des devises à son champs taux de change vide, on retourne une erreur 
        Object.entries(deviseValue).map(([key,value])=>{
            if(value=='') valueDeviseGo = false
        })

        if(!valueDeviseGo ){
            setErrorField(true)
            setValidButton(false)
        }else{
            let customsCat = {}
            let factureAchat = {}
            let ventilation = []

            factureAchatSelected.map(value=>{
                if(value.associate.length==0){
                    factureAchat[value.id]=value.percent
                }else{
                    customsCat[value.id] = {
                        // Conserver les autres propriétés existantes pour l'ID
                        ...customsCat[value.id],  
                        // Fusionner le tableau existant avec la nouvelle valeur
                        [value.percent]: [
                            ...(customsCat[value.id]?.[value.percent] || []),  // Si un tableau existe déjà, on le fusionne
                            value.associate  // Ajouter la nouvelle association
                        ].flat()  // Utiliser flat() pour s'assurer qu'il n'y a pas d'imbrication de tableaux
                    };
                }
            })

            fraisManuels.map(value=>{
                ventilation.push({
                    [value.montant]:value.customs,
                    note:value.note
                });
            })
    
            let obj = {
                taux_de_change:deviseValue,
                categorie_douane:customsCat,
                facture_achat:factureAchat,
                ventilation:ventilation
            }

            const response  = await axios.post(process.env.REACT_APP_API_URL+"api/erp/po/calcul_indice/"+props.poId, obj
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(t(messageError), "", "error");
                    setValidButton(false)
                }else{
                    props.setReload(Date.now())
                    setFactureAchatSelected([])
                    setValidButton(false)
                    props.setShow(false)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                if(error.response==undefined){
                    swal.fire(t('unknowError'), "", "error");
                }
                else if(error.response.status==403){
                    swal.fire(t('forbiddenGeneral'), "", "error");
                }
                else {
                    swal.fire(t('unknowError'), "", "error");
                } 
                setValidButton(false)
            })
        }
    };

    const handleAddFrais = () => {
        setFraisManuels([...fraisManuels,{montant:0,note:'',customs:[]}])
    }




    return(<>
        {loading ? 
            <span className='d-flex justify-content-center'><div className="spinner-border spinner-border-sm"></div></span>
        :
            <form onSubmit={(e)=>handleSubmit(e)}>
                <label className='text-muted'>{t('exchangeRate')}</label>
                {props.allDevises.map(devise=>{
                    return(
                        <div key={devise} className="mb-2">
                            <div className="input-group ">
                                <div className="input-group-prepend">
                                    <div className="input-group-text rounded-0" style={{height:'100%'}}>{devise}</div>
                                </div>
                                <input disabled={validButton || error} value={deviseValue[devise]} type="text" className="form-control" onChange={(e)=>handleChange(e,'devise',devise)}/>
                            </div>
                            {(errorField && deviseValue[devise]=='') &&
                                <div className="text-danger mt-1 ml-2">
                                    {t('fieldRequired')}
                                </div>
                            }
                        </div>
                    )
                })}
                <div className="form-group ">
                    <label>{t('billingBuy')}</label>
                    <SelectFournisseur
                        documentType='billingBuy'
                        fournisseurId={fournisseurId}
                        setFournisseurId={setFournisseurId}
                        setFournisseurName={setFournisseurName}
                    />
                    <select disabled={validButton || error} value={selectInvoice} className="form-control mt-2" onChange={(e)=>handleChange(e,'facture',false)}>
                        <option value="">-- Select --</option>
                        {allFactureAchat.map(value=>{
                            if(!arrAchatSelected.includes(value.id.toString())){
                                return(
                                    <option key={value.id} value={value.id+'@!?'+value.bar+' : '+value.ht+devise}>{value.bar+' : '+value.ht+devise}</option>
                                )
                            }
                        })}
                    </select>
                    {(errorField && factureAchatSelected.length==0) &&
                        <div className="text-danger mt-1 ml-2">
                            {t('fieldRequired')}
                        </div>
                    }
                </div>
                <div>
                    <ul className="list-group list-group-flush">
                        {factureAchatSelected.map((value,ind)=>{
                            let style = (ind%2 == 0) ? 'stripeBg stripePadding px-2 row d-flex align-items-center' : 'stripePadding px-2 row d-flex align-items-center';

                            return(
                                <SelectedInvoiceLine 
                                    key={value.id+'_'+ind}
                                    index={ind}
                                    value={value}
                                    style={style}
                                    factureAchatSelected={factureAchatSelected}//infos
                                    setFactureAchatSelected={setFactureAchatSelected}
                                    arrAchatSelected={arrAchatSelected}//id uniquement
                                    setArrAchatSelected={setArrAchatSelected}
                                    allCustomsCategories={props.allCustomsCategories}
                                    validButton={validButton}
                                    displayingInvoiceId={displayingInvoiceId}
                                    setDisplayingInvoiceId={setDisplayingInvoiceId}
                                />
                            )
                        })}              
                    </ul>
                </div>
                <div className="form-group mt-2">
                    <label>{t('autreFrais')}</label>&nbsp;&nbsp;&nbsp;
                    <button type="button" className="btn btn-sm btn-success" onClick={() => handleAddFrais()}><FontAwesomeIcon icon={faPlus} /></button>
                </div>
                <div>
                    <ul className="list-group list-group-flush">
                        {fraisManuels.map((value,ind)=>{
                            let style = (ind%2 == 0) ? 'stripeBg stripePadding px-2 row d-flex align-items-center' : 'stripePadding px-2 row d-flex align-items-center';

                            return(
                                <SelectedFraisManuelsLine
                                    key={ind}
                                    style={style}
                                    ind={ind}
                                    fraisManuels={fraisManuels}
                                    setFraisManuels={setFraisManuels}
                                    devise={devise}
                                    allCustomsCategories={props.allCustomsCategories}
                                    validButton={validButton}
                                />
                            )
                        })}          
                    </ul>
                </div>
                
                {error && <div className='alert alert-danger mt-3'>{t(error)}</div>}
                {errorDevise && <div className='alert alert-danger mt-3'>{t(errorDevise)}</div>}
                <button disabled={validButton || error} className="btn btn-primary mt-3">
                    {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                </button>
            </form>
        }
    </>
    )
}

export default IndiceCalculationForm;